import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons'
import { useActiveTransmittal } from '../../Utilities/TransmittalManager/TransmittalContext'
import { useOpportunity } from '../../Utilities/TransmittalManager/TransmittalContext'
const ListOfTransmittals = ({ transmittals }) => {

	const RowOfTransmittal = ({ transmittal }) => {
		const [opportunity] = useOpportunity()
		const [activeTransmittal, setActiveTransmittal] = useActiveTransmittal()
		const handleSelectTransmittal = (transmittal) => {
			setActiveTransmittal(transmittal)
		}
		return (
			<>
				<div className='transmittal-list-row'>
					<div>
						{transmittal.status === "Open" &&
							<>
								{activeTransmittal?.id === transmittal.id && activeTransmittal.opportunityId === opportunity.id ? (
									<span onClick={() => handleSelectTransmittal(null)}>
										<FontAwesomeIcon icon={faSquareCheck} />
									</span>
								) : (
									<span onClick={() => handleSelectTransmittal(transmittal)}>
										<FontAwesomeIcon icon={faSquare} />
									</span>
								)}
							</>
						}
					</div>


					<div>

						<Link to={`/opportunity/${transmittal.opportunityId}/transmittal/view/${transmittal.id}`}>
							<div>{transmittal.id.toString().padStart(4, '0')}</div>
						</Link>
					</div>
					<div>{transmittal.name}</div>
					<div>{transmittal.status}</div>
					<div>{transmittal.created}</div>
					<div>{transmittal.createdBy}</div>
					<div>{transmittal.description}</div>
				</div>
			</>
		)
	}
	return transmittals ? (
		<div className='transmittals-list'>
			<div className='transmittal-list-row'>
				<div></div>
				<div>ID</div>
				<div>Name</div>
				<div>Status</div>
				<div>Created</div>
				<div>Created by</div>
			</div>
			{transmittals.length > 0 ? transmittals.map((transmittal, index) => <RowOfTransmittal key={index} transmittal={transmittal} />) : <div>No transmittals</div>}
		</div>
	) : (
		<div>
			<FontAwesomeIcon className='fa-spin' icon={faSpinner} /> Loading..
		</div>
	)
}
export default ListOfTransmittals
