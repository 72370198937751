import React, {useState, useEffect} from 'react'
import Comment from './Comment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronDown,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

function RevisionGroup(props) {
   const [toggle, setToggle] = useState(false);
	useEffect(() => {
		if (Number(props.latestRevision) === Number(props.revision)) {
			setToggle(true);
		}
	}, []);
	return (
		<div style={{paddingBottom:"10px"}}>
			<h3 className="RevisionGroup"  >
				<span  onClick={() => setToggle(!toggle)} style={{cursor:"pointer"}} >{`Revision ${props.revision
					.toString()
					}`}
					{toggle ? (
						<FontAwesomeIcon icon={faChevronDown} />
					) : (
						<FontAwesomeIcon icon={faChevronRight} />
					)}
				</span>
			</h3>
			{toggle ? (
				<div>
					{props.comments.map((comment, index) => {
						return <Comment key={index} comment={comment} user={props.user} siteUsers={props.siteUsers} SendComment={props.SendComment}   />;
					})}
				</div>
			) : null}
		</div>
	);
};

export default RevisionGroup