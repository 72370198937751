import { useState, useEffect } from 'react';
import Document from './Document'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { getAllComments } from '../../../../utils/fetchUtils'

import { useSelector } from 'react-redux'
const SkeletonLoading = () => {
	return [1, 2, 3].map((value) => (
		<div key={value} className='document-row-wrapper'>
			<div className='document-row latest-revision skeleton-loading'>
				<div></div>
				<div className='document-row-metadata'>
					<div>
						<div className='skeleton skeleton-loading-doc-icons'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-60'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-60'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-60'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-60'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-doc-icons'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-30'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-60'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-60'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-60'></div>
					</div>
				</div>
				<div className='document-row-menu skeleton-loading-menu-icon'>
					<span>
						<FontAwesomeIcon className='fa-spin' icon={faEllipsisVertical} />
					</span>
				</div>
			</div>
		</div>
	))
}
function Documents({ documents, opportunity, searchString, selectedTool }) {
	const [comments, setCommnets] = useState([]);
	const status = useSelector((state) => state.browse.status)

	useEffect(() => {
		if (status === 'success') {
			const getComments = async () => {
				setCommnets(await getAllComments(opportunity.storage));
			}
			getComments();
		}
	}, [status])
	return status === 'loading' ? (
		<SkeletonLoading />
	) : documents.length ? (
		documents.map((document) => {
			return <Document key={document.documentid} document={document} searchString={searchString} comments={comments} selectedTool={selectedTool} />
		})
	) : (
		<div>No documents..</div>
	)
}
export default Documents
