import DropdownBrowseFilter from '../../../Utilities/Dropdown/DropdownBrowseFilter'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import {
	setSelectedStatusFilter,
	setSelectedCreatedByFilter,
	setSelectedModifiedByFilter,
	setSelectedClassFilter,
	resetStatusFilter,
	resetCreatedByFilter,
	resetModifiedByFilter,
	resetClassFilter,
} from './browseSlice'
const Filter = ({ setSearchString }) => {
	const filterSelected_Status = useSelector((state) => state.browse.filterStatus.selected)
	const filterSelected_CreatedBy = useSelector((state) => state.browse.filterCreatedBy.selected)
	const filterSelected_ModifiedBy = useSelector((state) => state.browse.filterModifiedBy.selected)
	const filterSelected_Class = useSelector((state) => state.browse.filterClass.selected)

	const filterOptions_Status = useSelector((state) => state.browse.filterStatus.options)
	const filterOptions_CreatedBy = useSelector((state) => state.browse.filterCreatedBy.options)
	const filterOptions_ModifiedBy = useSelector((state) => state.browse.filterModifiedBy.options)
	const filterOptions_Class = useSelector((state) => state.browse.filterClass.options)
	const dispatch = useDispatch()
	const handleSearchInput = (value) => {
		setSearchString(value)
	}
	return (
		<div className='document-filter'>
			<DropdownBrowseFilter
				setValue={(option) => {
					dispatch(setSelectedStatusFilter(option.title))
				}}
				resetValue={() => {
					dispatch(resetStatusFilter())
				}}
				label={'Status'}
				selected={filterSelected_Status}
				options={filterOptions_Status.map(title => {
					return {
						title: title,
						data: null
					}
				})}
				icon={faFilter}
			/>
			<DropdownBrowseFilter
				setValue={(option) => {
					dispatch(setSelectedCreatedByFilter(option.title))
				}}
				resetValue={() => {
					dispatch(resetCreatedByFilter())
				}}
				label={'Created by'}
				selected={filterSelected_CreatedBy}
				options={filterOptions_CreatedBy.map(title => {
					return {
						title: title,
						data: null
					}
				})}
				icon={faFilter}
			/>
			<DropdownBrowseFilter
				setValue={(option) => {
					dispatch(setSelectedModifiedByFilter(option.title))
				}}
				resetValue={() => {
					dispatch(resetModifiedByFilter())
				}}
				label={'Modified by'}
				selected={filterSelected_ModifiedBy}
				options={filterOptions_ModifiedBy.map(title => {
					return {
						title: title,
						data: null
					}
				})}
				icon={faFilter}
			/>
			<DropdownBrowseFilter
				setValue={(option) => {
					dispatch(setSelectedClassFilter(option.title))
				}}
				resetValue={() => {
					dispatch(resetClassFilter())
				}}
				label={'Class'}
				selected={filterSelected_Class}
				options={filterOptions_Class.map(title => {
					return {
						title: title,
						data: null
					}
				})}
				icon={faFilter}
			/>

			<div className='filter-search-container'>
				<div className='filter-search'>
					<input onChange={(e) => handleSearchInput(e.target.value)} type='text' placeholder='Search Here...' />
					<span>
						<FontAwesomeIcon icon={faMagnifyingGlass} />
					</span>
				</div>
			</div>
		</div>
	)
}
export default Filter
