import React, { useEffect, useState } from 'react'
import { useParams, Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchFoldersByPath, setSelectedNode } from '../Tree/treeSlice'
import { getDocumentByIdAndRevision, getOpportunityById } from '../../utils/fetchUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { GetFileIcon } from '../../utils/appUtils'
import DocumentTabsMenu from './Components/DocumentTabsMenu'
import { useOpportunity } from '../Utilities/TransmittalManager/TransmittalContext'
const Document = () => {
	const [opportunity, setOpportunity] = useOpportunity()
	const [document, setDocument] = useState(null)
	const params = useParams()
	const dispatch = useDispatch()
	const nodes = useSelector((state) => state.tree.nodes)
	const rootSet = useSelector((state) => state.tree.rootSet)
	useEffect(() => {
		if (rootSet) {
			setOpportunity({
				...nodes.find((node) => node.id === params.opportunityid),
			})
		}
	}, [nodes])
	useEffect(() => {
		const fetchDocument = async () => {
			let Opportunity = await getOpportunityById(params.opportunityid)
			let Document = await getDocumentByIdAndRevision(params.opportunityid, Opportunity.storage, params.docid, Number(params.rev))
			setDocument(Document)
			let node = nodes.find((node) => node.opportunityId === params.opportunityid && node.path === Document.path)
			if (rootSet && node) {
				dispatch(setSelectedNode(node.id))
			}
		}
		setDocument(null)
		fetchDocument()
	}, [params])
	useEffect(() => {
		if (!rootSet) {
			if (nodes.length) {
				if (document) {
					dispatch(
						fetchFoldersByPath({
							opportunityid: params.opportunityid,
							SelectedFolderPath: document.path,
						})
					)
				}
			}
		}
	}, [params, rootSet, nodes, document])
	return (
		<div>
			<div className='subpage-heading-document'>
				{document ? (
					<h1>
						<FontAwesomeIcon icon={GetFileIcon(document.fileType)} />
						<span>{document.title}</span>
					</h1>
				) : (
					<h1>
						<FontAwesomeIcon className='fa-spin' icon={faSpinner} />
						<span>Loading..</span>
					</h1>
				)}
				{document ? <DocumentTabsMenu status={document.status} /> : null}
			</div>
			{opportunity && document ? <Outlet context={{ opportunity: opportunity, document: document }} /> : null}
		</div>
	)
}

export default Document
