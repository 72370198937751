import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { getDocumentHistory } from '../../../utils/fetchUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight, faChevronDown, faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons'
import { GetFormattedDate } from '../../../utils/appUtils'
import './History.css'
const History = () => {
	let [history, setHistory] = useState(null)
	const { document } = useOutletContext()
	useEffect(() => {
		const fetchDocument = async () => {
			setHistory(await getDocumentHistory(document.storage, document.opportunityId, document.documentid))
		}
		if (document) {
			fetchDocument()
		}
	}, [document])
	const VersionGroup = ({version})=>{		
		return(
			version.data.map((property) => (				
				<div className='version-table'>
					<div>{property.label}</div>
					<div>{property.changes.previousValue}</div>
					<div>
						{property.changes.difference === 'Set' ? (
							<FontAwesomeIcon icon={faPlus} />
						) : (
							<FontAwesomeIcon icon={faLongArrowAltRight} />
						)}
					</div>
					<div>{property.changes.currentValue}</div>
				</div>
			))
		)
	}
	const RevisionGroup = (props) => {
		const [toggle, setToggle] = useState(false)
		useEffect(() => {
			if (props.index === 0) {
				setToggle(true)
			}
		}, [])
		return (
			<div className='asdsa'>
				<h3 className='RevisionGroup'>
					<span>{`Revision ${props.revision.document.SalesDCM_DocumentRevision.toString().padStart(2, '0')}`}</span>
					<span onClick={() => setToggle(!toggle)}>
						{toggle ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />}
					</span>
				</h3>
				{toggle ? (
					<div>
						{props.revision.versions.map((version, index) => {
							return (
								<div className='version'>
									<div>
										<span>Change log</span>
										<span>{version.VersionLabel}</span>
									</div>
									<div>
										<span>Modified</span>
										<span>{GetFormattedDate(version.Modified)}</span>
									</div>
									<div>
										<span>Editor</span>
										<span>{version.Editor}</span>
									</div>
									<VersionGroup version={version}/>
								</div>
							)
						})}
					</div>
				) : null}
			</div>
		)
	}
	return (
		<div className='page'>
			<h1>Document history</h1>
			{history
				? history.map((revision, index) => {
						return <RevisionGroup index={index} revision={revision} />
				  })
				: null}
		</div>
	)
}

export default History
