import { useParams, useLocation } from 'react-router-dom'
import TabsMenu from '../../Utilities/TabsMenu/TabMenu'

const TransmittalsTabsMenu = () => {
	let params = useParams()
	let opportunity = params.opportunityid
	const location = useLocation()
	const RoutedComponent = location.pathname.slice(1).split('/')[3]

	let tabs = [
		{
			link: `/opportunity/${opportunity}/transmittals/all`,
			active: RoutedComponent === 'all' ? true : false,
			label: 'All',
			enabled: true,
		},
		{
			link: `/opportunity/${opportunity}/transmittals/create`,
			active: RoutedComponent === 'create' ? true : false,
			label: 'Create',
			enabled: true,
		},
	]
	return <TabsMenu tabs={tabs} />
}
export default TransmittalsTabsMenu
