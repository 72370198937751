import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import './DocumentOptionsMenu.css'
const DocumentOptionsMenu = (props) => {
	let [toggleOptions, setToggleOptions] = useState(false)

	const wrapperRef = useRef(null)
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setToggleOptions(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [wrapperRef])
	const toggleRevisions = () => {
		setToggleOptions(false)
		props.setTogglePreviousRevisions((togglePreviousRevisions) => !togglePreviousRevisions)
	}
	return (
		<div ref={wrapperRef} className='document-row-menu-wrapper'>
			<div className='document-row-menu' onClick={() => setToggleOptions((toggleOptions) => !toggleOptions)}>
				<FontAwesomeIcon icon={faEllipsisVertical} />
			</div>
			{toggleOptions ? (
				<div className='document-row-menu-options'>
					{props.document.status === 'Draft' ? (
						<Link
							to={`/opportunity/${props.document.opportunityId}/document/finalise/${props.document.documentid}/${props.document.revision}`}
						>
							<div className='document-row-menu-option'>Finalise</div>
						</Link>
					) : null}
					{props.document.status === 'Finalised' ? (
						<Link to={`/opportunity/${props.document.opportunityId}/document/revise/${props.document.documentid}/${props.document.revision}`}>
							<div className='document-row-menu-option'>Revise</div>
						</Link>
					) : null}
					<Link to={`/opportunity/${props.document.opportunityId}/document/view/${props.document.documentid}/${props.document.revision}`}>
						<div className='document-row-menu-option'>View metadata</div>
					</Link>
					{props.document.status === 'Draft' ? (
						<Link to={`/opportunity/${props.document.opportunityId}/document/edit/${props.document.documentid}/${props.document.revision}`}>
							<div className='document-row-menu-option'>Edit metadata</div>
						</Link>
					) : null}
					<Link to={`/opportunity/${props.document.opportunityId}/document/comments/${props.document.documentid}/${props.document.revision}`}>
						<div className='document-row-menu-option'>Comments</div>
					</Link>
					<Link to={`/opportunity/${props.document.opportunityId}/document/history/${props.document.documentid}/${props.document.revision}`}>
						<div className='document-row-menu-option'>History</div>
					</Link>
					{props.document.revision > 1 ? (
						<div className='document-row-menu-option' onClick={() => toggleRevisions()}>
							Previous revisions
						</div>
					) : null}
				</div>
			) : null}
		</div>
	)
}

export default DocumentOptionsMenu
