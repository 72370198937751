import { faFileUpload, faSquare, faSquareCheck, faInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DownloadLink, ReadAppLink, ModifyAppLink, OpenInNewTab, GetFileIcon } from '../../../../utils/appUtils'
import Tooltip from '../../../Utilities/Tooltip/Tooltip'
import { useModal } from '../../../Utilities/Modal/ModalContext'
import UploadRender from './UploadRender'
import UploadNative from './UploadNative'
const File = ({ mode, document, pdfRender }) => {
	const [modal, setModal] = useModal()
	const ReadNative = ({}) => {
		const Link = () => {
			return (
				<div className='doc-properties-row'>
					<div>Read</div>
					<div>
						<span
							className='doc-properties-row-file-link'
							onClick={() => OpenInNewTab(ReadAppLink(document.fileType, document.ServerRelativeUrl))}
						>
							<FontAwesomeIcon style={{ marginRight: '5px' }} icon={GetFileIcon(document.fileType)} />
							{`${document.title}.${document.fileType}`}
						</span>
					</div>
				</div>
			)
		}
		switch (document.status) {
			case 'Draft':
			case 'Finalised':
			case 'Expired':
				return <Link />
		}
	}
	const ModifyNative = () => {
		const Link = () => {
			return (
				<div className='doc-properties-row'>
					<div>Modify</div>
					<div>
						<span
							className='doc-properties-row-file-link'
							onClick={() => OpenInNewTab(ModifyAppLink(document.fileType, document.ServerRelativeUrl))}
						>
							<FontAwesomeIcon style={{ marginRight: '5px' }} icon={GetFileIcon(document.fileType)} />
							{`${document.title}.${document.fileType}`}
						</span>
					</div>
				</div>
			)
		}
		switch (document.status) {
			case 'Draft':
				switch (document.fileType) {
					case 'pdf':
						return null
					default:
						return <Link />
				}
			case 'Finalised':
				return null
			case 'Expired':
				return null
		}
	}
	const ToggleAutoRender = () => {
		
		const Link = () => {
			return (
				<div className='doc-properties-row'>
					<div className='doc-properties-row-with-info'>
						<span className='required'>PDF</span>
						<Tooltip
							icon={faInfo}
							text={
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
							}
						/>
					</div>
					<div>
						<span className='doc-properties-row-file-link'>
							{pdfRender.pdfRender ? (
								<span>
									<FontAwesomeIcon onClick={() => pdfRender.setPdfRender(false)} icon={faSquareCheck} />
									PDF will be Automatically rendered when document is finalised.
								</span>
							) : (
								<span>
									<FontAwesomeIcon onClick={() => pdfRender.setPdfRender(true)} icon={faSquare} />
									PDF will NOT be automatically rendered when document is finalised.
								</span>
							)}
						</span>
					</div>
				</div>
			)
		}		
		switch (document.status) {
			case 'Draft':
				switch (mode) {					
					case 'edit':
					case 'finalise':
					case 'revise':
						switch (document.fileType) {
							case 'pdf':
								return null
							default:
								return <Link />
						}
					default:
						return null
				}
			case 'Finalised':
			case 'Expired':
				return null
		}
	}
	const UploadRenderFile = () => {
		const Link = () => {
			const handleUploadPDF = () => {
				const ModalContent = () => {
					return (
						<div className='page'>
							<h1>Upload PDF</h1>
							<UploadRender document={document} />
						</div>
					)
				}
				setModal(<ModalContent />)
			}
			return (
				<div className='doc-properties-row'>
					<div className='doc-properties-row-with-info'>
						<span>PDF</span>
						<Tooltip
							icon={faInfo}
							text={
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
							}
						/>
					</div>
					<div>
						<span className='doc-properties-row-file-link'>
							<span onClick={() => handleUploadPDF()}>
								<FontAwesomeIcon icon={faFileUpload} /> Upload
							</span>
						</span>
					</div>
				</div>
			)
		}

		switch (document.status) {
			case 'Draft':
				return null
			case 'Finalised':
				switch (document.renderStatus) {
					case 'Manual':
					case 'Failed':
						return <Link />
					case 'Started':
					case 'Completed':
					case 'NA':
						return null
				}
			case 'Expired':
				return null
		}
	}
	const ReplaceNativeFile = () => {
		const Link = () => {
			const handleUploadFile = () => {
				const ModalContent = () => {
					return (
						<div className='page'>
							<h1>Replace file</h1>
							<UploadNative document={document} />
						</div>
					)
				}
				setModal(<ModalContent />)
			}
			return (
				<div className='doc-properties-row'>
					<div className='doc-properties-row-with-info'>
						<span>Replace</span>
						<Tooltip
							icon={faInfo}
							text={
								'In draft mode the existing file can be replaced'
							}
						/>
					</div>
					<div>
						<span className='doc-properties-row-file-link'>
							<span onClick={() => handleUploadFile()}>
								<FontAwesomeIcon icon={faFileUpload} /> Replace
							</span>
						</span>
					</div>
				</div>
			)
		}

		switch (document.status) {
			case 'Draft':
				return <Link />
			case 'Finalised':
			case 'Expired':
				return null
		}
	}
	const ReadRender = () => {
		const Link = () => {
			return (
				<div className='doc-properties-row'>
					<div className='doc-properties-row-with-info'>
						<span>PDF</span>
						<Tooltip
							icon={faInfo}
							text={
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
							}
						/>
					</div>
					<div>
						<span
							className='doc-properties-row-file-link'
							onClick={() => DownloadLink(document.storage, document.render.fileType, document.render.ServerRelativeUrl)}
						>
							<FontAwesomeIcon style={{ marginRight: '5px' }} icon={GetFileIcon(document.render.fileType)} />
							{`${document.render.title}.${document.render.fileType}`}
						</span>
					</div>
				</div>
			)
		}
		switch (document.status) {
			case 'Draft':
				return null
			case 'Finalised':
			case 'Expired':
				switch (document.renderStatus) {
					case 'Manual':
					case 'Started':
					case 'Failed':
					case 'NA':
						return null
					case 'Completed':
						return document.render ? <Link /> : null
				}
		}
	}
	
	return (
		<>
			<h3>File</h3>
			<div className='doc-properties'>
				<ReadNative />
				<ModifyNative />
				<ReplaceNativeFile />
				<ToggleAutoRender />
				<UploadRenderFile />
				<ReadRender />
			</div>
		</>
	)
}
export default File
