import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical, faTrash } from '@fortawesome/free-solid-svg-icons'
import { GetFileIcon, ReadAppLink, OpenInNewTab } from '../../../utils/appUtils'
import { useOutletContext } from "react-router-dom";

import './table-design.css'
const SkeletonLoading = () => {
	return [1, 2, 3].map((value) => (
		<div key={value} className='document-row-wrapper'>
			<div className='document-row latest-revision skeleton-loading'>
				<div></div>
				<div className='document-row-metadata'>
					<div>
						<div className='skeleton skeleton-loading-doc-icons'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-60'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-60'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-60'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-60'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-doc-icons'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-30'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-60'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-60'></div>
					</div>
					<div>
						<div className='skeleton skeleton-loading-width-60'></div>
					</div>
				</div>
				<div className='document-row-menu skeleton-loading-menu-icon'>
					<span>
						<FontAwesomeIcon className='fa-spin' icon={faEllipsisVertical} />
					</span>
				</div>
			</div>
		</div>
	))
}
const Document = ({ document, transmittal, selectedTransmittal, handleDeleteFile }) => {


	return (
		<div className='document-row-wrapper-transmittals'>
			<div className='document-row-transmittals'>
				{transmittal.status === "Open" &&
					<div className='document-row-trash-transmittals' onClick={() => handleDeleteFile(selectedTransmittal.storage, document.ServerRelativeUrl)}>
						<span>
							<FontAwesomeIcon icon={faTrash} />
						</span>
					</div>
				}
				<div className='document-row-metadata-link'>
					<div onClick={() => OpenInNewTab(ReadAppLink(document.fileType, document.ServerRelativeUrl))}>
						<FontAwesomeIcon icon={GetFileIcon(document.fileType)} /> Read
					</div>
				</div>
				<div className='document-row-metadata-transmittals'>
					<div>
						<Link to={`/opportunity/${document.opportunityId}/document/view/${document.documentid}/${document.revision}`}>
							{document.title}
						</Link>
					</div>
					<div>{document.status}</div>
					<div>{document.created}</div>
					<div>{document.modified}</div>
					<div>{document.class}</div>
					<div>{`${document.documentid},-${document.revision.toString().padStart(2, '0')}`}</div>
					<div>{document.createdBy}</div>
					<div>{document.modifiedBy}</div>
				</div>
			</div>
		</div>
	)
}
const Browse = () => {

	const { transmittal, documents, loadingDocuments, selectedTransmittal, handleDeleteFile } = useOutletContext();
	return (
		<div className='page'>
			<div className='documents-table-transmittals'>
				{loadingDocuments ? (
					<SkeletonLoading />
				) : documents.length && transmittal ? (
					documents.map((document) => {
						return <Document key={document.documentid} document={document} transmittal={transmittal} selectedTransmittal={selectedTransmittal} handleDeleteFile={handleDeleteFile} />
					})
				) : (
					<div>No documents..</div>
				)}
			</div>
		</div>
	)
}
export default Browse
