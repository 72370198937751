import { useOutletContext } from 'react-router-dom'
import File from './Components/File'
import Properties from './Components/Properties'
import Metadata from './Components/Metadata'
import Auditable from './Components/Auditable'

const View = () => {
	const { document } = useOutletContext()
	return (
		<div className='page'>
			<File mode={'view'} document={document} />
			<Properties mode={'view'} document={document} />
			<Metadata mode={'view'} document={document} />
			<Auditable document={document} />
		</div>
	)
}
export default View
