export const Configuration = {
	auth: {
		clientId: process.env.REACT_APP_CLIENTID,
		authority: process.env.REACT_APP_AUTHORITY,
		redirectUri: process.env.REACT_APP_REDIRECTURI,
		navigateToLoginRequestUrl: false,
	},
}
export const AuthParams = {
	scopes: [process.env.REACT_APP_ROOTSITE + '/.default'],
}