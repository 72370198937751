export const MIMEMap = (fileType) => {
	let MIMEtypes = [
		{
			fileExtenstion: 'avi',
			MIME: 'video/x-msvideo',
		},
		{
			fileExtenstion: 'bmp',
			MIME: 'image/bmp',
		},
		{
			fileExtenstion: 'doc',
			MIME: 'application/msword',
		},
		{
			fileExtenstion: 'pdf',
			MIME: 'application/pdf',
		},
		{
			fileExtenstion: 'docx',
			MIME: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		},
		{
			fileExtenstion: 'csv',
			MIME: 'text/csv',
		},
		{
			fileExtenstion: 'gz',
			MIME: 'application/gzip',
		},
		{
			fileExtenstion: 'gif',
			MIME: 'image/gif',
		},
		{
			fileExtenstion: 'htm',
			MIME: 'text/html',
		},
		{
			fileExtenstion: 'html',
			MIME: 'text/html',
		},
		{
			fileExtenstion: 'jpeg',
			MIME: 'image/jpeg',
		},
		{
			fileExtenstion: 'jpg',
			MIME: 'image/jpeg',
		},
		{
			fileExtenstion: 'js',
			MIME: 'text/javascript',
		},
		{
			fileExtenstion: 'json',
			MIME: 'application/json',
		},
		{
			fileExtenstion: 'mp3',
			MIME: 'audio/mpeg',
		},
		{
			fileExtenstion: 'mp4',
			MIME: 'video/mp4',
		},
		{
			fileExtenstion: 'mpeg',
			MIME: 'video/mpeg',
		},
		{
			fileExtenstion: 'png',
			MIME: 'image/png',
		},
		{
			fileExtenstion: 'php',
			MIME: 'application/x-httpd-php',
		},
		{
			fileExtenstion: 'ppt',
			MIME: 'application/vnd.ms-powerpoint',
		},
		{
			fileExtenstion: 'pptx',
			MIME: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		},
		{
			fileExtenstion: 'rar',
			MIME: 'application/vnd.rar',
		},
		{
			fileExtenstion: 'svg',
			MIME: 'image/svg+xml',
		},
		{
			fileExtenstion: 'tar',
			MIME: 'application/x-tar',
		},
		{
			fileExtenstion: 'tiff',
			MIME: 'image/tiff',
		},
		{
			fileExtenstion: 'tif',
			MIME: 'image/tiff',
		},
		{
			fileExtenstion: 'wav',
			MIME: 'audio/wav',
		},
		{
			fileExtenstion: 'xhtml',
			MIME: 'application/xhtml+xml',
		},
		{
			fileExtenstion: 'xls',
			MIME: 'application/vnd.ms-excel',
		},
		{
			fileExtenstion: 'xlsx',
			MIME: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
		{
			fileExtenstion: 'xml',
			MIME: 'application/xml',
		},
		{
			fileExtenstion: 'zip',
			MIME: 'application/zip',
		},
	]
    const MIME = MIMEtypes.find(type=> type.fileExtenstion === fileType)
    return MIME ? MIME.MIME : null
}
