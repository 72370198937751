import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faFileCirclePlus, faHashtag, faList, faSpinner } from '@fortawesome/free-solid-svg-icons'
import './Manager.css'
import ListOfAllTransmittals from '../../Transmittals/Components/AllTransmittals'
import { useSelector, useDispatch } from 'react-redux'
import { useActiveTransmittal, useTransmittal, useOpportunity } from './TransmittalContext'
import { AddToTransmittal, getTransmittalContent } from '../../../utils/fetchUtils'
import { useSendMessage } from '../MessageManager/MessageContext'
import CreateTransmittal from '../../Transmittals/Create/Create'
import { useModal } from '../Modal/ModalContext'
import { deselectAllDocuments } from '../../Folder/Browse/Components/browseSlice'

const TransmittalManager = () => {
	const SendMessage = useSendMessage()
	const [modal, setModal] = useModal()
	const [opportunity] = useOpportunity()
	const [activeTransmittal] = useActiveTransmittal()
	const [transmittal, setTransmittal] = useTransmittal()
	const selectedDocuments = useSelector((state) => state.browse.documents.filter((document) => document.selected))
	let navigate = useNavigate()

	useEffect(() => {
		const fetchDocuments = async () => {
			setTransmittal(await getTransmittalContent(opportunity.storage, opportunity.id, activeTransmittal.id))
		}
		if (activeTransmittal) {
			fetchDocuments()
		}
	}, [activeTransmittal])
	const handleSelectTransmittal = () => {
		const handleNavigateToCreateTransmittal = () => {
			/*navigate(`/opportunity/${opportunity.id}/transmittals/create`, { replace: true })*/
			const handleNavigateBack = () => {
				setModal(<ModalContent />)
			}
			setModal(
				<div className='page'>
					<CreateTransmittal opportunity={opportunity} handleCreateComplete={handleNavigateBack} />
					<button className='btn btn-blue mt-25' onClick={() => handleNavigateBack()}>
						Back
					</button>
				</div>
			)
		}
		const ModalContent = () => {
			const nodes = useSelector((state) => state.tree.nodes)
			const [activeTransmittal] = useActiveTransmittal()
			const correctOpportunity = nodes.find(node => node.type === "opportunity" && node.id === activeTransmittal?.opportunityId);
			return (
				<div className='page'>
					<h1>Select transmittal</h1>
					{activeTransmittal && activeTransmittal?.opportunityId !== opportunity.id && <div style={{ fontStyle: "italic", fontWeight: "bold", marginBottom: "1rem" }}>Your selected Transmittal is in the {correctOpportunity?.title}</div>}
					<ListOfAllTransmittals />
					<button className='btn btn-blue mt-25' onClick={() => setModal(null)}>
						Close
					</button>
					<button className='btn btn-blue mt-25' onClick={() => handleNavigateToCreateTransmittal()}>
						Create new
					</button>
				</div>
			)
		}
		setModal(<ModalContent />)
	}
	const SelectTransmittal = () => {
		return (
			<div onClick={() => handleSelectTransmittal()}>
				<div>
					<FontAwesomeIcon icon={faList} />
				</div>
				<div>Select</div>
			</div>
		)
	}
	const Documents = () => {
		return (
			<div onClick={() => navigate(`/opportunity/${activeTransmittal.opportunityId}/transmittal/view/${activeTransmittal.id}`, { replace: true })}>
				<div>
					<span className='fa-layers fa-fw'>
						<FontAwesomeIcon icon={faCopy} />
						<span className='fa-layers-counter transmittal-documents-counter'>{transmittal && transmittal.length}</span>
					</span>
				</div>
				<div>Documents</div>
			</div>
		)
	}
	const ActiveTransmittal = () => {
		return (
			<div onClick={() => handleSelectTransmittal()}>
				<div>
					<FontAwesomeIcon icon={faHashtag} />
				</div>
				<div>{activeTransmittal.id}</div>
			</div>
		)
	}
	const AddDocument = () => {
		const [loading, setLoading] = useState(false)
		const [cantTransmit, setCantTransmit] = useState(false)
		const dispatch = useDispatch()
		const documentsCanTransmit = () => {
			const documentInTransmittal = (id, rev) => {
				if (transmittal) {
					const CheckIfDocumentInTransmittal = (document) => `${document.documentid}_${document.revision}` === `${id}_${rev}`
					return transmittal.some(CheckIfDocumentInTransmittal)
				}
			}
			const documentStatusNotFinalised = (status) => status !== 'Finalised'
			const documentRenderMissing = (selectedDocument) => {
				if (selectedDocument.renderStatus === 'NA') {
					return false
				} else if (selectedDocument.renderStatus === 'Completed' || selectedDocument.render) {
					return false
				} else {
					return true
				}
			}
			return selectedDocuments.map((selectedDocument) => {
				if (documentInTransmittal(selectedDocument.documentid, selectedDocument.revision)) {
					return {
						...selectedDocument,
						canTransmit: false,
						reason: 'Document already in transmittal',
					}
				}
				if (documentStatusNotFinalised(selectedDocument.status)) {
					return {
						...selectedDocument,
						canTransmit: false,
						reason: 'Document not finalised',
					}
				}
				if (documentRenderMissing(selectedDocument)) {
					return {
						...selectedDocument,
						canTransmit: false,
						reason: 'Document render file is missing',
					}
				} else {
					return {
						...selectedDocument,
						canTransmit: true,
						reason: null,
					}
				}
			})
		}
		useEffect(() => {
			if (selectedDocuments) {
				setCantTransmit(documentsCanTransmit().some((document) => document.canTransmit === false))
			}
		}, [selectedDocuments])
		const handleAddDocument = async () => {
			if (!cantTransmit) {
				setLoading(true)
				await AddToTransmittal(opportunity.storage, opportunity.id, activeTransmittal.id, selectedDocuments, setTransmittal)
				setTransmittal(await getTransmittalContent(opportunity.storage, opportunity.id, activeTransmittal.id))
				dispatch(deselectAllDocuments())
				SendMessage('Documents successfully added to transmittal!', 'success')
				setLoading(false)
			} else {
				const ModalContent = () => {
					return (
						<div className='page'>
							<h1>Documents can´t transmit</h1>
							<div className='table'>
								<div className='table-heading table-row documents-cant-transmit'>
									<div>Title</div>
									<div>Document ID</div>
									<div>Rev.</div>
									<div>Status</div>
									<div>Reason</div>
								</div>
								{documentsCanTransmit()
									.filter((document) => !document.canTransmit)
									.map((document) => {
										return (
											<div className='table-row documents-cant-transmit'>
												<div>{document.title}</div>
												<div>{document.documentid}</div>
												<div>{document.revision.toString().padStart(2, '0')}</div>
												<div>{document.status}</div>
												<div>{document.reason}</div>
											</div>
										)
									})}
							</div>

							<button className='btn btn-blue mt-25' onClick={() => setModal(null)}>
								Close
							</button>
						</div>
					)
				}
				setModal(<ModalContent />)
			}
		}
		return loading ? (
			<div>
				<div>
					<FontAwesomeIcon className='fa-spin' icon={faSpinner} />
				</div>
				<div>Processing</div>
			</div>
		) : (
			<div onClick={() => handleAddDocument()}>
				<div>
					<span className='fa-layers fa-fw'>
						<FontAwesomeIcon icon={faFileCirclePlus} />
						{cantTransmit && <span className='fa-layers-counter transmittal-documents-issue'>!</span>}
					</span>
				</div>
				<div>Add</div>
			</div>
		)
	}

	return (
		<>
			<div>Transmittals</div>
			<div className='tool-selector-options'>
				{!activeTransmittal && opportunity && <SelectTransmittal />}
				{activeTransmittal && <ActiveTransmittal />}
				{activeTransmittal && selectedDocuments.length > 0 && <AddDocument />}
				{activeTransmittal && <Documents />}
			</div>
		</>
	)
}
export default TransmittalManager
