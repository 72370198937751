import React, { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import store from './Store'
import { Provider } from 'react-redux'
import Home from './components/Home/Home'
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs'
import Region from './components/Areas/Region/Region'
import SubRegion from './components/Areas/Region/SubRegion/SubRegion'
import Country from './components/Areas/Region/SubRegion/Country/Country'
import Opportunity from './components/Areas/Region/SubRegion/Country/Opportunity/Opportunity'
import OpportunityProperties from './components/Areas/Region/SubRegion/Country/Opportunity/OpportunityProperties/OpportunityProperties'
import Document from './components/Document/Document'
import ViewDocument from './components/Document/Metadata/View'
import EditDocument from './components/Document/Metadata/Edit'
import FinaliseDocument from './components/Document/Metadata/Finalise'
import ReviseDocument from './components/Document/Metadata/Revise'
import DocumentComments from './components/Document/Comments/Comments'
import DocumentHistory from './components/Document/History/History'
import Transmittals from './components/Transmittals/Transmittals'
import Transmittal from './components/Transmittal/Transmittal'
import Folder from './components/Folder/Folder'
import TopBar from './components/TopBar/TopBar'
import Tree from './components/Tree/Tree'
import MessageManager from './components/Utilities/MessageManager/MessageManager'
import BrowseDocuments from './components/Folder/Browse/Browse'
import AllTransmittals from './components/Transmittals/Components/AllTransmittals'
import CreateTransmittal from './components/Transmittals/Create/Create'
import ViewTransmittal from './components/Transmittal/Components/View'
import CreateFolder from './components/Folder/Create/Create'
import { MessageProvider } from './components/Utilities/MessageManager/MessageContext'
import TransmittalProvider from './components/Utilities/TransmittalManager/TransmittalContext'
import ClipboardProvider from './components/Utilities/ClipboardManager/ClipboardContext'
import ToolSelector from './components/Utilities/ToolSelector/ToolSelector'
import Modal from './components/Utilities/Modal/Modal'
import ModalProvider from './components/Utilities/Modal/ModalContext'
import { UserProvider } from "./components/Utilities/UserContext/UserProvider"

function App() {
	const [selectedTool, setSelectedTool] = useState(false)

	return (
		<Provider store={store}>
			<BrowserRouter>
				<UserProvider>
					<MessageProvider>
						<TransmittalProvider>
							<ClipboardProvider>
								<ModalProvider>
									<Modal />
									<div className='app'>
										<MessageManager />
										<ToolSelector selectedTool={selectedTool} setSelectedTool={setSelectedTool} />
										<div className='app-header'>
											<TopBar />
											<Breadcrumbs />
										</div>
										<div className='app-sidebar'>
											<Tree />
										</div>
										<div className='app-content'>
											<Routes>
												<Route path='' element={<Home />} />
												<Route path='region/:region' element={<Region />} />
												<Route path='subregion/:subregion' element={<SubRegion />} />
												<Route path='country/:country' element={<Country />} />
												<Route path='opportunity/:opportunityid' element={<Opportunity />}>
													<Route path='' element={<OpportunityProperties />} />
												</Route>
												<Route path='opportunity/:opportunityid/document' element={<Document />}>
													<Route path='view/:docid/:rev' element={<ViewDocument />} />
													<Route path='edit/:docid/:rev' element={<EditDocument />} />
													<Route path='finalise/:docid/:rev' element={<FinaliseDocument />} />
													<Route path='revise/:docid/:rev' element={<ReviseDocument />} />
													<Route path='comments/:docid/:rev' element={<DocumentComments />} />
													<Route path='history/:docid/:rev' element={<DocumentHistory />} />
												</Route>
												<Route path='opportunity/:opportunityid/folder' element={<Folder />}>
													<Route path='browse' element={<BrowseDocuments selectedTool={selectedTool} />} />
													<Route path='create' element={<CreateFolder />} />
												</Route>
												<Route path='opportunity/:opportunityid/transmittals' element={<Transmittals />}>
													<Route path='all' element={<AllTransmittals />} />
													<Route path='create' element={<CreateTransmittal />} />
												</Route>
												<Route path='opportunity/:opportunityid/transmittal' element={<Transmittal />}>
													<Route path='view/:transmittalid' element={<ViewTransmittal />} />
												</Route>
											</Routes>
										</div>
									</div>
								</ModalProvider>
							</ClipboardProvider>
						</TransmittalProvider>
					</MessageProvider>
				</UserProvider>
			</BrowserRouter>
		</Provider>
	)
}
export default App
