import React, { useEffect, useState } from 'react'
import { useParams, Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedNode, fetchTransmittals, fetchTransmittalRootFolders } from '../Tree/treeSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/free-solid-svg-icons'
import { useOpportunity } from '../Utilities/TransmittalManager/TransmittalContext'
import TransmittalInformation from './Components/TransmittalInformation'
import { getTransmittal, getTransmittalZip } from '../../utils/fetchUtils'
import { getTransmittalContent, deleteFileFromTransmittal } from '../../utils/fetchUtils'

import { useTransmittal } from '../Utilities/TransmittalManager/TransmittalContext'

const Transmittal = () => {
	const [loadingDocuments, setLoadingDocuments] = useState(true);
	const [transmittal, setTransmittal] = useState();
	const [selectedTransmittal, setSelectedTransmittal] = useState(null)
	const [opportunity, setOpportunity] = useOpportunity()
	const [documents, setDocuments] = useState(null)
	const [zip, setZip] = useState(null)
	let params = useParams()
	const [transmittalContext, setTransmittalContext] = useTransmittal()
	const dispatch = useDispatch()
	const nodes = useSelector((state) => state.tree.nodes)
	const rootSet = useSelector((state) => state.tree.rootSet)

	const handleDeleteFile = async (storage, ServerRelativeUrl) => {
		setLoadingDocuments(true)
		await deleteFileFromTransmittal(storage, ServerRelativeUrl);
		setDocuments(await getTransmittalContent(opportunity.storage, opportunity.id, params.transmittalid))
		setTransmittalContext(await getTransmittalContent(opportunity.storage, opportunity.id, params.transmittalid))
		setLoadingDocuments(false)
	};

	useEffect(() => {
		const fetchData = async () => {
			setZip(await getTransmittalZip(selectedTransmittal.storage, selectedTransmittal.opportunityId, selectedTransmittal.title))
		}
		if (transmittal && selectedTransmittal) { fetchData() }

	}, [transmittal, selectedTransmittal])

	useEffect(() => {
		const fetchData = async () => {
			const recievedTransmittal = await getTransmittal(selectedTransmittal.storage, selectedTransmittal.opportunityId, selectedTransmittal.title)
			if (recievedTransmittal) {
				setTransmittal(recievedTransmittal)
			}
		}
		if (selectedTransmittal && selectedTransmittal.type !== "transmittalRoot") {
			fetchData()
		}
	}, [selectedTransmittal])


	useEffect(() => {
		if (rootSet) {
			setOpportunity({
				...nodes.find((node) => node.id === params.opportunityid),
			})
		}
		setSelectedTransmittal(nodes.find((node) => node.selected === true))
	}, [nodes])

	useEffect(() => {

		const fetchDocuments = async () => {
			setLoadingDocuments(true)
			setDocuments(await getTransmittalContent(opportunity.storage, opportunity.id, params.transmittalid))
			setLoadingDocuments(false)
		}
		if (opportunity) {
			fetchDocuments()
		}

	}, [opportunity])

	useEffect(() => {
		let node = nodes.find((node) => node.opportunityId === params.opportunityid && node.type === 'transmittal' && node.title === params.transmittalid)
		if (rootSet && node) {
			dispatch(setSelectedNode(node.id))
		} else if (nodes.length) {
			dispatch(
				fetchTransmittalRootFolders({
					opportunityid: params.opportunityid,
					storage: opportunity.storage,
					parentId: `transmittals_${params.opportunityid}`,
					transmittalId: params.transmittalid,
				})
			)
		}
	}, [params])
	useEffect(() => {
		if (!rootSet) {
			if (nodes.length) {
				dispatch(
					fetchTransmittals({
						opportunityid: params.opportunityid,
						parentId: `transmittals_${params.opportunityid}`,
						transmittalId: params.transmittalid,
					})
				)
			}
		}
	}, [params, rootSet, nodes])

	return (
		<div>
			<div className='subpage-heading'>
				<div>
					<h1>
						<FontAwesomeIcon icon={faFolder} />
						<span>{`Transmittal ${selectedTransmittal?.title}`}</span>
					</h1>
				</div>
			</div>

			<TransmittalInformation selectedTransmittal={selectedTransmittal} transmittal={transmittal} zip={zip} setTransmittal={setTransmittal} />
			<Outlet context={{ selectedTransmittal: selectedTransmittal, transmittal: transmittal, documents: documents, loadingDocuments: loadingDocuments, handleDeleteFile: handleDeleteFile }} />

		</div >
	)
}

export default Transmittal
