import React from 'react'

const TopBar = () => {
	return (
		<div className='app-heading'>
			<div className='app-heading-logo'></div>
			<div className='app-heading-name'></div>
		
		</div>
	)
}
export default TopBar
