import React, { useState, useContext } from 'react'

const ActiveTransmittalContext = React.createContext()
const TransmittalContext = React.createContext()
const OpportunityContext = React.createContext()

export const useActiveTransmittal = () => {
	return useContext(ActiveTransmittalContext)
}
export const useTransmittal = () => {
	return useContext(TransmittalContext)
}
export const useOpportunity = () => {
	return useContext(OpportunityContext)
}
const TransmittalProvider = ({ children }) => {
	const [opportunity, setOpportunity] = useState(null)
	const [activeTransmittal, setActiveTransmittal] = useState(null)
	const [transmittal, setTransmittal] = useState(null)
	return (
		<OpportunityContext.Provider value={[opportunity, setOpportunity]}>
			<ActiveTransmittalContext.Provider value={[activeTransmittal, setActiveTransmittal]}>
				<TransmittalContext.Provider value={[transmittal, setTransmittal]}>{children}</TransmittalContext.Provider>
			</ActiveTransmittalContext.Provider>
		</OpportunityContext.Provider>
	)
}
export default TransmittalProvider
