const Auditable = ({document}) => {
	return (
		<>
			<h3>Auditable</h3>
			<div className='doc-properties'>
				<div className='doc-properties-row'>
					<div>created</div>
					<div>{document.created}</div>
				</div>
				<div className='doc-properties-row'>
					<div>modified</div>
					<div>{document.modified}</div>
				</div>
				<div className='doc-properties-row'>
					<div>created By</div>
					<div>{document.createdBy}</div>
				</div>
				<div className='doc-properties-row'>
					<div>modified By</div>
					<div>{document.modifiedBy}</div>
				</div>
			</div>
		</>
	)
}
export default Auditable