import '@pnp/sp/webs'
import '@pnp/sp/lists'
import '@pnp/sp/items'
import '@pnp/sp/items/get-all'
import '@pnp/sp/folders'
import '@pnp/sp/files'
import '@pnp/sp/files/folder'
import '@pnp/sp/fields'
import '@pnp/sp/profiles'
import '@pnp/sp/sputilities'
import '@pnp/sp/site-users/web'
import '@pnp/sp/webs'
import '@pnp/sp/batching'

import { createBatch } from '@pnp/sp/batching'
import { sp } from './pnpjs.js'
import { GetFormattedDate } from './appUtils'
import { updatePermissions, PDFRender, PDFRenderCompleted, DocumentStatus, MoveFile, Export } from './azureFunctions'
export const addFolder = async (storage, ServerRelativeUrl, folderName) => {
	const folder = await sp(storage).web.folders.addUsingPath(`${ServerRelativeUrl}/${folderName}`)
	return folder.data
}
export const sendEmails = async (taggedUsers, siteUsers) => {
	const uniqueIds = new Set()
	const uniqueMentions = taggedUsers.filter((element) => {
		const isDuplicate = uniqueIds.has(element.id)
		uniqueIds.add(element.id)
		if (!isDuplicate) {
			return true
		}
		return false
	})

	for (let mention of uniqueMentions) {
		const user = siteUsers.find((user) => user.Id === parseInt(mention.id))
		if (!user) continue
		let EmailToRequestedReviewer = {
			To: [user.Email],
			Subject: 'You have been mentioned in the comment',
			Body: `Please check - ${window.location.href}`,
			AdditionalHeaders: {
				'content-type': 'text/html',
			},
		}
		await sp(process.env.REACT_APP_BASESITE).utility.sendEmail(EmailToRequestedReviewer)
	}
}
export const addComment = async (storage, comment, reply, ID, opportunity, docId, revision, userId) => {
	const listPath = `${process.env.REACT_APP_SHAREPOINTURL}${storage}/Lists/Comments/${opportunity}`
	const item = [
		{ FieldName: 'Title', FieldValue: opportunity },
		{
			FieldName: 'DocumentID',
			FieldValue: docId,
		},
		{
			FieldName: 'DocumentRevision',
			FieldValue: revision.toString(),
		},
		{ FieldName: 'Type', FieldValue: reply ? 'Reply' : 'Comment' },
		{ FieldName: 'Comment', FieldValue: comment },
		{ FieldName: 'ReplyTo', FieldValue: ID ? ID.toString() : null },
		{ FieldName: 'ViewedBy', FieldValue: `[${userId.toString()}]` },
	]
	await sp(storage).web.lists.getByTitle('Comments').addValidateUpdateItemUsingPath(item, listPath)
}
export const getSiteUers = async () => {
	let users = await sp(process.env.REACT_APP_BASESITE).web.siteUsers()
	users = users.filter((user) => user.Email.length > 0)
	users.forEach((user) => {
		user.id = user.Id
		user.display = user.Title
	})
	return users
}
export const getUserProfile = async () => {
	return await sp(process.env.REACT_APP_BASESITE).web.currentUser()
}
export const getCommentsAndRepliesForDoc = async (storage, documentid) => {
	const GetComments = () => {
		return sp(storage)
			.web.lists.getByTitle('Comments')
			.items.select('Title,DocumentID,DocumentRevision,Type,Comment,ReplyTo,Modified,Created,Modified,Created,ID,Author/Title,Editor/Title')
			.filter(`DocumentID eq '${documentid}' and Type eq 'Comment'`)
			.expand('Author,Editor')
			.top(100)
			.orderBy('Created', false)()
	}
	const GetReplies = () => {
		return sp(storage)
			.web.lists.getByTitle('Comments')
			.items.select('Title,DocumentID,DocumentRevision,Type,Comment,ReplyTo,Modified,Created,ID,Author/Title,Editor/Title')
			.filter(`DocumentID eq '${documentid}' and Type eq 'Reply'`)
			.expand('Author,Editor')
			.top(100)
			.orderBy('Created', false)()
	}

	const Comments = await GetComments()
	const Replies = await GetReplies()

	return Comments.reduce((r, Comment) => {
		Comment.Replies = Replies.filter((Reply) => Reply.ReplyTo === Comment.ID)
		r[Comment.DocumentRevision] = [...(r[Comment.DocumentRevision] || []), Comment]
		return r
	}, {})
}
export const getAllComments = async (storage) => {
	const comments = await sp(storage).web.lists.getByTitle('Comments').items.select('DocumentID', 'ViewedBy').getAll()
	comments.forEach((comment) => {
		if (comment.ViewedBy != null) {
			comment.ViewedBy = JSON.parse(comment.ViewedBy)
		} else comment.ViewedBy = []
	})
	return comments
}
export const updateCommentsViewedBy = async (storage, userId, documentId) => {
	const spfi = sp(storage)
	const comments = await spfi.web.lists.getByTitle('Comments').items.select('ID', 'ViewedBy').filter(`DocumentID eq '${documentId}'`).getAll()
	if (comments.length === 0) return

	comments.forEach((comment) => {
		if (comment.ViewedBy != null) {
			comment.ViewedBy = JSON.parse(comment.ViewedBy)
		} else comment.ViewedBy = []
	})

	let list = spfi.web.lists.getByTitle('Comments')
	const [batchedListBehavior, execute] = createBatch(list)
	list.using(batchedListBehavior)

	for (let comment of comments) {
		if (!comment.ViewedBy.includes(userId)) {
			comment.ViewedBy.push(userId)
			const updatedMentions = JSON.stringify(comment.ViewedBy)
			list.items.getById(comment.Id).update({ ViewedBy: updatedMentions })
		}
	}

	await execute()
}
export const getAllRegions = async () => {
	const Regions = await sp(process.env.REACT_APP_BASESITE).web.lists.getByTitle('Regions').items.getAll()
	return Regions.map((Region) => {
		return {
			type: 'region',
			title: Region.Title,
			id: `region_${Region.ID}`,
			SPID: Region.ID,
			parent: '#',
			childrenLoaded: false,
			canExpand: true,
			expanded: false,
			root: false,
			selected: false,
			loading: false,
			link: `/region/${Region.Title}`,
		}
	})
}
export const getAllSubRegions = async () => {
	const SubRegions = await sp(process.env.REACT_APP_BASESITE).web.lists.getByTitle('SubRegions').items.getAll()
	return SubRegions.map((SubRegion) => {
		return {
			type: 'subregion',
			title: SubRegion.Title,
			id: `subregion_${SubRegion.ID}`,
			SPID: SubRegion.ID,
			parent: `region_${SubRegion.ParentRegionID}`,
			canExpand: true,
			childrenLoaded: false,
			expanded: false,
			root: false,
			selected: false,
			loading: false,
			link: `/subregion/${SubRegion.Title}`,
		}
	})
}
export const getAllCountries = async () => {
	const Countries = await sp(process.env.REACT_APP_BASESITE).web.lists.getByTitle('Countries').items.getAll()
	return Countries.map((Country) => {
		return {
			type: 'country',
			title: Country.Title,
			id: `country_${Country.ID}`,
			SPID: Country.ID,
			parent: `subregion_${Country.SubRegionID}`,
			canExpand: true,
			childrenLoaded: false,
			expanded: false,
			root: false,
			selected: false,
			loading: false,
			link: `/country/${Country.Title}`,
		}
	})
}
export const getCountry = async (id) => {
	return await sp(process.env.REACT_APP_BASESITE).web.lists.getByTitle('Countries').items.getById(id)()
}
export const getInitialStateNodes = async () => {
	return Promise.all([getAllRegions(), getAllSubRegions(), getAllCountries()]).then((data) => {
		let FetchedRegions = data[0].map((Region) => {
			return {
				...Region,
				childrenLoaded: true,
			}
		})
		let FetchedSubRegions = data[1].map((SubRegion) => {
			return {
				...SubRegion,
				childrenLoaded: true,
			}
		})
		let FetchedCountries = data[2]
		return FetchedRegions.concat(FetchedSubRegions).concat(FetchedCountries)
	})
}
export const getOpportunitiesForCountry = async (countryId) => {
	const Opportunities = await sp(process.env.REACT_APP_BASESITE).web.lists.getByTitle('Opportunities').items.filter(`CountryID eq '${countryId}'`)()
	return Opportunities.map((Opportunity) => {
		return {
			type: 'opportunity',
			title: Opportunity.Title,
			id: Opportunity.OpportunityID,
			SPID: Opportunity.ID,
			parent: `country_${countryId}`,
			storage: Opportunity.Storage,
			canExpand: true,
			childrenLoaded: false,
			expanded: false,
			root: false,
			selected: false,
			loading: false,
			link: `/opportunity/${Opportunity.OpportunityID}`,
			AccountName: Opportunity.AccountName,
			SiteLocationName: Opportunity.SiteLocationName,
			ProjectType: Opportunity.ProjectType,
			EBProjectClassification: Opportunity.EBProjectClassification,
			NumberofEng: Opportunity.NumberofEng,
			PrimaryEngineType: Opportunity.PrimaryEngineType,
			NumberofCyl: Opportunity.NumberofCyl,
			BusinessLineES: Opportunity.BusinessLineES,

		}
	})
}
export const getStorageByOpportunityId = async (opportunityId) => {
	const Storage = await sp(process.env.REACT_APP_BASESITE)
		.web.lists.getByTitle('Opportunities')
		.items.select('Storage')
		.filter(`OpportunityID eq '${opportunityId}'`)()
	if (Storage.length) {
		return Storage[0].Storage
	}
	return false
}
export const getOpportunityById = async (opportunityId) => {
	const Opportunity = await sp(process.env.REACT_APP_BASESITE).web.lists.getByTitle('Opportunities').items.filter(`OpportunityID eq '${opportunityId}'`)()
	return {
		type: 'opportunity',
		title: Opportunity[0].Title,
		id: Opportunity[0].OpportunityID,
		SPID: Opportunity[0].ID,
		parent: `country_${Opportunity[0].CountryID}`,
		storage: Opportunity[0].Storage,
		countryId: Opportunity[0].CountryID,
		canExpand: true,
		childrenLoaded: false,
		expanded: false,
		root: false,
		selected: false,
		loading: false,
		link: `/opportunity/${Opportunity[0].OpportunityID}`,
	}
}
export const getOpportunityRootFolders = async (opportunityId, storage) => {
	const OpportunityRootFolders = await sp(storage)
		.web.lists.getByTitle(opportunityId)
		.rootFolder.folders.filter(`ListItemAllFields/Id ne null`)
		.expand('ListItemAllFields')
		.orderBy('Name', true)()
	let TransmittalParentFolder = {
		type: 'transmittalRoot',
		title: 'Transmittals',
		ServerRelativeUrl: null,
		opportunityId: opportunityId,
		id: `transmittals_${opportunityId}`,
		parent: opportunityId,
		storage: storage,
		canExpand: true,
		childrenLoaded: false,
		expanded: false,
		root: false,
		selected: false,
		loading: false,
		link: `/opportunity/${opportunityId}/transmittals/all`,
		path: "Transmittals"
	}
	let RootFolders = OpportunityRootFolders.filter((Folder) => !Folder.Name.includes('- Archive')).map((Folder) => {
		return {
			type: 'folder',
			title: Folder.Name,
			path: Folder.Name,
			ServerRelativeUrl: Folder.ServerRelativeUrl,
			opportunityId: opportunityId,
			id: Folder.UniqueId,
			parent: opportunityId,
			storage: storage,
			canExpand: true,
			childrenLoaded: false,
			expanded: false,
			root: false,
			selected: false,
			loading: false,
			link: `/opportunity/${opportunityId}/folder/browse?path=${Folder.Name}`,
			isRootFolder: true
		}
	})
	RootFolders.push(TransmittalParentFolder)
	console.log("RootFolders", RootFolders)
	return RootFolders
}
export const getSubfoldersByParentPath = async (path, opportunityId, storage, parentId) => {
	const SubFolders = await sp(storage).web.getFolderByServerRelativePath(`/sites/${storage}/${opportunityId}/${path}`).folders()
	SubFolders.sort((a, b) => a.Name > b.Name ? 1 : -1)
	return SubFolders.map((Folder) => {
		return {
			type: 'folder',
			title: Folder.Name,
			path: `${path}/${Folder.Name}`,
			ServerRelativeUrl: Folder.ServerRelativeUrl,
			opportunityId: opportunityId,
			id: Folder.UniqueId,
			parent: parentId ? parentId : null,
			storage: storage,
			canExpand: true,
			childrenLoaded: false,
			expanded: false,
			root: false,
			selected: false,
			loading: false,
			link: `/opportunity/${opportunityId}/folder/browse?path=${path}/${Folder.Name}`,
			parentPath: path,
		}
	})
}
export const getSubfoldersByParentId = async (opportunityId, storage, parentId) => {
	const SubFolders = await sp(storage).web.getFolderById(parentId).folders()
	return SubFolders.map((Folder) => {
		let path = Folder.ServerRelativeUrl.substring(
			Folder.ServerRelativeUrl.indexOf(`/sites/${storage}/${opportunityId}/`) + `/sites/${storage}/${opportunityId}/`.length
		)
		let parentPath = path.split('/')
		parentPath.pop()
		return {
			type: 'folder',
			title: Folder.Name,
			path: path,
			ServerRelativeUrl: Folder.ServerRelativeUrl,
			opportunityId: opportunityId,
			id: Folder.UniqueId,
			parent: parentId ? parentId : null,
			storage: storage,
			canExpand: true,
			childrenLoaded: false,
			expanded: false,
			root: false,
			selected: false,
			loading: false,
			link: `/opportunity/${opportunityId}/folder/browse?path=${path}`,
			parentPath: parentPath.join('/'),
		}
	})
}
export const getDocumentsByPath = async (path, opportunityId, storage, ServerRelativeUrl) => {
	const Documents = await sp(storage)
		.web.getFolderByServerRelativePath(ServerRelativeUrl)
		.files.expand('ListItemAllFields', 'ListItemAllFields/File_x0020_Type', 'Author', 'ModifiedBy')
		.filter('ListItemAllFields/SalesDCM_DocumentLatestRevision eq true')()
	let Renders = Documents.filter((Document) => Document.ListItemAllFields.SalesDCM_DocumentStatus === 'Render')
	const FindRenderFile = (DocumentID, DocumentRevision) => {
		let RenderFile = Renders.find(
			(Render) =>
				Render.ListItemAllFields.SalesDCM_DocumentID === DocumentID && Render.ListItemAllFields.SalesDCM_DocumentRevision === DocumentRevision
		)
		return RenderFile
			? {
				ServerRelativeUrl: RenderFile.ServerRelativeUrl,
				title: RenderFile.ListItemAllFields.SalesDCM_DocumentTitle,
				documentid: RenderFile.ListItemAllFields.SalesDCM_DocumentID,
				revision: RenderFile.ListItemAllFields.SalesDCM_DocumentRevision,
				fileType: RenderFile.ListItemAllFields.File_x0020_Type,
				status: RenderFile.ListItemAllFields.SalesDCM_DocumentStatus,
				renderStatus: RenderFile.ListItemAllFields.SalesDCM_Render,
				class: RenderFile.ListItemAllFields.SalesDCM_DocumentClass,
				modifiedBy: RenderFile.ModifiedBy.Title,
				modified: GetFormattedDate(RenderFile.ListItemAllFields.Modified),
				createdBy: RenderFile.Author.Title,
				created: GetFormattedDate(RenderFile.ListItemAllFields.Created),
			}
			: null
	}

	return Documents.filter((Document) => Document.ListItemAllFields.SalesDCM_DocumentStatus !== 'Render').map((Document) => {
		return {
			path: path,
			opportunityId: opportunityId,
			storage: storage,
			ServerRelativeUrl: Document.ServerRelativeUrl,
			title: Document.ListItemAllFields.SalesDCM_DocumentTitle,
			documentid: Document.ListItemAllFields.SalesDCM_DocumentID,
			revision: Document.ListItemAllFields.SalesDCM_DocumentRevision,
			fileType: Document.ListItemAllFields.File_x0020_Type,
			revisions: [],
			render: FindRenderFile(Document.ListItemAllFields.SalesDCM_DocumentID, Document.ListItemAllFields.SalesDCM_DocumentRevision),
			status: Document.ListItemAllFields.SalesDCM_DocumentStatus,
			renderStatus: Document.ListItemAllFields.SalesDCM_Render,
			class: Document.ListItemAllFields.SalesDCM_DocumentClass,
			modifiedBy: Document.ModifiedBy.Title,
			modified: GetFormattedDate(Document.ListItemAllFields.Modified),
			createdBy: Document.Author.Title,
			created: GetFormattedDate(Document.ListItemAllFields.Created),
			filtered: false,
			selected: false,
		}
	})
}
export const getDocumentTemplates = async () => {
	const templates = await sp(process.env.REACT_APP_BASESITE).web.lists.getByTitle('DocumentTemplates').items.select('File_x0020_Type').expand('File')();
	templates.forEach(t => {
		t.File.name = t.File.Name;
		t.File.size = 0
	})
	return templates;
}
export const getDocumentByIdAndRevision = async (opportunityId, storage, documentid, revision) => {
	const Documents = await sp(storage)
		.web.lists.getByTitle(opportunityId)
		.items.select(
			'File_x0020_Type',
			'ID',
			'SalesDCM_DocumentID',
			'SalesDCM_DocumentRevision',
			'SalesDCM_DocumentStatus',
			'SalesDCM_DocumentTitle',
			'SalesDCM_DocumentClass',
			'SalesDCM_InformationClass',
			'SalesDCM_ArchivalCode',
			'SalesDCM_ApplicationArea',
			'SalesDCM_KnowledgeArea',
			'SalesDCM_Render',
			'SalesDCM_BusinessCategory',
			'Created',
			'Modified',
			'Author/Title',
			'Editor/Title'
		)
		.expand('File', 'Author', 'Editor')
		.filter(`SalesDCM_DocumentID eq '${documentid}' and SalesDCM_DocumentRevision eq ${revision}`)()
	let Native = Documents.filter((document) => document.SalesDCM_DocumentStatus !== 'Render')
	let Render = Documents.filter((document) => document.SalesDCM_DocumentStatus === 'Render')
	if (Native.length > 0) {
		Native = Native[0]
		let NativePath = Native.File.ServerRelativeUrl.substring(
			Native.File.ServerRelativeUrl.indexOf(`/sites/${storage}/${opportunityId}/`) + `/sites/${storage}/${opportunityId}/`.length
		)
		NativePath = NativePath.substring(0, NativePath.indexOf(Native.File.Name) - 1)
		let NativeCreatedDate = GetFormattedDate(Native.Created)
		let NativeModifiedDate = GetFormattedDate(Native.Modified)
		Native = {
			opportunityId: opportunityId,
			storage: storage,
			ServerRelativeUrl: Native.File.ServerRelativeUrl,
			path: NativePath,
			documentid: Native.SalesDCM_DocumentID,
			revision: Native.SalesDCM_DocumentRevision,
			status: Native.SalesDCM_DocumentStatus,
			modifiedBy: Native.Editor.Title,
			modified: NativeModifiedDate,
			createdBy: Native.Author.Title,
			created: NativeCreatedDate,
			SPID: Native.ID,
			fileType: Native.File_x0020_Type,
			etag: Native['odata.etag'],
			title: Native.SalesDCM_DocumentTitle,
			class: Native.SalesDCM_DocumentClass,
			renderStatus: Native.SalesDCM_Render,
			render: null,
			informationClass: Native.SalesDCM_InformationClass,
			archivalCode: Native.SalesDCM_ArchivalCode,
			applicationArea: Native.SalesDCM_ApplicationArea,
			knowledgeArea: Native.SalesDCM_KnowledgeArea,
			businessCategory: Native.SalesDCM_BusinessCategory,
		}
		if (Render.length > 0) {
			Render = Render[0]
			let RenderPath = Render.File.ServerRelativeUrl.substring(
				Render.File.ServerRelativeUrl.indexOf(`/sites/${storage}/${opportunityId}/`) + `/sites/${storage}/${opportunityId}/`.length
			)
			RenderPath = RenderPath.substring(0, RenderPath.indexOf(Render.File.Name) - 1)
			let RenderCreatedDate = GetFormattedDate(Render.Created)
			let RenderModifiedDate = GetFormattedDate(Render.Modified)
			Render = {
				opportunityId: opportunityId,
				storage: storage,
				ServerRelativeUrl: Render.File.ServerRelativeUrl,
				path: RenderPath,
				documentid: Render.SalesDCM_DocumentID,
				revision: Render.SalesDCM_DocumentRevision,
				status: Render.SalesDCM_DocumentStatus,
				modifiedBy: Render.Editor.Title,
				modified: RenderModifiedDate,
				createdBy: Render.Author.Title,
				created: RenderCreatedDate,
				SPID: Render.ID,
				fileType: Render.File_x0020_Type,
				etag: Render['odata.etag'],
				title: Render.SalesDCM_DocumentTitle,
				class: Render.SalesDCM_DocumentClass,
				renderStatus: Render.SalesDCM_Render,
				informationClass: Render.SalesDCM_InformationClass,
				archivalCode: Render.SalesDCM_ArchivalCode,
				applicationArea: Render.SalesDCM_ApplicationArea,
				knowledgeArea: Render.SalesDCM_KnowledgeArea,
				businessCategory: Render.SalesDCM_BusinessCategory,
			}
			Native.render = Render
		}
		return Native
	} else {
		return null
	}
}
export const getRevisionsById = async (opportunityId, storage, documentid) => {
	const Documents = await sp(storage)
		.web.lists.getByTitle(opportunityId)
		.items.select(
			'File_x0020_Type',
			'ID',
			'SalesDCM_DocumentID',
			'SalesDCM_DocumentRevision',
			'SalesDCM_DocumentStatus',
			'SalesDCM_DocumentTitle',
			'SalesDCM_DocumentClass',
			'SalesDCM_InformationClass',
			'SalesDCM_ArchivalCode',
			'SalesDCM_ApplicationArea',
			'SalesDCM_KnowledgeArea',
			'SalesDCM_BusinessCategory',
			'Created',
			'Modified',
			'Author/Title',
			'Editor/Title'
		)
		.expand('File', 'Author', 'Editor')
		.filter(`SalesDCM_DocumentID eq '${documentid}' and SalesDCM_DocumentLatestRevision eq false`)()

	if (Documents.length) {
		let Renders = Documents.filter((Document) => Document.SalesDCM_DocumentStatus === 'Render')
		let Natives = Documents.filter((Document) => Document.SalesDCM_DocumentStatus !== 'Render')
		return Natives.map((Document) => {
			let createdDate = GetFormattedDate(Document.Created)
			let modifiedDate = GetFormattedDate(Document.Modified)
			return {
				opportunityId: opportunityId,
				storage: storage,
				ServerRelativeUrl: Document.File.ServerRelativeUrl,
				title: Document.SalesDCM_DocumentTitle,
				documentid: Document.SalesDCM_DocumentID,
				revision: Document.SalesDCM_DocumentRevision,
				status: Document.SalesDCM_DocumentStatus,
				class: Document.SalesDCM_DocumentClass,
				modifiedBy: Document.Editor.Title,
				modified: modifiedDate,
				createdBy: Document.Author.Title,
				created: createdDate,
				SPID: Document.ID,
				fileType: Document.File_x0020_Type,
				informationClass: Document.SalesDCM_InformationClass,
				archivalCode: Document.SalesDCM_ArchivalCode,
				applicationArea: Document.SalesDCM_ApplicationArea,
				knowledgeArea: Document.SalesDCM_KnowledgeArea,
				businessCategory: Document.SalesDCM_BusinessCategory,
				Render: Renders.find((Render) => Render.Title === Document.Title),
			}
		})
	} else {
		return []
	}
}

export const deleteFileFromTransmittal = async (storage, serverRelativeUrl) => {
	await sp(storage).web.getFileByServerRelativePath(serverRelativeUrl).delete();
}
export const getTransmittalContent = async (storage, opportunityId, transmittalId) => {
	const documents = await sp(storage)
		.web.getFolderByServerRelativePath(`/sites/${storage}/${opportunityId}Transmittals/${transmittalId}`)
		.files.expand('ListItemAllFields', 'ListItemAllFields/File_x0020_Type', 'Author', 'ModifiedBy')()
	return documents.map((document) => {
		return {
			ServerRelativeUrl: document.ServerRelativeUrl,
			fileType: document.ListItemAllFields.File_x0020_Type,
			opportunityId: opportunityId,
			title: document.ListItemAllFields.SalesDCM_DocumentTitle,
			documentid: document.ListItemAllFields.SalesDCM_DocumentID,
			revision: document.ListItemAllFields.SalesDCM_DocumentRevision,
			status: document.ListItemAllFields.SalesDCM_DocumentStatus,
			class: document.ListItemAllFields.SalesDCM_DocumentClass,
			modifiedBy: document.ModifiedBy.Title,
			modified: GetFormattedDate(document.ListItemAllFields.Modified),
			createdBy: document.Author.Title,
			created: GetFormattedDate(document.ListItemAllFields.Created),
		}
	})
}
export const getTransmittalRootFolders = async (opportunityId, storage, parentId) => {
	const RootFolders = await sp(storage)
		.web.lists.getByTitle(`${opportunityId}Transmittals`)
		.rootFolder.folders.filter('ListItemAllFields/Id ne null')
		.expand('ListItemAllFields')
		.orderBy('Name', true)()
	return RootFolders.map((Folder) => {
		return {
			type: 'transmittal',
			title: Folder.Name,
			path: Folder.Name,
			ServerRelativeUrl: Folder.ServerRelativeUrl,
			opportunityId: opportunityId,
			id: Folder.UniqueId,
			parent: parentId,
			storage: storage,
			childrenLoaded: false,
			canExpand: false,
			expanded: false,
			root: false,
			selected: false,
			loading: false,
			link: `opportunity/${opportunityId}/transmittal/view/${Folder.Name}`,
		}
	})
}
export const getTransmittal = async (storage, opportunityId, transmittalId) => {
	const Transmittal = await sp(storage)
		.web.lists.getByTitle('Transmittals')
		.items.select(
			'Message,Notes,Title,TransmittalID,TransmittalStatus,TransmittalSent,TransmittalName,TransmittalDescription,cc,to,ID,Modified,Created,Author/Title,Editor/Title'
		)
		.expand('Author,Editor')
		.filter(`Title eq '${opportunityId}' and TransmittalID eq '${transmittalId}'`)
		.getAll()
	if (Transmittal.length) {
		return {
			id: Transmittal[0].TransmittalID,
			created: GetFormattedDate(Transmittal[0].Created),
			createdBy: Transmittal[0].Author.Title,
			opportunityId: opportunityId,
			name: Transmittal[0].TransmittalName,
			SPID: Transmittal[0].ID,
			description: Transmittal[0].TransmittalDescription,
			message: Transmittal[0].Message,
			notes: Transmittal[0].Notes,
			status: Transmittal[0].TransmittalStatus,
			recipient: null,
		}
	}
	return false
}
export const getTransmittalZip = async (storage, opportunityId, transmittalId) => {
	let item = await sp(storage)
		.web.lists.getByTitle('Transmittals')
		.items.select('AttachmentFiles')
		.expand('AttachmentFiles')
		.filter(`Title eq '${opportunityId}' and TransmittalID eq '${transmittalId}'`)
		.getAll()
	if (item[0].AttachmentFiles.length) {
		return process.env.REACT_APP_ROOTSITE + item[0].AttachmentFiles[0].ServerRelativeUrl
	} else {
		return null
	}
}
export const closeTransmittal = async (storage, opportunityId, transmittalId, ServerRelativeUrl, email, SPID, message, created, data) => {
	await updateTransmittal(storage, SPID, data)
	Export(storage, opportunityId, ServerRelativeUrl, transmittalId, email, SPID, message, created)
	return
}
export const updateTransmittal = async (storage, SPID, data) => {
	return await sp(storage).web.lists.getByTitle('Transmittals').items.getById(SPID).update(data)
}
export const getTransmittals = async (storage, opportunityId) => {
	let OpenTransmittals = await sp(storage)
		.web.lists.getByTitle('Transmittals')
		.items.select(
			'Message,Notes,Title,TransmittalID,TransmittalSent,TransmittalStatus,TransmittalName,TransmittalDescription,cc,to,ID,Modified,Created,Author/Title,Editor/Title'
		)
		.expand('Author,Editor')
		.filter(`Title eq '${opportunityId}' and TransmittalStatus ne null`)
		.getAll()

	return OpenTransmittals.map((transmittal) => {
		return {
			id: transmittal.TransmittalID,
			created: GetFormattedDate(transmittal.Created),
			createdBy: transmittal.Author.Title,
			opportunityId: transmittal.Title,
			name: transmittal.TransmittalName,
			description: transmittal.TransmittalDescription,
			status: transmittal.TransmittalStatus,
			recipient: null,
			SPID: transmittal.ID,
			message: transmittal.Message,
			notes: transmittal.Notes,
		}
	})
}
export const getDocumentClassification = async (DocumentClass) => {
	if (DocumentClass) {
		const DocumentClassification = await sp(process.env.REACT_APP_BASESITE)
			.web.lists.getByTitle('DocumentClassification')
			.items.filter(`Title eq '${DocumentClass}'`)()
		if (DocumentClassification.length) {
			return {
				MainClass: DocumentClassification[0].MainClass,
				SubClass: DocumentClassification[0].SubClass,
				Class: DocumentClassification[0].Title,
			}
		}
		return false
	}
	return false
}
export const getDocumentClassifications = async () => {
	const DocumentClassifications = await sp(process.env.REACT_APP_BASESITE).web.lists.getByTitle('DocumentClassification').items.getAll()
	let data = [...new Set(DocumentClassifications.map((Classification) => Classification.MainClass))].map((MainClass) => {
		return {
			MainClass: MainClass,
			SubClasses: [...new Set(DocumentClassifications.filter((Classes) => Classes.MainClass === MainClass).map((SubClass) => SubClass.SubClass))].map(
				(SubClass) => {
					return {
						SubClass: SubClass,
						Classes: [...new Set(DocumentClassifications.filter((Classes) => Classes.SubClass === SubClass).map((Class) => Class.Title))],
					}
				}
			),
		}
	})
	return {
		grouped: true,
		items: data.map((MainClass, MainIndex) => {
			return {
				label: MainClass.MainClass,
				items: MainClass.SubClasses.map((SubClass, SubIndex) => {
					return SubClass.Classes.map((Class, index) => {
						return { id: MainIndex + '_' + SubIndex + '_' + index, label: Class }
					})
				}).flat(),
			}
		}),
	}
}
export const getKnowledgeAreaChoices = async (storage) => {
	const Choices = await sp(storage).web.fields.getByTitle('SalesDCM_KnowledgeArea').select('Choices')()
	return Choices.Choices.map((Choice, index) => {
		return { id: index, label: Choice }
	})
}
export const getApplicationAreaChoices = async (storage) => {
	const Choices = await sp(storage).web.fields.getByTitle('SalesDCM_ApplicationArea').select('Choices')()
	return Choices.Choices.map((Choice, index) => {
		return { id: index, label: Choice }
	})
}
export const getArchivalCodeChoices = async (storage) => {
	const Choices = await sp(storage).web.fields.getByTitle('SalesDCM_ArchivalCode').select('Choices')()
	return Choices.Choices.map((Choice, index) => {
		return { id: index, label: Choice }
	})
}
export const getInformationClassChoices = async (storage) => {
	const Choices = await sp(storage).web.fields.getByTitle('SalesDCM_InformationClass').select('Choices')()
	return Choices.Choices.map((Choice, index) => {
		return { id: index, label: Choice }
	})
}
export const EditDocument = async (storage, opportunityId, id, item) => {
	let data = {
		SalesDCM_DocumentTitle: item.title.value,
		SalesDCM_InformationClass: item.informationClass.value,
		SalesDCM_ArchivalCode: item.archivalCode.value,
		SalesDCM_ApplicationArea: item.applicationArea.value,
		SalesDCM_KnowledgeArea: item.knowledgeArea.value,
		SalesDCM_Render: item.pdfRender.value,
	}
	if (item.class.value) {
		const DocumentClassification = await getDocumentClassification(item.class.value)
		if (DocumentClassification) {
			Object.assign(data, { SalesDCM_DocumentMainClass: DocumentClassification.MainClass })
			Object.assign(data, { SalesDCM_DocumentSubClass: DocumentClassification.SubClass })
			Object.assign(data, { SalesDCM_DocumentClass: DocumentClassification.Class })
		}
	} else {
		Object.assign(data, { SalesDCM_DocumentMainClass: null })
		Object.assign(data, { SalesDCM_DocumentSubClass: null })
		Object.assign(data, { SalesDCM_DocumentClass: null })
	}
	await sp(storage).web.lists.getByTitle(opportunityId).items.getById(id).update(data)
}
export const FinaliseDocument = async (storage, opportunityId, id, item, ServerRelativeUrl, fileType) => {
	const DocumentClassification = await getDocumentClassification(item.class.value)
	if (DocumentClassification) {
		let data = {
			SalesDCM_DocumentTitle: item.title.value,
			SalesDCM_DocumentMainClass: DocumentClassification.MainClass,
			SalesDCM_DocumentSubClass: DocumentClassification.SubClass,
			SalesDCM_DocumentClass: DocumentClassification.Class,
			SalesDCM_InformationClass: item.informationClass.value,
			SalesDCM_ArchivalCode: item.archivalCode.value,
			SalesDCM_ApplicationArea: item.applicationArea.value,
			SalesDCM_KnowledgeArea: item.knowledgeArea.value,
			SalesDCM_Render: item.pdfRender.value,
			SalesDCM_DocumentStatus: 'Finalised',
		}
		await sp(storage).web.lists.getByTitle(opportunityId).items.getById(id).update(data)
		await updatePermissions(storage, opportunityId, id)
		if (item.pdfRender.value !== 'Manual') {
			if (fileType !== 'pdf') {
				PDFRender(storage, opportunityId, id, ServerRelativeUrl, fileType).then(response => {
					console.log("RESPONSE", response);
					if (!response) {
						let data = {
							SalesDCM_Render: "Manual",
						}
						sp(storage).web.lists.getByTitle(opportunityId).items.getById(id).update(data)
					}

				});
			}
		}
	}
}
export const VerifyEtag = async (storage, opportunityId, id, currentEtag) => {
	const etag = await sp(storage).web.lists.getByTitle(opportunityId).items.select('etag').getById(id)()
	if (etag) {
		if (etag['odata.etag'] === currentEtag) {
			return true
		}
		return false
	}
	return false
}
export const ReviseDocument = async (storage, opportunityId, id, item, etag, revision, documentid, ServerRelativeUrl, renderId, filesToArchive) => {
	const DocumentClassification = await getDocumentClassification(item.class.value)
	const VerifiedEtag = await VerifyEtag(storage, opportunityId, id, etag)
	if (DocumentClassification && VerifiedEtag) {
		const NewRevision = revision + 1
		const OldTitle = `${documentid}_${revision.toString().padStart(2, '0')}`
		const NewTitle = `${documentid}_${NewRevision.toString().padStart(2, '0')}`
		const DestinationUrl = ServerRelativeUrl.replace(OldTitle, NewTitle)
		await sp(storage).web.getFileByServerRelativePath(ServerRelativeUrl).copyTo(DestinationUrl, false)
		let data = {
			Title: NewTitle,
			SalesDCM_DocumentTitle: item.title.value,
			SalesDCM_DocumentMainClass: DocumentClassification.MainClass,
			SalesDCM_DocumentSubClass: DocumentClassification.SubClass,
			SalesDCM_DocumentClass: DocumentClassification.Class,
			SalesDCM_InformationClass: item.informationClass.value,
			SalesDCM_ArchivalCode: item.archivalCode.value,
			SalesDCM_ApplicationArea: item.applicationArea.value,
			SalesDCM_KnowledgeArea: item.knowledgeArea.value,
			SalesDCM_DocumentRevision: NewRevision,
			SalesDCM_DocumentLatestRevision: true,
			SalesDCM_DocumentStatus: 'Draft',
			SalesDCM_Render: 'Preview',
		}
		await DocumentStatus(storage, opportunityId, id, 'Expired', false)
		if (renderId) {
			await DocumentStatus(storage, opportunityId, renderId, null, false)
		}
		await MoveFile(storage, filesToArchive)
		const NewFile = await sp(storage).web.getFileByServerRelativePath(DestinationUrl).getItem()
		const { Id } = await NewFile()
		await sp(storage).web.lists.getByTitle(opportunityId).items.getById(Id).update(data)
		return
	}
}
export const GetDocumentIDs = async (AmountOfDocuments) => {
	let currentID = await sp(process.env.REACT_APP_BASESITE).web.lists.getByTitle('DocumentIDGenerator').items.getById(1)()
	await sp(process.env.REACT_APP_BASESITE)
		.web.lists.getByTitle('DocumentIDGenerator')
		.items.getById(1)
		.update({ DocumentID: currentID.DocumentID + AmountOfDocuments }, currentID['odata.etag'])
	const GenerateDocumentID = (SPID) => {
		let idOf = (i) => {
			return (i >= 26 ? idOf(((i / 26) >> 0) - 1) : '') + 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i % 26 >> 0]
		}
		let i = Math.floor((SPID - 1) / 999999)
		let IDtoUse = (SPID - i * 999999).toString().padStart(6, '0')
		let newDocumentID = `${process.env.REACT_APP_DOC_NAME}${idOf(i)}${IDtoUse}`
		return newDocumentID
	}
	return Array.from(Array(AmountOfDocuments).keys()).map((id) => GenerateDocumentID(currentID.DocumentID + id))
}
export const UploadDocuments = async (storage, ServerRelativeUrl, fileList) => {
	let DocumentIDs = await GetDocumentIDs(fileList.length)
	return await Promise.all(
		fileList.map(async (file) => {
			let DocumentID = DocumentIDs.shift()
			const DocumentClassification = await getDocumentClassification(file.metadata.class)
			let data = {
				Title: `${DocumentID}_01`,
				SalesDCM_DocumentID: DocumentID,
				SalesDCM_DocumentTitle: file.metadata.title,
				SalesDCM_DocumentMainClass: DocumentClassification ? DocumentClassification.MainClass : null,
				SalesDCM_DocumentSubClass: DocumentClassification ? DocumentClassification.SubClass : null,
				SalesDCM_DocumentClass: DocumentClassification ? DocumentClassification.Class : null,
				SalesDCM_InformationClass: file.metadata.informationClass,
				SalesDCM_ArchivalCode: file.metadata.archivalCode,
				SalesDCM_ApplicationArea: file.metadata.applicationArea,
				SalesDCM_KnowledgeArea: file.metadata.knowledgeArea,
				SalesDCM_DocumentRevision: 1,
				SalesDCM_DocumentLatestRevision: true,
				SalesDCM_DocumentStatus: 'Draft',
				SalesDCM_Render: 'Preview',
			}
			let FileName = `${DocumentID}_01.${file.metadata.fileExtension}`
			return UploadDocument(storage, ServerRelativeUrl, FileName, file, data)
		})
	)
}
export const UploadRenderFile = async (storage, ServerRelativeUrl, opportunityId, fileList, nativeID) => {
	return await Promise.all(
		fileList.map(async (file) => {
			const DocumentClassification = await getDocumentClassification(file.metadata.class)
			let data = {
				Title: `${file.metadata.documentid}_${file.metadata.revision.toString().padStart(2, '0')}`,
				SalesDCM_DocumentID: file.metadata.documentid,
				SalesDCM_DocumentTitle: file.metadata.title,
				SalesDCM_DocumentMainClass: DocumentClassification ? DocumentClassification.MainClass : null,
				SalesDCM_DocumentSubClass: DocumentClassification ? DocumentClassification.SubClass : null,
				SalesDCM_DocumentClass: DocumentClassification ? DocumentClassification.Class : null,
				SalesDCM_InformationClass: file.metadata.informationClass,
				SalesDCM_ArchivalCode: file.metadata.archivalCode,
				SalesDCM_ApplicationArea: file.metadata.applicationArea,
				SalesDCM_KnowledgeArea: file.metadata.knowledgeArea,
				SalesDCM_DocumentRevision: file.metadata.revision,
				SalesDCM_DocumentLatestRevision: true,
				SalesDCM_DocumentStatus: 'Render',
				SalesDCM_Render: 'NA',
			}
			let FileName = `${file.metadata.documentid}_${file.metadata.revision.toString().padStart(2, '0')}.${file.metadata.fileExtension}`
			let renderID = await UploadDocument(storage, ServerRelativeUrl, FileName, file, data)
			await PDFRenderCompleted(storage, opportunityId, nativeID)
			await updatePermissions(storage, opportunityId, renderID)
		})
	)
}


export const CopyDocumentsTo = async (destinationPath, fileList, storage) => {
	let DocumentIDs = await GetDocumentIDs(fileList.length)
	return await Promise.all(
		fileList.map(async (file) => {
			let DocumentID = DocumentIDs.shift()
			const destinationUrl = `${destinationPath}/${DocumentID}_01.${file.fileType}`
			await sp(file.storage).web.getFileByServerRelativePath(file.ServerRelativeUrl).copyByPath(destinationUrl, false, true)
			const item = await sp(storage).web.getFileByServerRelativePath(destinationUrl).getItem()
			let data = {
				Title: `${DocumentID}_01`,
				SalesDCM_DocumentID: DocumentID,
				SalesDCM_DocumentRevision: 1,
				SalesDCM_DocumentLatestRevision: true,
				SalesDCM_DocumentStatus: 'Draft',
				SalesDCM_Render: 'Preview',
			}
			return await item.update(data)
		})
	)
}
export const MoveDocumentsTo = async (storage, fileList) => {
	return await MoveFile(storage, fileList)
}
export const ReplaceNativeFile = async (storage, ServerRelativeUrl, fileList) => {
	return await Promise.all(
		fileList.map(async (file) => {
			return await ReplaceFile(storage, ServerRelativeUrl, file)
		})
	)
}
export const UploadFile = async (storage, ServerRelativeUrl, FileName, file) => {
	if (file.size <= 10485760) {
		// small upload
		return await sp(storage).web.getFolderByServerRelativePath(ServerRelativeUrl).files.addUsingPath(FileName, file, { Overwrite: false })
	} else {
		// large upload
		return await sp(storage)
			.web.getFolderByServerRelativePath(ServerRelativeUrl)
			.files.addChunked(
				FileName,
				file,
				(data) => {
					console.log(`progress`)
				},
				true
			)
	}
}
export const ReplaceFile = async (storage, ServerRelativeUrl, file) => {
	const filename = ServerRelativeUrl.split('\\').pop().split('/').pop();
	const extension = filename.split('.').pop();
	const officeFiles = ["doc", "docx", "rtf", "xls", "xlsx", "ppt", "pptx"]

	if (officeFiles.includes(extension)) {
		let UploadedFile = await UploadFile(process.env.REACT_APP_BASESITE, `/sites/${process.env.REACT_APP_BASESITE}/ReplaceFileDump`, filename, file)
		const cleanedFile = await sp(process.env.REACT_APP_BASESITE).web.getFileByUrl(UploadedFile.data.ServerRelativeUrl).getBlob();
		if (cleanedFile.size <= 10485760) {
			await sp(storage).web.getFileByServerRelativePath(ServerRelativeUrl).setContent(cleanedFile)
		} else {
			await sp(storage).web.getFileByServerRelativePath(ServerRelativeUrl).setContentChunked(cleanedFile)
		}
		await sp(process.env.REACT_APP_BASESITE).web.getFileByServerRelativePath(UploadedFile.data.ServerRelativeUrl).delete();
	} else {
		if (file.size <= 10485760) {
			// small upload
			await sp(storage).web.getFileByServerRelativePath(ServerRelativeUrl).setContent(file)
		} else {
			// large upload
			await sp(storage).web.getFileByServerRelativePath(ServerRelativeUrl).setContentChunked(file)
		}
	}
}
export const UploadDocument = async (storage, ServerRelativeUrl, FileName, file, data) => {
	let UploadedFile = await UploadFile(storage, ServerRelativeUrl, FileName, file)
	const item = await UploadedFile.file.getItem()
	await item.update(data)
	const { Id } = await item
	return Id
}
export const GetTransmittalID = async (opportunityId) => {
	let currentID = await sp(process.env.REACT_APP_BASESITE).web.lists.getByTitle('TransmittalIDGenerator').items.filter(`Title eq '${opportunityId}'`)()
	await sp(process.env.REACT_APP_BASESITE)
		.web.lists.getByTitle('TransmittalIDGenerator')
		.items.getById(currentID[0].ID)
		.update(
			{
				TransmittalID: currentID[0].TransmittalID + 1,
			},
			currentID['odata.etag']
		)
	return currentID[0].TransmittalID.toString().padStart(4, '0')
}
export const CreateNewTransmittal = async (opportunityId, item) => {
	let storage = await getStorageByOpportunityId(opportunityId)
	let transmittalId = await GetTransmittalID(opportunityId)
	const data = [
		{
			FieldName: 'Title',
			FieldValue: opportunityId,
		},
		{
			FieldName: 'TransmittalStatus',
			FieldValue: 'Open',
		},
		{
			FieldName: 'TransmittalID',
			FieldValue: transmittalId,
		},
		{
			FieldName: 'TransmittalName',
			FieldValue: item.name.value,
		},
		{
			FieldName: 'TransmittalDescription',
			FieldValue: item.description.value,
		},
	]
	const list = await sp(storage).web.lists.getByTitle('Transmittals').select('Title', 'ParentWebUrl')()
	const folderPath = `${list.ParentWebUrl}/Lists/${list.Title}/${opportunityId}`
	await sp(storage).web.lists.getByTitle('Transmittals').addValidateUpdateItemUsingPath(data, folderPath)
	const transmittal = await sp(storage).web.folders.addUsingPath(`/sites/${storage}/${opportunityId}Transmittals/${transmittalId}`)
	return transmittal.data
}
export const AddToTransmittal = async (storage, opportunityId, transmittalID, documents) => {
	return await Promise.all(
		documents.map(async (document) => {
			if (document.renderStatus === 'NA') {
				// Transmit Native
				const fileName = document.ServerRelativeUrl.split('/').pop().split('.').join(`_${document.title}.`)
				const destinationUrl = `/sites/${storage}/${opportunityId}Transmittals/${transmittalID}/${fileName}`
				return await sp(storage).web.getFileByServerRelativePath(document.ServerRelativeUrl).copyTo(destinationUrl, false)
			} else {
				// Transmit Render
				const fileName = document.render.ServerRelativeUrl.split('/').pop().split('.').join(`_${document.render.title}.`)
				const destinationUrl = `/sites/${storage}/${opportunityId}Transmittals/${transmittalID}/${fileName}`
				return await sp(storage).web.getFileByServerRelativePath(document.render.ServerRelativeUrl).copyTo(destinationUrl, false)
			}
		})
	)
}
export const updateRenameFolder = async (storage, ServerRelativeUrl, NewName) => {
	const folder = sp(storage).web.getFolderByServerRelativePath(ServerRelativeUrl)
	const item = await folder.getItem()
	await item.update({ FileLeafRef: NewName })
}
export const getFolderById = async (storage, opportunityId, parentPath, parentId, id) => {
	const Folder = await sp(storage).web.getFolderById(id)()
	return {
		type: 'folder',
		title: Folder.Name,
		path: `${parentPath}/${Folder.Name}`,
		ServerRelativeUrl: Folder.ServerRelativeUrl,
		opportunityId: opportunityId,
		id: Folder.UniqueId,
		parent: parentId ? parentId : null,
		storage: storage,
		childrenLoaded: false,
		canExpand: true,
		expanded: false,
		root: false,
		selected: false,
		loading: false,
		link: `/opportunity/${opportunityId}/folder/browse?path=${parentPath}/${Folder.Name}`,
		parentPath: parentPath,
	}
}
export const getDocumentHistory = async (storage, opportunityId, documentid) => {
	let Documents = await sp(storage)
		.web.lists.getByTitle(opportunityId)
		.items.filter(`SalesDCM_DocumentID eq '${documentid}' and SalesDCM_DocumentStatus ne 'Render'`)
		.select('ID,SalesDCM_DocumentID,SalesDCM_DocumentRevision')
		.getAll()
	let DocumentsVersions = await Promise.all(
		Documents.map(async (document) => {
			const Versiosn = await sp(storage).web.lists.getByTitle(opportunityId).items.getById(document.ID).versions.orderBy('VersionLabel', true)()
			return {
				document: document,
				versions: Versiosn.map((Version) => {
					return {
						VersionLabel: Version.VersionLabel,
						Modified: Version.Modified,
						Editor: Version.Editor.LookupValue,
						ApplicationArea: Version.SalesDCM_x005f_ApplicationArea,
						ApprovalRequestedBy: Version.SalesDCM_x005f_ApprovalRequestedBy,
						ApprovedBy: Version.SalesDCM_x005f_ApprovedBy,
						ArchivalCode: Version.SalesDCM_x005f_ArchivalCode,
						BusinessCategory: Version.SalesDCM_x005f_BusinessCategory,
						DocumentClass: Version.SalesDCM_x005f_DocumentClass,
						DocumentRevision: Version.SalesDCM_x005f_DocumentRevision,
						DocumentStatus: Version.SalesDCM_x005f_DocumentStatus,
						DocumentTitle: Version.SalesDCM_x005f_DocumentTitle,
						InformationClass: Version.SalesDCM_x005f_InformationClass,
						KnowledgeArea: Version.SalesDCM_x005f_KnowledgeArea,
						RequestedApprover: Version.SalesDCM_x005f_RequestedApprover,
						RequestedReviewer: Version.SalesDCM_x005f_RequestedReviewer,
						ReviewRequestedBy: Version.SalesDCM_x005f_ReviewRequestedBy,
						ReviewedBy: Version.SalesDCM_x005f_ReviewedBy,
					}
				}),
			}
		})
	)
	const compareValues = (previousValue, currentValue) => {
		if (previousValue !== currentValue) {
			if (previousValue === null) {
				return {
					difference: 'Set',
					previousValue: previousValue,
					currentValue: currentValue,
				}
			} else {
				return {
					difference: 'Change',
					previousValue: previousValue,
					currentValue: currentValue,
				}
			}
		} else {
			return null
		}
	}
	return DocumentsVersions.map((Document) => {
		return {
			document: Document.document,
			versions: Document.versions.map((version, index) => {
				return {
					VersionLabel: version.VersionLabel,
					Modified: version.Modified,
					Editor: version.Editor,
					data: [
						{
							label: 'Application Area',
							changes: compareValues(index === 0 ? null : Document.versions[index - 1]['ApplicationArea'], version.ApplicationArea),
						},
						{
							label: 'Approval Requested By',
							changes: compareValues(
								index === 0 ? null : Document.versions[index - 1]['ApprovalRequestedBy'],
								version.ApprovalRequestedBy
							),
						},
						{
							label: 'Approved By',
							changes: compareValues(index === 0 ? null : Document.versions[index - 1]['ApprovedBy'], version.ApprovedBy),
						},
						{
							label: 'Archival Code',
							changes: compareValues(index === 0 ? null : Document.versions[index - 1]['ArchivalCode'], version.ArchivalCode),
						},
						{
							label: 'Business Category',
							changes: compareValues(index === 0 ? null : Document.versions[index - 1]['BusinessCategory'], version.BusinessCategory),
						},
						{
							label: 'Class',
							changes: compareValues(index === 0 ? null : Document.versions[index - 1]['DocumentClass'], version.DocumentClass),
						},
						{
							label: 'Revision',
							changes: compareValues(index === 0 ? null : Document.versions[index - 1]['DocumentRevision'], version.DocumentRevision),
						},
						{
							label: 'Status',
							changes: compareValues(index === 0 ? null : Document.versions[index - 1]['DocumentStatus'], version.DocumentStatus),
						},
						{
							label: 'Title',
							changes: compareValues(index === 0 ? null : Document.versions[index - 1]['DocumentTitle'], version.DocumentTitle),
						},
						{
							label: 'Information Classification',
							changes: compareValues(index === 0 ? null : Document.versions[index - 1]['InformationClass'], version.InformationClass),
						},
						{
							label: 'Knowledge Area',
							changes: compareValues(index === 0 ? null : Document.versions[index - 1]['KnowledgeArea'], version.KnowledgeArea),
						},
						{
							label: 'Requested Approver',
							changes: compareValues(index === 0 ? null : Document.versions[index - 1]['RequestedApprover'], version.RequestedApprover),
						},
						{
							label: 'Requested Reviewer',
							changes: compareValues(index === 0 ? null : Document.versions[index - 1]['RequestedReviewer'], version.RequestedReviewer),
						},
						{
							label: 'Review Requested By',
							changes: compareValues(index === 0 ? null : Document.versions[index - 1]['ReviewRequestedBy'], version.ReviewRequestedBy),
						},
						{
							label: 'Reviewed By',
							changes: compareValues(index === 0 ? null : Document.versions[index - 1]['ReviewedBy'], version.ReviewedBy),
						},
					].filter((property) => property.changes),
				}
			}),
		}
	})
}
export const getFileAsBlob = async (storage, ServerRelativeUrl) => {
	return await sp(storage).web.getFileByServerRelativePath(ServerRelativeUrl).getBlob()
}
export const getFileAsBuffer = async (storage, ServerRelativeUrl) => {
	return await sp(storage).web.getFileByServerRelativePath(ServerRelativeUrl).getBuffer()
}
