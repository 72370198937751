import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faFileCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { useModal } from '../Modal/ModalContext'
import { useClipboard } from './ClipboardContext'
import ListOfDocumentsInClipboard from './ListOfDocumentsInClipboard'
const ClipboardManager = () => {
	const selectedDocuments = useSelector((state) => state.browse.documents.filter((document) => document.selected))
	const [modal, setModal] = useModal()
	const [clipboard, setClipboard] = useClipboard()
	const nodes = useSelector((state) => state.tree.nodes)

	const handleOpenClipboard = () => {
		const ModalContent = () => {
			return (
				<div className='page'>
					<h1>Clipboard</h1>
					<ListOfDocumentsInClipboard />

					{/*
					<button className='btn btn-blue mt-25' onClick={() => setModal(null)}>
						Close
					</button>
					<button className='btn btn-blue mt-25' >
						Copy selected document´s to folder
					</button>
					<button className='btn btn-blue mt-25' >
						Move selected document´s to folder
				</button>*/}
				</div>
			)
		}
		setModal(<ModalContent />)
	}
	const Clipboard = () => {
		const selectedFolder = nodes.find((node) => node.selected === true)
		return (
			<div style={selectedFolder?.type === "transmittalRoot" || selectedFolder?.type === "transmittal" ? { opacity: "0.6", cursor: "not-allowed", pointerEvents: "none" } : {}} onClick={() => handleOpenClipboard()}>
				<div>
					<span className='fa-layers fa-fw'>
						<FontAwesomeIcon icon={faClipboardList} />
						<span className='fa-layers-counter transmittal-documents-counter'>{clipboard && clipboard.length}</span>
					</span>
				</div>
				<div>Clipboard</div>
			</div>
		)
	}
	const AddDocument = () => {
		const handleAddDocuments = () => {
			const newClipboard = [...clipboard]
			selectedDocuments.forEach((selectedDocument) => {
				if (!clipboard.find((document) => document.documentid === selectedDocument.documentid && document.revision === selectedDocument.revision)) {
					newClipboard.push({ ...selectedDocument, selected: false })
				}
			})
			setClipboard(newClipboard)
		}
		return (
			<div onClick={() => handleAddDocuments()}>
				<div>
					<FontAwesomeIcon icon={faFileCirclePlus} />
				</div>

				<div>Add</div>
			</div>
		)
	}
	return (
		<>
			<div>Clipboard</div>

			<div className='tool-selector-options'>
				{selectedDocuments.length > 0 && <AddDocument />}
				<Clipboard />
			</div>
		</>
	)
}
export default ClipboardManager
