import React, { useEffect } from 'react'
import { useParams, Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedNode, fetchTransmittals } from '../Tree/treeSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/free-solid-svg-icons'
import { useOpportunity } from '../Utilities/TransmittalManager/TransmittalContext'
import TransmittalsTabsMenu from './Components/TransmittalsTabsMenu'
const Transmittals = () => {
	const [opportunity, setOpportunity] = useOpportunity()

	let params = useParams()
	const dispatch = useDispatch()
	const nodes = useSelector((state) => state.tree.nodes)
	const rootSet = useSelector((state) => state.tree.rootSet)
	useEffect(() => {
		if (rootSet) {
			setOpportunity({
				...nodes.find((node) => node.id === params.opportunityid),
			})
		}
	}, [nodes])
	useEffect(() => {
		let node = nodes.find((node) => node.id === `transmittals_${params.opportunityid}`)
		if (rootSet && node) {
			dispatch(setSelectedNode(node.id))
		}
	}, [params])
	useEffect(() => {
		if (!rootSet) {
			if (nodes.length) {
				dispatch(
					fetchTransmittals({
						opportunityid: params.opportunityid,
						parentId: `transmittals_${params.opportunityid}`,
					})
				)
			}
		}
	}, [params, rootSet, nodes])
	return (
		<div>
			<div className='subpage-heading-document'>
				<h1>
					<FontAwesomeIcon icon={faFolder} />
					<span>Transmittals</span>
				</h1>
				<TransmittalsTabsMenu />
			</div>
			<div className='page'>
				<Outlet opportunity={opportunity} context={{ opportunity: opportunity }} />
			</div>
		</div>
	)
}

export default Transmittals
