import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAllDocuments, deselectAllDocuments, sortDocuments } from './browseSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownAZ, faArrowDownZA } from '@fortawesome/free-solid-svg-icons'
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons'

const SortColumnButton = (props) => {
	const [sortOrder, setSortOrder] = useState(true)
	const dispatch = useDispatch()
	const handleSortColumn = () => {
		setSortOrder((sortOrder) => !sortOrder)
		dispatch(sortDocuments({ columnToSort: props.columnName, order: sortOrder }))
	}
	return (
		<div>
			<span className='sort-column'
				onClick={() => {
					handleSortColumn()
				}}
			>
				<FontAwesomeIcon icon={sortOrder ? faArrowDownAZ : faArrowDownZA} />
				<span>{props.columnTitle}</span>
			</span>
		</div>
	)
}

const Sorter = () => {
	const dispatch = useDispatch()
	const documents = useSelector((state) => state.browse.documents)
	return (
		<div className='document-sort'>
			<div className='document-sort-checkbox'>
				{documents.some((document) => !document.selected) ? (
					<span onClick={() => dispatch(selectAllDocuments())}>
						<FontAwesomeIcon icon={faSquare} />
					</span>
				) : (
					<span onClick={() => dispatch(deselectAllDocuments())}>
						<FontAwesomeIcon icon={faSquareCheck} />
					</span>
				)}
			</div>
			<div className='document-sort-metadata'>
				<div></div>
				<SortColumnButton columnTitle={'Title'} columnName={'title'} />
				<SortColumnButton columnTitle={'Status'} columnName={'status'} />
				<SortColumnButton columnTitle={'Created'} columnName={'created'} />
				<SortColumnButton columnTitle={'Modified'} columnName={'modified'} />
				<div></div>
				<SortColumnButton columnTitle={'Class'} columnName={'class'} />
				<SortColumnButton columnTitle={'Document ID'} columnName={'documentid'} />
				<SortColumnButton columnTitle={'Created by'} columnName={'createdBy'} />
				<SortColumnButton columnTitle={'Modified by'} columnName={'modifiedBy'} />
			</div>
			<div className='document-sort-menu'></div>
		</div>
	)
}
export default Sorter
