import React, { useEffect, useRef, useState } from 'react'
import { useParams, Outlet, useSearchParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchFoldersByPath, setSelectedNode, refreshNodes, addNode, removeNode } from '../Tree/treeSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel, faEdit, faFolder, faFolderPlus, faSave, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons'
import { updateRenameFolder, getFolderById, getSubfoldersByParentId } from '../../utils/fetchUtils'
import { ObjectHasEmptyValues } from '../../utils/appUtils'
import { useSendMessage } from '../Utilities/MessageManager/MessageContext'
import { useOpportunity } from '../Utilities/TransmittalManager/TransmittalContext'
import { addFolder } from '../../utils/fetchUtils'
import { RemoveFolder } from '../../utils/azureFunctions'

const Folder = () => {
	const [opportunity, setOpportunity] = useOpportunity()
	const [selectedFolder, setSelectedFolder] = useState(null)
	const [toggleRenameFolder, setToggleRenameFolder] = useState(false)
	const [toggleCreateFolder, setToggleCreateFolder] = useState(false)
	const [renameFolderLoading, setRenameFolderLoading] = useState(false)
	const [deleteFolderLoading, setDeleteFolderLoading] = useState(false)
	const SendMessage = useSendMessage()
	let params = useParams()
	const dispatch = useDispatch()
	const nodes = useSelector((state) => state.tree.nodes)
	const rootSet = useSelector((state) => state.tree.rootSet)
	let [searchParams, setSearchParams] = useSearchParams()
	let navigate = useNavigate()
	let SelectedFolderPath = searchParams.get('path')
	let renameFolderInput = useRef(null)
	let createFolderInput = useRef(null)
	const documents = useSelector((state) => state.browse.documents)

	useEffect(() => {
		if (rootSet) {
			setOpportunity({
				...nodes.find((node) => node.id === params.opportunityid),
			})
		}
	}, [nodes])
	useEffect(() => {
		let node = nodes.find((node) => node.opportunityId === params.opportunityid && node.path === SelectedFolderPath)
		if (rootSet && node) {
			dispatch(setSelectedNode(node.id))
		}
	}, [params])
	useEffect(() => {
		setSelectedFolder(nodes.find((node) => node.selected === true))
	}, [nodes])
	useEffect(() => {
		if (!rootSet) {
			if (nodes.length) {
				dispatch(
					fetchFoldersByPath({
						opportunityid: params.opportunityid,
						SelectedFolderPath: SelectedFolderPath,
					})
				)
			}
		}
	}, [params, rootSet, nodes])
	const RenameFolder = () => {
		return (
			<div className='rename-folder'>
				<h1>
					<FontAwesomeIcon icon={faFolder} />
				</h1>
				<input ref={renameFolderInput} className='input' type='text' defaultValue={selectedFolder.title}></input>
				<h1 style={{ cursor: 'pointer' }} onClick={() => setToggleRenameFolder(false)}>
					<FontAwesomeIcon icon={faCancel} />
				</h1>
			</div>
		)
	}
	const CreateSubfolder = () => {
		return (
			<div className='rename-folder'>
				<h1>
					<FontAwesomeIcon icon={faFolder} />
				</h1>
				<input ref={createFolderInput} className='input' type='text' placeholder='Name your folder..'></input>
				<h1 style={{ cursor: 'pointer' }} onClick={() => setToggleCreateFolder(false)}>
					<FontAwesomeIcon icon={faCancel} />
				</h1>
			</div>
		)
	}
	const handleSaveRenameFolder = async () => {
		if (!renameFolderLoading) {
			let FolderName = renameFolderInput.current.value
			var format = /[\\\/\:\*\?\""\<\>\&|]/

			//var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/

			const forbiddenCharacters = format.test(FolderName)
			if (forbiddenCharacters) {
				SendMessage('Wrong characters in folder name', 'alert')
				return
			}
			setRenameFolderLoading(true)
			if (
				ObjectHasEmptyValues({
					name: {
						required: true,
						value: FolderName,
					},
				})
			) {
				SendMessage('Required fields missing!', 'alert')
				setRenameFolderLoading(false)
				setToggleRenameFolder(false)
			} else {
				await updateRenameFolder(selectedFolder.storage, selectedFolder.ServerRelativeUrl, FolderName)
				let NewFolder = await getFolderById(
					selectedFolder.storage,
					selectedFolder.opportunityId,
					selectedFolder.parentPath,
					selectedFolder.parent,
					selectedFolder.id
				)
				let nodesToRefresh = nodes
					.filter((node) => node.type === 'folder')
					.filter((node) => node.opportunityId === selectedFolder.opportunityId)
					.filter((node) => node.path.includes(selectedFolder.path) && node.id !== selectedFolder.id)
					.map((node) => {
						return {
							opportunityId: node.opportunityId,
							storage: node.storage,
							parent: node.parent,
						}
					})
				let SubFolders = await Promise.all(nodesToRefresh.map((node) => getSubfoldersByParentId(node.opportunityId, node.storage, node.parent)))
				SubFolders.push(NewFolder)
				dispatch(refreshNodes(SubFolders.flat()))
				setToggleRenameFolder(false)
				setRenameFolderLoading(false)
				SendMessage('Folder successfully renamed!', 'success')
				navigate(`/opportunity/${selectedFolder.opportunityId}/folder/browse?path=${NewFolder.path}`, { replace: true })
			}
		}
	}
	const handleAddSubfolder = async () => {
		const folderName = createFolderInput.current.value.trim()
		var format = /[\\\/\:\*\?\""\<\>\&|]/

		//var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/

		const forbiddenCharacters = format.test(folderName)
		if (forbiddenCharacters) {
			SendMessage('Wrong characters in folder name', 'alert')
			return
		}
		const folder = await addFolder(selectedFolder.storage, selectedFolder.ServerRelativeUrl, folderName)
		const node = {
			ServerRelativeUrl: folder.ServerRelativeUrl,
			canExpand: true,
			childrenLoaded: false,
			expanded: false,
			id: folder.UniqueId,
			link: `${selectedFolder.link}/${folderName}`,
			loading: false,
			opportunityId: selectedFolder.opportunityId,
			parent: selectedFolder.id,
			path: `${selectedFolder.path}/${folderName}`,
			root: false,
			selected: false,
			storage: selectedFolder.storage,
			title: folderName,
			type: 'folder',
			parentPath: selectedFolder.path,
		}

		dispatch(addNode(node))
		setToggleCreateFolder(false)
	}

	const handleRemoveFolder = async () => {
		setDeleteFolderLoading(true);
		try {
			const response = await RemoveFolder(selectedFolder.storage, selectedFolder.ServerRelativeUrl)
			if (response.status !== 200) {
				SendMessage('Something went wrong while deleting the folder', 'alert')
				setDeleteFolderLoading(false);
				return
			}
			navigate(`/opportunity/${selectedFolder.opportunityId}/folder/browse?path=${selectedFolder.parentPath}`, { replace: true })
			dispatch(removeNode(selectedFolder.id))
			setDeleteFolderLoading(false);
		}
		catch (err) {
			SendMessage('Something went wrong while deleting the folder', 'alert')
			console.log("Something went wrong when deleting the folder - ", err);
			setDeleteFolderLoading(false);
		}
	}
	return (
		<div>
			<div className='subpage-heading'>
				<div>
					{selectedFolder ? (
						toggleRenameFolder ? (
							<RenameFolder />
						) : (
							<h1>
								<FontAwesomeIcon icon={faFolder} />
								<span>{selectedFolder.title}</span>
							</h1>
						)
					) : null}
					{toggleCreateFolder && <CreateSubfolder />}
				</div>
				<div>
					{toggleRenameFolder ? (
						<div className='btn-flat btn-flat-blue' onClick={() => handleSaveRenameFolder()}>
							{renameFolderLoading ? (
								<>
									<span>
										<FontAwesomeIcon className='fa-spin' icon={faSpinner} />
									</span>
									<span>Saving..</span>
								</>
							) : (
								<>
									<span>
										<FontAwesomeIcon icon={faSave} />
									</span>
									<span>Save changes</span>
								</>
							)}
						</div>
					) : (
						!toggleCreateFolder && selectedFolder && !selectedFolder.isRootFolder && (
							<div className='btn-flat btn-flat-blue' onClick={() => setToggleRenameFolder((toggleRenameFolder) => !toggleRenameFolder)}>
								<span>
									<FontAwesomeIcon icon={faEdit} />
								</span>
								<span>Rename folder</span>
							</div>
						)
					)}
					{!toggleCreateFolder && !toggleRenameFolder && (
						<>
							<div className='btn-flat btn-flat-blue' onClick={() => setToggleCreateFolder(true)}>
								<span>
									<FontAwesomeIcon icon={faFolderPlus} />
								</span>
								<span>Add subfolder</span>
							</div>

							{selectedFolder && !selectedFolder.isRootFolder && documents.length === 0 ?
								(
									deleteFolderLoading ?
										<FontAwesomeIcon className='fa-spin' icon={faSpinner} /> :
										<div className='btn-flat btn-flat-blue' onClick={() => (handleRemoveFolder())}>
											<span>
												<FontAwesomeIcon icon={faTrash} />
											</span>
											<span>Remove folder</span>
										</div>
								)

								: <></>}
						</>
					)}
					{toggleCreateFolder && (
						<div className='btn-flat btn-flat-blue' onClick={() => handleAddSubfolder()}>
							<span>
								<FontAwesomeIcon icon={faSave} />
							</span>
							<span>Create folder</span>
						</div>
					)}
				</div>
			</div>
			<Outlet context={{ opportunity: opportunity, selectedFolder: selectedFolder }} />
		</div >
	)
}

export default Folder
