import './Tooltip.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Tooltip = ({text, icon}) => {
    return (
        <div className="tooltip">
            <FontAwesomeIcon icon={icon} />
            <span className="tooltiptext">{text}</span>
        </div>
    )
}
export default Tooltip
