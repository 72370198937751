import React, { useEffect, useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faCheck, faXmark, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import './Dropdown.css'
const Dropdown = ({ data, placeholder, selected, searchable, setValue }) => {
	const [items, setItems] = useState([])
	const [isOpen, setOpen] = useState(false)
	const [selectedItem, setSelectedItem] = useState(null)
	const [searchString, setSearchString] = useState(null)
	const wrapperRef = useRef(null)
	const searchRef = useRef()
	
	useEffect(() => {
		if (selected) {
			if (data.grouped) {
				setSelectedItem(
					data.items.find((group) => group.items.some((item) => item.label === selected)).items.find((item) => item.label === selected)
				)
			} else {
				setSelectedItem(data.items.find((item) => item.label === selected))
			}
		}
	}, [])
	useEffect(() => {
		const getGroupedSearchResults = () => {
			return data.items.map((group) => {
				return {
					label: group.label,
					items: group.items.filter((item) => {
						return item.label.toUpperCase().includes(searchString.toUpperCase())
					}),
				}
			})
		}
		const getUngroupedSearchResults = () => {
			return data.items.filter((item) => item.label.toUpperCase().includes(searchString.toUpperCase()))
		}
		if (searchString) {
			setOpen(true)
			if (data.grouped) {
				setItems(getGroupedSearchResults())
			} else {
				setItems(getUngroupedSearchResults())
			}
		} else {
			setItems(data.items)
		}
	}, [searchString])
	
	useEffect(() => {
		if(setValue){
			setValue(selectedItem)
		}
	}, [selectedItem])
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setOpen(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [wrapperRef])
	const handleItemSelect = (item) => {
		if (searchable) {
			searchRef.current.value = ''
			setSearchString(null)
		}
		setSelectedItem(item)
		setItems(data.items)
		setOpen(false)
	}
	const handleItemDeselect = () => {
		setSelectedItem(null)
		setOpen(false)
	}
	const DropdownItem = ({ item }) => {
		return (
			<div className='dropdown-item' key={item.id}>
				<div onClick={() => handleItemSelect(item)}>
					<div>{selectedItem ? item.id == selectedItem.id ? <FontAwesomeIcon icon={faCheck} /> : null : null}</div>
					<div>{item.label}</div>
				</div>
				<div onClick={() => handleItemDeselect()}>
					{selectedItem ? item.id === selectedItem.id ? <FontAwesomeIcon icon={faXmark} /> : null : null}
				</div>
			</div>
		)
	}
	const DropdownGroup = ({ group }) => {
		return group.items.length ? (
			<div className='dropdown-group'>
				<div className='dropdown-group-header'>{group.label}</div>
				{group.items.map((item, index) =>
					item.hasOwnProperty('items') ? <DropdownGroup key={index} group={item} /> : <DropdownItem key={item.id} item={item} />
				)}
			</div>
		) : null
	}
	return (
		<div ref={wrapperRef} className='dropdown'>
			<div className='dropdown-header' onClick={() => setOpen((isOpen) => !isOpen)}>
				{searchable ? <input ref={searchRef} type='text' onChange={(e) => setSearchString(e.target.value)} /> : <div></div>}
				{searchString ? null : (
					<div className='dropdown-selected-item'>
						{selectedItem ? (
							<div>{selectedItem.label}</div>
						) : searchable ? (
							<span>
								<span>
									<FontAwesomeIcon icon={faMagnifyingGlass} />
								</span>
								<span>{placeholder}</span>
							</span>
						) : (
							<span>
								<span>{placeholder}</span>
							</span>
						)}
					</div>
				)}
				<FontAwesomeIcon className={`icon ${isOpen && 'open'}`} icon={faChevronRight} />
			</div>
			<div className={`dropdown-body ${isOpen && 'open'}`}>
				{items.length
					? items.map((item, index) =>
							item.hasOwnProperty('items') ? <DropdownGroup key={index} group={item} /> : <DropdownItem key={item.id} item={item} />
					  )
					: null}
			</div>
		</div>
	)
}
export default Dropdown
