import { Link } from 'react-router-dom'
import './TabsMenu.css'
const TabsMenu = ({ tabs }) => {
	return (
		<div className='tabs-menu'>
			{tabs.filter(tab=> tab.enabled).map((tab, index) => (
				<Link key={index} to={tab.link}>
					<div className={`tabs-menu-tab ${tab.active ? 'tabs-menu-tab-active' : null}`}>{tab.label}</div>
				</Link>
			))}
		</div>
	)
}

export default TabsMenu