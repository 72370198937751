import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { MentionsInput, Mention } from 'react-mentions'

function NewComment(props) {
	const [comment, setComment] = useState();
	const [plainTextComment, setPlainTextComment] = useState("")
	const [taggedUsers, setTaggedUsers] = useState([]);
	const handleSubmit = async () => {
		await props.SendComment(plainTextComment, props.reply, props.commentID, taggedUsers);
		if (!props.reply) { setComment("") }
	}
	const handleMentionChange = (event, newValue, newPlainTextValue, mentions)=>{
		setPlainTextComment(newPlainTextValue);
		setComment(event.target.value);
		setTaggedUsers(mentions);
	}
	return (
		<div className='comment'>
			<div>
				<div>
					<span>
						<FontAwesomeIcon icon={faUserCircle} />
					</span>
					<span>{props.user.Title}</span>
				</div>
			</div>
			<div>
				<MentionsInput
					    style={{ background: "white", height: "8vh", margin: "10px 0 10px 0" }}
						placeholder="Write a comment.. " value={comment} onChange={handleMentionChange}>
					
						<Mention
							trigger="@"
							data={props.siteUsers}
						/>
				</MentionsInput>
			</div>
			<span>
				<span>
					<FontAwesomeIcon icon={faPaperPlane} />
				</span>
				<span onClick={handleSubmit}>Send</span>
			</span>
		</div>
	)
}

export default NewComment
