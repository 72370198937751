import { useParams, useLocation } from 'react-router-dom'
import TabsMenu from '../../Utilities/TabsMenu/TabMenu'

const DocumentTabsMenu = ({status}) => {
	let params = useParams()
	let documentid = params.docid
	let revision = params.rev
	let opportunity = params.opportunityid
	const location = useLocation()
	const RoutedComponent = location.pathname.slice(1).split('/')[3]
	let tabs = [
		{
			link: `/opportunity/${opportunity}/document/view/${documentid}/${revision}`,
			active: RoutedComponent === 'view' ? true : false,
			label: 'View',
			enabled: true,
		},
		{
			link: `/opportunity/${opportunity}/document/edit/${documentid}/${revision}`,
			active: RoutedComponent === 'edit' ? true : false,
			label: 'Edit',
			enabled: status === "Finalised" || status === "Expired" ? false: true
		},
		{
			link: `/opportunity/${opportunity}/document/comments/${documentid}/${revision}`,
			active: RoutedComponent === 'comments' ? true : false,
			label: 'Comments',
			enabled: true,
		},
		{
			link: `/opportunity/${opportunity}/document/history/${documentid}/${revision}`,
			active: RoutedComponent === 'history' ? true : false,
			label: 'History',
			enabled: true,
		},
	]
	return status ? <TabsMenu tabs={tabs}/>: null
}
export default DocumentTabsMenu