export const updatePermissions = async (storage, opportunityId, id) => {
	if (process.env.REACT_APP_ENVIRONMENT === 'Local') {
		var url = 'https://salesdcm-dev-functionapp.azurewebsites.net/api/updatePermissions?code=oY87fu3kauQJAzP967TmwYPOQqc5GZt/WiezZbWHA4I8E1viAtW9gg=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'Development') {
		var url = 'https://salesdcm-dev-functionapp.azurewebsites.net/api/updatePermissions?code=oY87fu3kauQJAzP967TmwYPOQqc5GZt/WiezZbWHA4I8E1viAtW9gg=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'QA') {
		var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/UpdatePermissions?code=U7eHvKd0TfMfAC3cNZkW9BvUDW57z2bMHLPO0iNVX1AYAzFu0EgFsw=='
	}

	if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
		var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/UpdatePermissions?code=U7eHvKd0TfMfAC3cNZkW9BvUDW57z2bMHLPO0iNVX1AYAzFu0EgFsw=='
	}

	return await fetch(url, {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			spSitePath: process.env.REACT_APP_SHAREPOINTURL + storage,
			libraryName: opportunityId,
			id: id,
		}),
	})
}
export const PDFRender = async (storage, opportunityId, id, ServerRelativeUrl, fileType) => {
	let supportedFileTypes = [
		'csv',
		'doc',
		'docx',
		'odp',
		'ods',
		'odt',
		'pot',
		'potm',
		'potx',
		'pps',
		'ppsx',
		'ppsxm',
		'ppt',
		'pptm',
		'pptx',
		'rtf',
		'xls',
		'xlsx',
	]
	if (supportedFileTypes.includes(fileType)) {
		if (process.env.REACT_APP_ENVIRONMENT === 'Local') {
			var url = 'https://salesdcm-dev-functionapp.azurewebsites.net/api/PDFRender?code=aNL2c1yIRXeba2OxRQCECGjHlXXsPGTystGmB7AWpNGjxFLm5nHkHA=='
		}
		if (process.env.REACT_APP_ENVIRONMENT === 'Development') {
			var url = 'https://salesdcm-dev-functionapp.azurewebsites.net/api/PDFRender?code=aNL2c1yIRXeba2OxRQCECGjHlXXsPGTystGmB7AWpNGjxFLm5nHkHA=='
		}
		if (process.env.REACT_APP_ENVIRONMENT === 'QA') {
			var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/PDFRender?code=vaBBX53DFc3eeBDMEk2v18e26gBaEYAfdcpuMqVNyThkAzFusSWksQ=='
		}
		if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
			var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/PDFRender?code=vaBBX53DFc3eeBDMEk2v18e26gBaEYAfdcpuMqVNyThkAzFusSWksQ=='
		}

		return await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				spSitePath: process.env.REACT_APP_SHAREPOINTURL + storage,
				libraryName: opportunityId,
				id: id,
				serverRelativeUrl: ServerRelativeUrl,
			}),
		})
	} else return false
}
export const MoveFile = async (storage, files) => {
	if (process.env.REACT_APP_ENVIRONMENT === 'Local') {
		var url = 'https://salesdcm-dev-functionapp.azurewebsites.net/api/MoveFiles?code=Jyqu7tl9XiKBSJspf2AbJWFY8EsRZmk88vQGX2KphVrnAzFuIvdF5g=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'Development') {
		var url = 'https://salesdcm-dev-functionapp.azurewebsites.net/api/MoveFiles?code=Jyqu7tl9XiKBSJspf2AbJWFY8EsRZmk88vQGX2KphVrnAzFuIvdF5g=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'QA') {
		var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/MoveFiles?code=E6TURLpjQg3AGak-hBkjpldGBeeVIOyKej1Kk6foKohqAzFuRt8bwA=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
		var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/MoveFiles?code=E6TURLpjQg3AGak-hBkjpldGBeeVIOyKej1Kk6foKohqAzFuRt8bwA=='
	}
	return await fetch(url, {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			spSitePath: process.env.REACT_APP_SHAREPOINTURL + storage,
			files: files,
		}),
	})
}
export const DocumentStatus = async (storage, opportunityId, id, status, latestRevision) => {
	if (process.env.REACT_APP_ENVIRONMENT === 'Local') {
		var url =
			'https://salesdcm-dev-functionapp.azurewebsites.net/api/SalesDCMDocumentStatus?code=B0x9by758ZINPKk251bMhpkkEfivuqJvUog4NZzPIloCpYPFlaTQPw=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'Development') {
		var url =
			'https://salesdcm-dev-functionapp.azurewebsites.net/api/SalesDCMDocumentStatus?code=B0x9by758ZINPKk251bMhpkkEfivuqJvUog4NZzPIloCpYPFlaTQPw=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'QA') {
		var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/SalesDCMDocumentStatus?code=mPDB8B9HHxB3a7CBPq3B6rpYjXCGcI-pVEPTKBSN5dLBAzFuRGcpMw=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
		var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/SalesDCMDocumentStatus?code=mPDB8B9HHxB3a7CBPq3B6rpYjXCGcI-pVEPTKBSN5dLBAzFuRGcpMw=='
	}
	return await fetch(url, {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			spSitePath: process.env.REACT_APP_SHAREPOINTURL + storage,
			libraryName: opportunityId,
			id: id,
			salesDcmDocumentStatus: status,
			salesDcmDocumentLatestRevision: latestRevision,
		}),
	})
}
export const PDFRenderCompleted = async (storage, opportunityId, id) => {
	if (process.env.REACT_APP_ENVIRONMENT === 'Local') {
		var url = 'https://salesdcm-dev-functionapp.azurewebsites.net/api/PDFRenderCompleted?code=52QOwLKtXzfiPq7tzZWy6hBXv-uAhLT1t5y9xe_fLPekAzFu8bEd8A=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'Development') {
		var url = 'https://salesdcm-dev-functionapp.azurewebsites.net/api/PDFRenderCompleted?code=52QOwLKtXzfiPq7tzZWy6hBXv-uAhLT1t5y9xe_fLPekAzFu8bEd8A=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'QA') {
		var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/PDFRenderCompleted?code=a6UkvM5dayqicVzIBK9_sigmLsoY757GfSwOp3JucLTiAzFupRRKvQ=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
		var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/PDFRenderCompleted?code=a6UkvM5dayqicVzIBK9_sigmLsoY757GfSwOp3JucLTiAzFupRRKvQ=='
	}
	return await fetch(url, {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			spSitePath: process.env.REACT_APP_SHAREPOINTURL + storage,
			libraryName: opportunityId,
			id: id,
		}),
	})
}
export const PDFRenderManual = async (storage, opportunityId, nativeId, renderId) => {
	if (process.env.REACT_APP_ENVIRONMENT === 'Local') {
		var url = 'https://salesdcm-dev-functionapp.azurewebsites.net/api/PDFRenderManual?code=jw1CWW_jeRkvPmTGLSd-Uhp_o7mjAdLzwGdX2dy-oRRkAzFuE82TDA=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'Development') {
		var url = 'https://salesdcm-dev-functionapp.azurewebsites.net/api/PDFRenderManual?code=jw1CWW_jeRkvPmTGLSd-Uhp_o7mjAdLzwGdX2dy-oRRkAzFuE82TDA=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'QA') {
		var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/PDFRenderManual?code=3dcsh8qDe5egxQKx7h9pSqqqCVc_HwmvcfeeP6BSeGDgAzFuS-z-aw=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
		var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/PDFRenderManual?code=3dcsh8qDe5egxQKx7h9pSqqqCVc_HwmvcfeeP6BSeGDgAzFuS-z-aw=='
	}
	return await fetch(url, {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			spSitePath: process.env.REACT_APP_SHAREPOINTURL + storage,
			libraryName: opportunityId,
			nativeId: nativeId,
			renderId: renderId,
		}),
	})
}
export const Export = async (storage, opportunityId, ServerRelativeUrl, transmittalId, email, SPID, message, created) => {
	if (process.env.REACT_APP_ENVIRONMENT === 'Local') {
		var url = 'https://salesdcm-dev-functionapp.azurewebsites.net/api/Export?code=c6-usDWySN3LR4BJ5WGRWyibGUmTIYmf77E2MHB8ZcAlAzFuD3LweA=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'Development') {
		var url = 'https://salesdcm-dev-functionapp.azurewebsites.net/api/Export?code=c6-usDWySN3LR4BJ5WGRWyibGUmTIYmf77E2MHB8ZcAlAzFuD3LweA=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'QA') {
		var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/Export?code=tto_nT8diP66kGW-4bGzxYZk8PiVsJ1rYyV0OPuOpEa0AzFupJ4uMQ=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
		var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/Export?code=tto_nT8diP66kGW-4bGzxYZk8PiVsJ1rYyV0OPuOpEa0AzFupJ4uMQ=='
	}
	return await fetch(url, {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
		},

		body: JSON.stringify({
			SpSitePath: process.env.REACT_APP_SHAREPOINTURL + storage,
			OpportunityName: opportunityId,
			SourceLibraryName: `${opportunityId}Transmittals`,
			SourcePath: ServerRelativeUrl,
			TargetListName: 'Transmittals',
			TargetItemId: SPID,
			Email: email,
			RootSite: process.env.REACT_APP_ROOTSITE,
			Transmittal: {
				Id: transmittalId,
				Message: message ?? '',
				Created: created,
			},
		}),
	})
}
export const RemoveFolder = async (storage, ServerRelativeUrl) => {
	if (process.env.REACT_APP_ENVIRONMENT === 'Local') {
		var url = 'https://salesdcm-dev-functionapp.azurewebsites.net/api/RemoveFolder?code=D4BR0Jk9Cu9USCBeQJ9juax-nvQUE-aXyjoJmNcNO7t3AzFuMJmB4w=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'Development') {
		var url = 'https://salesdcm-dev-functionapp.azurewebsites.net/api/RemoveFolder?code=D4BR0Jk9Cu9USCBeQJ9juax-nvQUE-aXyjoJmNcNO7t3AzFuMJmB4w=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'QA') {
		var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/RemoveFolder?code=SXfsksFBVIC4AWsJdaxrajSVxixltUBIb-NITPDX0zUkAzFuqft24w=='
	}
	if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
		var url = 'https://salesdcm-qa-functionapp.azurewebsites.net/api/RemoveFolder?code=SXfsksFBVIC4AWsJdaxrajSVxixltUBIb-NITPDX0zUkAzFuqft24w=='
	}
	return await fetch(url, {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			spSitePath: process.env.REACT_APP_SHAREPOINTURL + storage,
			siteRelativeUrl: ServerRelativeUrl,
		}),
	})
}
