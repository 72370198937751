import React, { useState, useEffect } from 'react'
import { getUserProfile, getSiteUers } from './utils/fetchUtils'
import App from './App'
import { useUsers } from './components/Utilities/UserContext/UserProvider'
const Auth = () => {
	const [auth, setAuth] = useState(false)
	const {setCurrentUser, setSiteUsers } = useUsers();
	const getAuth = async () => {
		setCurrentUser(await getUserProfile());
		setSiteUsers(await getSiteUers());
		setAuth(true)
	}
	useEffect(() => {
		getAuth()
	}, [])
	return auth ? <App/> : <div className='login-in-process'>Not authenticated</div>
}
export default Auth
