import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
const Breadcrumbs = () => {
	const [rootNode, setRootNdoe] = useState(null)
	const [selectedNode, setSelectedNode] = useState(null)
	const [toggle, setToggle] = useState(false)
	const [breadcrumbs, setBreadcrumbs] = useState([]);

	const nodes = useSelector((state) => state.tree.nodes)
		useEffect(() => {
		if (nodes.length) {
			let nodeRoot = nodes.find((node) => node.root === true)
			let nodeSelected = nodes.find((node) => node.selected === true)
			if (nodeRoot) {
				setRootNdoe(nodeRoot)
			}
			if (nodeSelected) {
				setSelectedNode(nodeSelected)
			}
		}
	}, [nodes]);

	useEffect(() => {
		let crumbs = [];
		const findCrumbs = (node) => {
			crumbs.push(node);
			// if (node.id === rootNode.id) {
			// 	crumbs.reverse();
			// 	setBreadcrumbs(crumbs);
			// 	return;
			// }
			const parent = nodes.find(n => n.id === node.parent);
			if (parent) {
				findCrumbs(parent);
			} else {
				crumbs.reverse();
				setBreadcrumbs(crumbs);
			}
		}
		if (selectedNode && nodes && rootNode) {
			findCrumbs(selectedNode);
		}
	}, [selectedNode, rootNode])


	return (
		<div className='app-topbar'>
			<div className='breadcrumbs'>
				{breadcrumbs.length
					? breadcrumbs.map((breadcrumb, index, row) => {
						return (
							<div key={index} className='breadcrumb'>
								<Link to={breadcrumb.link}>{breadcrumb.title}</Link> {index + 1 < row.length && <span style={{ paddingLeft: "5px" }}>/</span>}
							</div>
						)

					})
					: null}	
			</div>
			{/*<div className='toggle-transmittal-clipboard'>
				<span>Clipboard</span>
				<span onClick={() => setToggle((toggle) => !toggle)}>
					{toggle ? <FontAwesomeIcon icon={faToggleOn} /> : <FontAwesomeIcon className='fa-flip-horizontal' icon={faToggleOn} />}
				</span>
				<span>Transmittals</span>
					</div>*/}
		</div>
	)
}

export default Breadcrumbs
