import React, { useEffect, useState } from 'react'
import { useParams, Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchOpportunityById, setSelectedNode } from '../../../../../Tree/treeSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import OpportunityTabsMenu from './OpportunityTabsMenu/OpportunityTabsMenu'
import { useOpportunity } from '../../../.././../Utilities/TransmittalManager/TransmittalContext'
const Opportunity = (props) => {
	const [opportunity, setOpportunity] = useOpportunity()
	let params = useParams()
	const dispatch = useDispatch()
	const nodes = useSelector((state) => state.tree.nodes)
	const rootSet = useSelector((state) => state.tree.rootSet)
	useEffect(() => {
		if (rootSet) {
			setOpportunity({
				...nodes.find((node) => node.id === params.opportunityid),
			})
		}
	}, [nodes])
	useEffect(() => {
		if (rootSet) {
			dispatch(setSelectedNode(params.opportunityid))
		}
	}, [params])
	useEffect(() => {
		if (params) {
			if (!rootSet) {
				if (nodes.length) {
					dispatch(fetchOpportunityById(params.opportunityid))
				}
			}
		}
	}, [params, rootSet, nodes])
	return (
		<div>
			<div className='subpage-heading-document'>
				{opportunity ? (
					<h1>
						<FontAwesomeIcon icon={faBriefcase} />
						<span>{opportunity.id}</span>
					</h1>
				) : null}
				<OpportunityTabsMenu />
			</div>
			<div className='page'>
				<Outlet context={{ opportunity: opportunity }} />
			</div>
		</div>
	)
}
export default Opportunity

/*

{opportunity && document ? <Outlet context={{ opportunity: opportunity, document: document }} /> : null}


	<div className='page'>
			<h1>Opportunity component</h1>
			<h2>id: {opportunity ? opportunity.id : null}</h2>
			<h2>Transmittals</h2>

		</div>

import React, { useEffect, useState } from 'react'
import { useParams,  Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchFoldersByPath, setSelectedNode } from '../Tree/treeSlice'
import { getDocumentByIdAndRevision } from '../../utils/fetchUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import DocumentTabsMenu from './Components/DocumentTabsMenu'
const Document = (props) => {
	let [opportunity, setOpportunity] = useState(null)
	let [document, setDocument] = useState(null)
	let params = useParams()
	const dispatch = useDispatch()
	const nodes = useSelector((state) => state.tree.nodes)
	const rootSet = useSelector((state) => state.tree.rootSet)
	useEffect(() => {
		if (rootSet) {
			setOpportunity({
				...nodes.find((node) => node.id === params.opportunityid),
			})
		}
	}, [nodes])
	useEffect(() => {
		const fetchDocument = async () => {
			let Document = await getDocumentByIdAndRevision(params.docid, Number(params.rev))
			setDocument(Document)
			let node = nodes.find((node) => node.opportunityId === params.opportunityid && node.path === Document.path)
			if (rootSet && node) {
				dispatch(setSelectedNode(node.id))
			}
		}
		fetchDocument()
	}, [params])
	useEffect(() => {
		if (!rootSet) {
			if (nodes.length) {
				if (document) {
					dispatch(
						fetchFoldersByPath({
							opportunityid: params.opportunityid,
							SelectedFolderPath: document.path,
						})
					)
				}
			}
		}
	}, [params, rootSet, nodes, document])
	return (
		<div>
			<div className='subpage-heading-document'>
				{document ? (
					<h1>
						<FontAwesomeIcon icon={faFile} />
						<span>{document.title}</span>
					</h1>
				) : null}
				<DocumentTabsMenu />
			</div>
			{opportunity && document ? <Outlet context={{ opportunity: opportunity, document: document }} />: null}
			
		</div>
	)
}

export default Document
 */
