import React, { } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { GetFormattedDate } from '../../../../../utils/appUtils'
function Reply(props) {
	return (
		<div className='comment reply'>
			<div>
				<div>
					<span>
						<FontAwesomeIcon icon={faUserCircle} />
					</span>
					<span>{props.comment.Author.Title}</span>
					<span>ID: {props.comment.ID}</span>
					<span>{GetFormattedDate(props.comment.Created)}</span>
				</div>
			</div>
			<div>
				<div>{props.comment.Comment}</div>
			</div>
		</div>
	)
}

export default Reply
