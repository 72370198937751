import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { GetFormattedBytes, GetFileIcon } from '../../../../utils/appUtils'
import Metadata from '../../../Document/Metadata/Components/Metadata'
import { useModal } from '../../../Utilities/Modal/ModalContext'
const UploadFileList = ({ fileList, dispatch, uploading }) => {
	const [modal, setModal] = useModal()
	const removeFile = (index) => {
		dispatch({ type: 'REMOVE_FILE_FROM_LIST', fileIndex: index })
	}
	const ModalContent = ({ index }) => {
		const changeDocumentClass = (value) => {
			dispatch({ type: 'CHANGE_METADATA_ON_FILE', fileIndex: index, field: 'class', value: value ? value.label : value })
		}
		const changeDocumentArchivalCode = (value) => {
			dispatch({ type: 'CHANGE_METADATA_ON_FILE', fileIndex: index, field: 'archivalCode', value: value ? value.label : value })
		}
		const changeDocumentInformationClass = (value) => {
			dispatch({ type: 'CHANGE_METADATA_ON_FILE', fileIndex: index, field: 'informationClass', value: value ? value.label : value })
		}
		const changeDocumentApplicationArea = (value) => {
			dispatch({ type: 'CHANGE_METADATA_ON_FILE', fileIndex: index, field: 'applicationArea', value: value ? value.label : value })
		}
		const changeDocumentKnowledgeArea = (value) => {
			dispatch({ type: 'CHANGE_METADATA_ON_FILE', fileIndex: index, field: 'knowledgeArea', value: value ? value.label : value })
		}
		return (
			<div className='page'>
				<Metadata
					mode={'edit'}
					document={fileList[index].metadata}
					setValues={{
						DocumentClass: changeDocumentClass,
						ArchivalCode: changeDocumentArchivalCode,
						InformationClass: changeDocumentInformationClass,
						ApplicationArea: changeDocumentApplicationArea,
						KnowledgeArea: changeDocumentKnowledgeArea,
					}}
				/>
				<button className='btn btn-blue mt-25' onClick={() => setModal(null)}>
					Save
				</button>
			</div>
		)
	}
	const handleOpenMetadataModal = (index) => {
		setModal(<ModalContent index={index} />)
	}
	return (
		<div>
			{fileList.length
				? fileList.map((file, index) => {
						return (
							<div key={index} className='file-to-Upload'>
								<div className='file-to-Upload-col-2'>
									<div>
										<FontAwesomeIcon icon={GetFileIcon(file.metadata.fileExtension)} />
									</div>
									<div>{GetFormattedBytes(file.size)}</div>
								</div>
								<div className='file-to-Upload-col-3'>
									{uploading ? (
										<div className='progress-bar'>
											<div className='progress-bar-value'></div>
										</div>
									) : (
										<div>
											<span onClick={() => handleOpenMetadataModal(index)}>
												<FontAwesomeIcon icon={faFilePen} />
											</span>
											<input
												onChange={(e) =>
													dispatch({
														type: 'CHANGE_METADATA_ON_FILE',
														fileIndex: index,
														field: 'title',
														value: e.target.value,
													})
												}
												className='input'
												type='text'
												value={file.metadata.title}
											/>
											<span onClick={() => removeFile(index)}>
												<FontAwesomeIcon icon={faTrash} />
											</span>
										</div>
									)}
								</div>
							</div>
						)
				  })
				: null}
		</div>
	)
}
/*
	
*/
export default UploadFileList
