import React, { useEffect, useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'
import './DropdownBrowseFilter.css'

const DropdownBrowseFilter = (props) => {
	let [options, setOptions] = useState()
	let [toggleOptions, setToggleOptions] = useState(false)
	const wrapperRef = useRef(null)
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setToggleOptions(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [wrapperRef])
	const handleOptionSelected = (option) => {
		props.setValue(option)
	}
	const handleFilterReset = () => {
		props.resetValue()
	}

	useEffect(() => {
		const options = props.options.filter(opt => opt?.title?.length > 0)
		setOptions(options)
	}, [props])

	return (
		<div className={options?.length > 0 ? 'DropdownBrowseFilter' : "DropdownBrowseFilter-disabled"} >
			{
				props.selected ? (
					<div className='DropdownBrowseFilter-icon-reset' onClick={() => handleFilterReset()}>
						<span>
							<FontAwesomeIcon icon={faBan} />
						</span>
						<span>{props.label}</span>
					</div>
				) : (
					<div className='DropdownBrowseFilter-icon'>
						<span>
							<FontAwesomeIcon icon={props.icon} />
						</span>
						<span>{props.label}</span>
					</div>
				)
			}
			< div ref={wrapperRef} onClick={() => setToggleOptions((toggleOptions) => !toggleOptions)}>
				<div className={options?.length > 0 ? 'DropdownBrowseFilter-select' : 'DropdownBrowseFilter-select-disabled'}>
					<span>{props.selected ? (props.selected.length > 20 ? props.selected.substring(0, 20) + '...' : props.selected) : 'Select.. '}</span>
					<span>
						<FontAwesomeIcon icon={faChevronCircleDown} />
					</span>
				</div>
				{toggleOptions && options?.length > 0 ? (
					<div className='DropdownBrowseFilter-options'>
						{options.map((option, index) => (
							<div key={index} onClick={() => handleOptionSelected(option)} className='DropdownBrowseFilter-option'>
								{option.title}
							</div>
						))}
					</div>
				) : null}
			</div>
		</div>
	)
}
export default DropdownBrowseFilter
