import React, { useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getCountry } from '../../../../../utils/fetchUtils'
import { countryLanding, setSelectedNode, fetchOpportunitiesForCountry } from '../../../../Tree/treeSlice'

const Country = (props) => {
	let [countryId, setCountryId] = useState(null)
	let [country, setCountry] = useState(null)
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [items, setItems] = useState([]);
	const [listlanguages,setListlanguages]=useState(null);
	const [listcurrencies,setListcurrencies]=useState(null);
	const [listborders,setListborders]=useState(null);

	let params = useParams()
	const dispatch = useDispatch()
	const nodes = useSelector((state) => state.tree.nodes)
	const rootSet = useSelector((state) => state.tree.rootSet)
	useEffect(() => {
		if (nodes.length) {
			setCountry({
				...nodes.find((node) => node.type === 'country' && node.title === params.country),
			})
		}

	}, [params])
	useEffect(() => {
		if (country) {
			dispatch(setSelectedNode(country.id))
			const getData = async () => {
				let CountryData = await getCountry(country.SPID)
				console.log('CountryCodeAlpha2', CountryData.CountryCodeAlpha2)

				fetch("https://restcountries.com/v2/alpha/"+CountryData.CountryCodeAlpha2)
				.then(res => res.json())
				.then(
					(result) => {
					setIsLoaded(true);
					setItems(result);
					setListlanguages(result.languages.map((i,index) => <li key={index}>{i.name} - {i.nativeName}</li>));
					setListcurrencies(result.currencies.map((i,index) => <li key={index}>{i.name} ({i.symbol})</li>));
					if("borders" in result){
						setListborders(result.borders.map((i,index ) => <span key={index}>{i} </span>));
					}
					else{
						setListborders(false);
					}
					console.log(items)
					},
					(error) => {
					setIsLoaded(true);
					setError(error);
					}
				)
			}
			console.log("country.id", country)
			getData()
		}
	}, [country])

	useEffect(() => {
		if (params) {
			if (!rootSet) {
				if (nodes.length) {
					let CountryNode = {
						...nodes.find((node) => node.type === 'country' && node.title === params.country),
					}
					setCountry(CountryNode)
					dispatch(fetchOpportunitiesForCountry(CountryNode.SPID))
					dispatch(countryLanding(params.country))
				}
			}
		}
	}, [params, rootSet, nodes])

	const imgstyle = {
		position: 'fixed',
 		left: 50+'%',
		zindex: -1,
	}

	return (
		<div>
			<img src={items.flag} alt="Logo" width="20%" style={imgstyle}/>
			<h1>Country component</h1>
			<h2>id: {country ? country.id : null}</h2>
			{items.name ? <h2>Name:  {items.name} - {items.nativeName ? items.nativeName : null}</h2>: null}
			{items.capital ? <h2>Capital:  {items.capital }</h2>: null}
			{listlanguages ? <h2>Languages: {listlanguages} </h2>: null}
			{listcurrencies ? <h2>Currencies: {listcurrencies} </h2>: null}
			{items.gini ? <h2>Gini: {items.gini} </h2>: null}
			{items.area ? <h2>Area: {items.area} km²/ {(items.area*Math.pow(0.62137119, 2)).toFixed(0)} sq mi</h2>: null}
			{listborders ? <h2>Borders: {listborders} </h2>: null}
			{items.population ? <h2>Population: {items.population}</h2>: null}
			{items.timezones ? <h2>Timezones: {items.timezones} </h2>: null}
			{items.callingCodes ? <h2>CallingCodes: {items.callingCodes} </h2>: null}
			{items.independent ?<h2>Independent: Yes</h2>:<h2>Independent: No</h2>}
			{items.topLevelDomain ? <h2>TopLevelDomain: {items.topLevelDomain} </h2>: null}
		</div>
	)
}

export default Country
