import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faCheck, faPen, faXmark } from '@fortawesome/free-solid-svg-icons'
import './InplaceEditor.css'

const InplaceEditor = ({ handleConfirm, text, column, type }) => {
	const [toggleEdit, setToggleEdit] = useState(true)
	const [loading, setLoading] = useState(false)
	const wrapperRef = useRef(null)
	const input = useRef(null)
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				if (type === 'input' && text === input.current.value) {
					setToggleEdit(true)
				}
				if (type === 'textarea' && text === input.current.textContent) {
					setToggleEdit(true)
				}
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [wrapperRef])
	const confirmEdit = async () => {
		setLoading(true)
		let data = {
			[column]: type === 'input' ? input.current.value : input.current.textContent,
		}
		await handleConfirm(data)
		setLoading(false)
		setToggleEdit(true)
	}
	const Input = () => {
		return (
			<div>
				<input ref={input} className='input' defaultValue={text}></input>
			</div>
		)
	}
	const Textarea = () => {
		return (
			<div>
				<div ref={input} className='textarea' contentEditable='true'>
					{text}
				</div>
			</div>
		)
	}
	const ButtonLoading = () => {
		return (
			<div className='inplace-editor-icon'>
				<FontAwesomeIcon className='fa-spin' icon={faSpinner} />
			</div>
		)
	}
	const ButtonConfirm = () => {
		return (
			<div className='inplace-editor-icon' onClick={() => confirmEdit()}>
				<FontAwesomeIcon icon={faCheck} />
			</div>
		)
	}
	const ButtonCancel = () => {
		return (
			<div className='inplace-editor-icon' onClick={() => setToggleEdit(true)}>
				<FontAwesomeIcon icon={faXmark} />
			</div>
		)
	}
	return (
		<div className='inplace-editor'>
			{toggleEdit ? (
				<div>
					<div style={{ paddingTop: 5, paddingBottom: 5 }}>
						{text}
						<span className='inplace-editor-icon' onClick={() => setToggleEdit(false)}>
							<FontAwesomeIcon icon={faPen} />
						</span>
					</div>
				</div>
			) : (
				<div ref={wrapperRef}>
					{type === 'input' ? <Input /> : null}
					{type === 'textarea' ? <Textarea /> : null}
					<div className='inplace-editor-buttons'>
						{loading ? <ButtonLoading /> : <ButtonConfirm />}
						{loading ? null : <ButtonCancel />}
					</div>
				</div>
			)}
		</div>
	)
}

export default InplaceEditor
