import { useParams } from 'react-router-dom'
import TabsMenu from '../../../../../../Utilities/TabsMenu/TabMenu'

const OpportunityTabsMenu = () => {
	let params = useParams()
	let opportunity = params.opportunityid
	let tabs = [
		{
			link: `/opportunity/${opportunity}`,
			active: true,
			label: 'Metadata',
			enabled: true,
		},
	]
	return <TabsMenu tabs={tabs} />
}
export default OpportunityTabsMenu
