import * as moment from 'moment'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getDocumentsByPath } from '../../../../utils/fetchUtils'
export const fetchDocuments = createAsyncThunk('getDocuments', async (data, { dispatch }) => {
	return getDocumentsByPath(data.SelectedFolderPath, data.opportunityid, data.storage, data.ServerRelativeUrl)
})
export const browseSlice = createSlice({
	name: 'browse',
	initialState: {
		status: 'loading',
		loading: false,
		documents: [],
		filterStatus: {
			selected: null,
			options: [],
		},
		filterCreatedBy: {
			selected: null,
			options: [],
		},
		filterModifiedBy: {
			selected: null,
			options: [],
		},
		filterClass: {
			selected: null,
			options: [],
		},
	},
	reducers: {
		selectDocument: (state, action) => {
			state.documents[state.documents.findIndex((document) => document.documentid === action.payload)].selected = true
		},
		deselectDocument: (state, action) => {
			state.documents[state.documents.findIndex((document) => document.documentid === action.payload)].selected = false
		},
		selectAllDocuments: (state, action) => {
			state.documents = state.documents.map((document) => {
				return {
					...document,
					selected: true,
				}
			})
		},
		deselectAllDocuments: (state, action) => {
			state.documents = state.documents.map((document) => {
				return {
					...document,
					selected: false,
				}
			})
		},
		sortDocuments: (state, action) => {
			const column = action.payload.columnToSort;
			switch (column) {
				case "modified":
				case "created":
					if (action.payload.order) {
						state.documents = state.documents
							.sort((a, b) => (moment(b[column], "DD/MM/YYYY hh:mm").toDate().getTime() > moment(a[column], "DD/MM/YYYY hh:mm").toDate().getTime() ? -1 : 1))
							.sort((a, b) => Number(a.filtered) - Number(b.filtered))

					} else {
						state.documents = state.documents
							.sort((a, b) => (moment(a[column], "DD/MM/YYYY hh:mm").toDate().getTime() > moment(b[column], "DD/MM/YYYY hh:mm").toDate().getTime() ? -1 : 1))
							.sort((a, b) => Number(a.filtered) - Number(b.filtered))
					}
					break;
				default:
					if (action.payload.order) {
						state.documents = state.documents
							.sort((a, b) => (b[column] < a[column] ? -1 : 1))
							.sort((a, b) => Number(a.filtered) - Number(b.filtered))

					} else {
						state.documents = state.documents
							.sort((a, b) => (a[column] < b[column] ? -1 : 1))
							.sort((a, b) => Number(a.filtered) - Number(b.filtered))
					}
					break;
			}

		},
		filterDocuments: (state) => {
			const AllFiltersInactive = () => {
				if (
					state.filterStatus.selected === null &&
					state.filterCreatedBy.selected === null &&
					state.filterModifiedBy.selected === null &&
					state.filterClass.selected === null
				) {
					return true
				} else {
					return false
				}
			}
			const SomeFiltersActive = (document) => {
				const IsPropertyFiltered = (property, value) => {
					let PropertyFiltered = false
					if (state.filterStatus.selected) {
						if (property === 'status' && value !== state.filterStatus.selected) {
							PropertyFiltered = true
						}
					}
					if (state.filterCreatedBy.selected) {
						if (property === 'createdBy' && value !== state.filterCreatedBy.selected) {
							PropertyFiltered = true
						}
					}
					if (state.filterModifiedBy.selected) {
						if (property === 'modifiedBy' && value !== state.filterModifiedBy.selected) {
							PropertyFiltered = true
						}
					}
					if (state.filterClass.selected) {
						if (property === 'class' && value !== state.filterClass.selected) {
							PropertyFiltered = true
						}
					}
					return PropertyFiltered
				}

				let IsStatusFiltered = IsPropertyFiltered('status', document.status)
				let IsCreatedByFiltered = IsPropertyFiltered('createdBy', document.createdBy)
				let IsModifiedByFiltered = IsPropertyFiltered('modifiedBy', document.modifiedBy)
				let IsClassFiltered = IsPropertyFiltered('class', document.class)
				if (IsStatusFiltered || IsCreatedByFiltered || IsModifiedByFiltered || IsClassFiltered) {
					return false
				} else {
					return true
				}
			}
			state.documents = state.documents
				.map((document) => {
					if (AllFiltersInactive()) {
						return {
							...document,
							filtered: false,
						}
					} else if (SomeFiltersActive(document)) {
						return {
							...document,
							filtered: false,
						}
					} else {
						return {
							...document,
							filtered: true,
						}
					}
				})
				.sort((a, b) => Number(a.filtered) - Number(b.filtered))
			state.filterStatus.options = [
				...new Set(
					state.documents
						.filter((document) => document.filtered === false)
						.map((document) => document.status)
						.sort()
				),
			]
			state.filterCreatedBy.options = [
				...new Set(
					state.documents
						.filter((document) => document.filtered === false)
						.map((document) => document.createdBy)
						.sort()
				),
			]
			state.filterModifiedBy.options = [
				...new Set(
					state.documents
						.filter((document) => document.filtered === false)
						.map((document) => document.modifiedBy)
						.sort()
				),
			]
			state.filterClass.options = [
				...new Set(
					state.documents
						.filter((document) => document.filtered === false)
						.map((document) => document.class)
						.sort()
				),
			]
		},
		setSelectedStatusFilter: (state, action) => {
			state.filterStatus.selected = action.payload
			browseSlice.caseReducers.filterDocuments(state)
		},
		setSelectedCreatedByFilter: (state, action) => {
			state.filterCreatedBy.selected = action.payload
			browseSlice.caseReducers.filterDocuments(state)
		},
		setSelectedModifiedByFilter: (state, action) => {
			state.filterModifiedBy.selected = action.payload
			browseSlice.caseReducers.filterDocuments(state)
		},
		setSelectedClassFilter: (state, action) => {
			state.filterClass.selected = action.payload
			browseSlice.caseReducers.filterDocuments(state)
		},
		resetStatusFilter: (state) => {
			state.filterStatus.selected = null
			browseSlice.caseReducers.filterDocuments(state)
		},
		resetCreatedByFilter: (state) => {
			state.filterCreatedBy.selected = null
			browseSlice.caseReducers.filterDocuments(state)
		},
		resetModifiedByFilter: (state) => {
			state.filterModifiedBy.selected = null
			browseSlice.caseReducers.filterDocuments(state)
		},
		resetClassFilter: (state) => {
			state.filterClass.selected = null
			browseSlice.caseReducers.filterDocuments(state)
		},
	},
	extraReducers: {
		[fetchDocuments.pending]: (state, action) => {
			state.status = 'loading'
		},
		[fetchDocuments.fulfilled]: (state, action) => {
			state.status = 'success'
			state.filterStatus.options = [...new Set(action.payload.map((document) => document.status).sort())]
			state.filterCreatedBy.options = [...new Set(action.payload.map((document) => document.createdBy).sort())]
			state.filterModifiedBy.options = [...new Set(action.payload.map((document) => document.modifiedBy).sort())]
			state.filterClass.options = [...new Set(action.payload.map((document) => document.class).sort())]

			action.payload.forEach(doc => {
				if (doc.class === null) {
					doc.class = ""
				}
			})

			state.documents = action.payload

			browseSlice.caseReducers.filterDocuments(state)
		},
		[fetchDocuments.rejected]: (state, action) => {
			state.status = 'failed'
		},
	},
})

export const {
	setSelectedStatusFilter,
	setSelectedCreatedByFilter,
	setSelectedModifiedByFilter,
	setSelectedClassFilter,
	resetStatusFilter,
	resetCreatedByFilter,
	resetModifiedByFilter,
	resetClassFilter,
	selectDocument,
	deselectDocument,
	selectAllDocuments,
	deselectAllDocuments,
	sortDocuments
} = browseSlice.actions

export default browseSlice.reducer
