import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import './Modal.css'
import { useModal } from '../Modal/ModalContext'
export default function Modal() {
	const [modal, setModal] = useModal()
	//modal-frame-minimal
	return modal ? (
		<div className='modal-background'>
			<div className={`modal-frame modal-frame-normal`}>
				<div className='modal-close' onClick={() => setModal(null)}>
					<span>Close</span> <FontAwesomeIcon icon={faTimes} />
				</div>
				{modal && modal}
			</div>
		</div>
	) : null
}
