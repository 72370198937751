import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useReducer, useState } from 'react'
import { faFileArrowUp, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import DragAndDrop from '../../../Folder/Browse/Components/DragAndDrop'
import { ReplaceNativeFile } from '../../../../utils/fetchUtils'
import { useSendMessage } from '../../../Utilities/MessageManager/MessageContext'
import { useModal } from '../../../Utilities/Modal/ModalContext'

const UploadNative = ({ document }) => {
	const [loading, setLoading] = useState(false)
	const SendMessage = useSendMessage()
	const [modal, setModal] = useModal()
	let navigate = useNavigate()
	const reducer = (state, action) => {
		switch (action.type) {
			case 'SET_DROP_DEPTH':
				return { ...state, dropDepth: action.dropDepth }
			case 'SET_IN_DROP_ZONE':
				return { ...state, inDropZone: action.inDropZone }
			case 'ADD_FILE_TO_LIST':
				let files = [action.files[0]]
				const existingFiles = state.fileList.map((f) => f.name)
				files = files.filter((f) => !existingFiles.includes(f.name))
				for (const [key, value] of Object.entries(files)) {
					files[key].metadata = {
						fileExtension: value.name.split('.').pop(),
						documentid: document.documentid,
						title: document.title,
						class: document.class,
						informationClass: document.informationClass,
						archivalCode: document.archivalCode,
						applicationArea: document.applicationArea,
						knowledgeArea: document.knowledgeArea,
						revision: document.revision,
					}
				}
				return { ...state, fileList: state.fileList.concat(files) }
			case 'REMOVE_FILE_FROM_LIST':
				return { ...state, fileList: state.fileList.filter((_, index) => index !== action.fileIndex) }
			case 'CHANGE_METADATA_ON_FILE':
				state.fileList[action.fileIndex].metadata[action.field] = action.value
				return { ...state, fileList: state.fileList }
			default:
				return state
		}
	}
	const handleRemoveFile = (index) => {
		dispatch({ type: 'REMOVE_FILE_FROM_LIST', fileIndex: index })
	}
	const handleUploadFile = async () => {
		if (data.fileList[0].metadata.fileExtension === document.fileType) {
			setLoading(true)
			await ReplaceNativeFile(document.storage, document.ServerRelativeUrl, data.fileList)
			SendMessage('File successfully replaced!', 'success')
			setLoading(false)
			setModal(null)
			navigate(`/opportunity/${document.opportunityId}/document/view/${document.documentid}/${document.revision}`, { replace: true })
		} else {
			SendMessage('File type mismatch!', 'error')
		}
	}
	const [data, dispatch] = useReducer(reducer, { dropDepth: 0, inDropZone: false, fileList: [] })
	const DocumentList = () => {
		return (
			<div>
				<div className='table'>
					<div className='table-row upload-pdf-render-table'>
						<div>{data.fileList[0].name}</div>
						<div onClick={() => handleRemoveFile(0)}>
							<FontAwesomeIcon icon={faTrash} />
						</div>
					</div>
				</div>
				<div className='mt-25'>
					<button disabled={loading} onClick={() => handleUploadFile()} className='btn btn-blue'>
						{loading ? (
							<span>
								<FontAwesomeIcon className='fa-spin ' icon={faSpinner} /> Uploading..
							</span>
						) : (
							<>
								<span>Upload {document.fileType.toUpperCase()}</span>
								<span>
									<FontAwesomeIcon icon={faFileArrowUp} />
								</span>
							</>
						)}
					</button>
				</div>
			</div>
		)
	}
	return data.fileList.length ? <DocumentList /> : <DragAndDrop dispatch={dispatch} data={data} fileTypes={[document.fileType]} multiple={false} />
}
export default UploadNative
