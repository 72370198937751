import React, { useState, useContext } from 'react'
const MessageContext = React.createContext()
const SendMessageContext = React.createContext()

export const useMessage = () => {
	return useContext(MessageContext)
}
export const useSendMessage = () => {
	return useContext(SendMessageContext)
}
export const MessageProvider = ({ children }) => {
	const [message, setMessage] = useState()

	const SendMessage = (message, type) => {
        if(message){
            setMessage({ message: message, type: type })
        }else{
            setMessage(null)
        }
	}
	return (
		<MessageContext.Provider value={message}>
			<SendMessageContext.Provider value={SendMessage}>
                {children}
            </SendMessageContext.Provider>
		</MessageContext.Provider>
	)
}
