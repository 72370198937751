import React, { useState, useContext, createContext } from 'react'

const UserContext = createContext();

export const useUsers = () => {
    return useContext(UserContext)
}
export function UserProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [siteUsers, setSiteUsers] = useState();

    return (
        <UserContext.Provider value={{ currentUser, siteUsers, setCurrentUser, setSiteUsers }}>
            {children }
        </UserContext.Provider>
    )
}
