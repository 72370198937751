import { useState, useEffect } from 'react';
import DropdownBrowseFilter from '../../../Utilities/Dropdown/DropdownBrowseFilter'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

function TransmittalsFilter({ transmittals, setFilteredTransmittals }) {
    const [filterSelected_Status, setFilterSelected_Status] = useState();
    const [filterSelected_CreatedBy, setFilterSelected_CreatedBy] = useState();

    const [filterOptions_CreatedBy, setfilterOptions_CreatedBy] = useState([]);
    const [filterOptions_Status, setFilterOptions_Status] = useState([]);

    useEffect(() => {
        let filtered = [...transmittals];
        if (filterSelected_Status) {
            filtered = filtered.filter(t => t.status === filterSelected_Status);
        }
        if (filterSelected_CreatedBy) {
            filtered = filtered.filter(t => t.createdBy === filterSelected_CreatedBy);
        }
        setFilteredTransmittals(filtered);

    }, [filterSelected_Status, filterSelected_CreatedBy])

    useEffect(() => {
        setfilterOptions_CreatedBy([...new Set(transmittals.map(item => item.createdBy))]);
        setFilterOptions_Status([...new Set(transmittals.map(item => item.status))]);
    }, [transmittals])


    return (
        <div className='document-filter'>
            <DropdownBrowseFilter
                setValue={(option) => {
                    setFilterSelected_Status(option.title);
                }}
                resetValue={() => {
                    setFilterSelected_Status();
                }}
                label={'Status'}
                selected={filterSelected_Status}
                options={filterOptions_Status.map(title => {
                    return {
                        title: title,
                        data: null
                    }
                })}
                icon={faFilter}
            />
            <DropdownBrowseFilter
                setValue={(option) => {
                    setFilterSelected_CreatedBy(option.title)
                }}
                resetValue={() => {
                    setFilterSelected_CreatedBy()
                }}
                label={'Created by'}
                selected={filterSelected_CreatedBy}
                options={filterOptions_CreatedBy.map(title => {
                    return {
                        title: title,
                        data: null
                    }
                })}
                icon={faFilter}
            />

        </div>
    )
}
export default TransmittalsFilter
