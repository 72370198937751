import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faChevronRight,
	faChevronDown,
	faCircleChevronUp,
	faToggleOff,
	faToggleOn,
	faSpinner,
	faBriefcase,
	faDatabase,
	faClipboardList,
} from '@fortawesome/free-solid-svg-icons'
import { faFolder, faFolderOpen } from '@fortawesome/free-regular-svg-icons'
import {
	expandNode,
	collapseNode,
	loadParentNodes,
	fetchInitialStateNodes,
	fetchOpportunitiesForCountry,
	fetchOpportunityRootFolders,
	fetchTransmittalRootFolders,
	fetchSubfoldersByPath,
} from './treeSlice'
import { getUserProfile, getSiteUers } from '../../utils/fetchUtils';
import { useUsers } from "../../components/Utilities/UserContext/UserProvider";
const Tree = () => {
	const [toggleTree, setToggleTree] = useState(true)
	const nodes = useSelector((state) => state.tree.nodes)
	const loading = useSelector((state) => state.tree.loading)
	const rootFetched = useSelector((state) => state.tree.rootFetched)
	const { setCurrentUser, setSiteUsers } = useUsers();
	const dispatch = useDispatch()
	const getSiteUsers = async () => {
		setCurrentUser(await getUserProfile());
		setSiteUsers(await getSiteUers());

	}
	useEffect(() => {
		if (!rootFetched) {
			dispatch(fetchInitialStateNodes())
			getSiteUsers()
		}
	}, [dispatch, rootFetched])
	const fetchNodes = (node) => {
		if (node.type === 'country') {
			dispatch(fetchOpportunitiesForCountry(node.SPID))
		}
		if (node.type === 'opportunity') {
			dispatch(fetchOpportunityRootFolders({ opportunityid: node.id, storage: node.storage }))
		}
		if (node.type === 'folder') {
			dispatch(fetchSubfoldersByPath({ parentPath: node.path, opportunityId: node.opportunityId, storage: node.storage, parentId: node.id }))
		}
		if (node.type === 'transmittalRoot') {
			dispatch(fetchTransmittalRootFolders({ opportunityid: node.opportunityId, storage: node.storage, parentId: node.id }))
		}
	}
	const toggleNode = async (node) => {
		if (node.expanded) {
			dispatch(collapseNode(node.id))
		} else {
			if (node.childrenLoaded) {
				dispatch(expandNode(node.id))
			} else {
				fetchNodes(node)
			}
		}
	}
	const selectNode = (node) => {
		if (node.childrenLoaded) {
			dispatch(expandNode(node.id))
		} else {
			fetchNodes(node)
		}
	}
	const loadParent = async () => {
		dispatch(loadParentNodes())
	}
	const Parent = (props) => {
		return (
			<ul>
				<Child node={props.node} />
			</ul>
		)
	}
	const TreeIcon = ({ node }) => {
		if (node.loading) {
			return <FontAwesomeIcon className='fa-spin' icon={faSpinner} />
		}
		if (node.type === 'opportunity') {
			return <FontAwesomeIcon icon={faBriefcase} />
		}
		if (node.type === 'transmittalRoot') {
			return <FontAwesomeIcon icon={faDatabase} />
		}
		if (node.type === 'transmittal') {
			return <FontAwesomeIcon icon={faClipboardList} />
		}
		if (node.expanded) {
			return <FontAwesomeIcon icon={faFolderOpen} />
		} else {
			return <FontAwesomeIcon icon={faFolder} />
		}
	}
	const Child = (props) => {
		return (
			<li>
				<div className={props.node.selected ? 'node-selected' : ''}>
					<span onClick={() => toggleNode(props.node)}>
						{props.node.canExpand &&
							(props.node.expanded ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />)}
					</span>
					<span>
						<TreeIcon node={props.node} />
					</span>
					<Link onClick={() => selectNode(props.node)} to={props.node.link}>
						{props.node.selected ? <b>{props.node.title}</b> : props.node.title}
					</Link>
				</div>
				{props.node.expanded ? nodes.filter((node) => node.parent === props.node.id).map((node) => <Parent key={node.id} node={node} />) : null}
			</li>
		)
	}
	return (
		<div className='sidebar'>
			<div className='toggle-tree' onClick={() => setToggleTree((toggleTree) => !toggleTree)}>
				<span>{toggleTree ? <FontAwesomeIcon icon={faToggleOn} /> : <FontAwesomeIcon icon={faToggleOff} />}</span>
				<span>Structure</span>
			</div>
			{toggleTree ? (
				<div>
					{nodes.some((node) => node.parent === '#' && node.root) ? null : (
						<span onClick={() => loadParent()}>
							<FontAwesomeIcon icon={faCircleChevronUp} /> Load parent
						</span>
					)}
					{loading ? (
						<div>loading..</div>
					) : (
						<div className='sidebar-structure'>
							{nodes
								.filter((node) => node.root === true)
								.map((node) => {
									return <Parent key={node.id} node={node} />
								})}
						</div>
					)}
				</div>
			) : null}
		</div>
	)
}
export default Tree
