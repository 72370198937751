import { useRef, useState } from 'react'
import { CreateNewTransmittal } from '../../../utils/fetchUtils'
import { useNavigate } from 'react-router-dom'
import { ObjectHasEmptyValues } from '../../../utils/appUtils'
import { useSendMessage } from '../../Utilities/MessageManager/MessageContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { useOpportunity } from '../../Utilities/TransmittalManager/TransmittalContext'
import { addNode } from '../../Tree/treeSlice'



const CreateTransmittal = (props) => {
	const [loading, setLoading] = useState(false)
	const [opportunity] = useOpportunity()
	const SendMessage = useSendMessage()
	const dispatch = useDispatch();
	let navigate = useNavigate()
	let transmittalNameInput = useRef(null)
	let transmittalDescriptionInput = useRef(null)
	let transmittalRecipientInput = useRef(null)

	const handleCreateTransmittal = async () => {
		setLoading(true)
		let item = {
			name: {
				required: true,
				value: transmittalNameInput.current.value === '' ? null : transmittalNameInput.current.value,
			},
			description: {
				required: true,
				value: transmittalDescriptionInput.current.textContent === '' ? null : transmittalDescriptionInput.current.textContent,
			},
			recipient: {
				required: false,
				value: transmittalRecipientInput.current.value === '' ? null : transmittalRecipientInput.current.value,
			},
		}
		if (ObjectHasEmptyValues(item)) {
			SendMessage('Required fields missing!', 'alert')
			setLoading(false)
		} else {
			const newTransmittal = await CreateNewTransmittal(opportunity.id, item);
			const node = {
				ServerRelativeUrl: newTransmittal.ServerRelativeUrl,
				canExpand: false,
				childrenLoaded: false,
				expanded: false,
				id: newTransmittal.UniqueId,
				link: `opportunity/${opportunity.id}/transmittal/view/${newTransmittal.Name}`,
				loading: false,
				opportunityId: opportunity.id,
				parent: `transmittals_${opportunity.id}`,
				path: newTransmittal.Name,
				root: false,
				selected: false,
				storage: opportunity.storage,
				title: newTransmittal.Name,
				type: 'transmittal',
			}
			dispatch(addNode(node))
			SendMessage('Transmittal successfully created!', 'success')


			if (props.handleCreateComplete) {
				props.handleCreateComplete()
			} else {
				navigate(`/opportunity/${opportunity.id}/transmittals/all`, { replace: true })
			}
		}
	}
	return (
		<div>
			<h1>Create transmittal</h1>
			<div className='doc-properties'>
				<div className='doc-properties-row'>
					<div>Name</div>
					<div>
						<input ref={transmittalNameInput} className='input' type='text' />
					</div>
				</div>
				<div className='doc-properties-row'>
					<div>Description</div>
					<div>
						<div ref={transmittalDescriptionInput} className='textarea' contentEditable='true'></div>
					</div>
				</div>
				<div className='doc-properties-row'>
					<div>Recipient(s)</div>
					<div>
						<input ref={transmittalRecipientInput} className='input' type='text' />
					</div>
				</div>
			</div>
			<button disabled={loading} className='btn btn-blue mt-25' onClick={() => handleCreateTransmittal()}>
				{loading ? (
					<span>
						<FontAwesomeIcon className='fa-spin' icon={faSpinner} /> Processing..
					</span>
				) : (
					<span>Create</span>
				)}
			</button>
		</div>
	)
}
export default CreateTransmittal
