import React, { useState } from 'react'
import Reply from './Reply'
import NewComment from './NewComment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import { faUserCircle, faBan } from '@fortawesome/free-solid-svg-icons'
import { GetFormattedDate } from '../../../../../utils/appUtils'
function Comment(props) {
	const [toggleReply, setToggleReply] = useState(false)
	return (
		<div className='comment'>
			<div>
				<div>
					<span>
						<FontAwesomeIcon icon={faUserCircle} />
					</span>
					<span>	{props.comment.Author.Title}</span>
					<span>ID: {props.comment.ID}</span>
					<span>{GetFormattedDate(props.comment.Created)}</span>
				</div>
				<div onClick={() => setToggleReply((toggleReply) => !toggleReply)}>
					<span>
						<FontAwesomeIcon icon={toggleReply ? faBan : faPaperPlane} />
					</span>
					<span>{toggleReply ? 'Cancel' : 'Reply'}</span>
				</div>
			</div>
			<div>
				<div> {props.comment.Comment}</div>
			</div>
			{toggleReply ? <NewComment user={props.user} siteUsers = {props.siteUsers} reply={true} commentID={props.comment.ID} SendComment={props.SendComment}/> : null}
			{props.comment.Replies.length > 0
					? props.comment.Replies.map((reply, index) => {
						return (
							<div className="Reply" key={index}>
								<Reply comment={reply} />
							</div>
						);
					})
					: null}
		</div>
	)
}

export default Comment
