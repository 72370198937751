import React, { useEffect, useState, useCallback } from 'react'
import { useParams, useOutletContext } from 'react-router-dom'
import './Comments.css'
import NewComment from './Components/Comments/NewComment'
import { getCommentsAndRepliesForDoc } from '../../../utils/fetchUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addComment } from '../../../utils/fetchUtils'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import RevisionGroup from './Components/Comments/RevisionGroup'
import { sendEmails } from '../../../utils/fetchUtils'
import { useUsers } from '../../Utilities/UserContext/UserProvider'
import { updateCommentsViewedBy } from '../../../utils/fetchUtils'

const Comments = () => {
	const { document } = useOutletContext()
	const { currentUser, siteUsers } = useUsers()
	//const [documentid, setDocumentid] = useState(null)
	//const [revision, setRevision] = useState(null)
	//const [opportunity, setOpportunity] = useState(null)
	const [comments, setComments] = useState([])
	const [loading, setLoading] = useState(false)
	let params = useParams()
	const SendComment = useCallback(
		async (comment, reply, ID, taggedUsers) => {
			if (comment.trim().length === 0) return
			setLoading(true)
			await addComment(document.storage, comment, reply, ID, document.opportunityId, document.documentid, document.revision, currentUser.Id)
			if (taggedUsers.length > 0) {
				await sendEmails(taggedUsers, siteUsers)
			}
			await getCommnets()
		},
		[document]
	)
	const getCommnets = async () => {
		if (!loading) {
			setLoading(true)
		}
		let comments = await getCommentsAndRepliesForDoc(document.storage, document.documentid)
		setComments(comments)
		setLoading(false)
	}
	useEffect(() => {
		getCommnets()
		updateCommentsViewedBy(document.storage, currentUser.Id, document.documentid)
	}, [])
	if (!currentUser || !siteUsers) {
		return <></>
	}
	return (
		<div>
			<h3>Document comments</h3>
			<div className='comment-section'>
				<NewComment user={currentUser} siteUsers={siteUsers} reply={false} SendComment={SendComment} />
			</div>
			{loading ? (
				<div className='comment-section'>
					<FontAwesomeIcon className='fa-spin' icon={faSpinner} /> Loading comments..
				</div>
			) : Object.keys(comments).length > 0 ? (
				Object.entries(comments)
					.reverse()
					.map((revisionGroup, index) => {
						return (
							<div key={index} className='comment-section'>
								<RevisionGroup
									latestRevision={document.revision}
									revision={revisionGroup[0]}
									comments={revisionGroup[1]}
									user={currentUser}
									siteUsers={siteUsers}
									SendComment={SendComment}
								/>
							</div>
						)
					})
			) : (
				<div className='comment-section'>No comments...</div>
			)}
		</div>
	)
}

export default Comments
