import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faInfo } from '@fortawesome/free-solid-svg-icons'
import Dropdown from '../../../Utilities/Dropdown/Dropdown'
import Tooltip from '../../../Utilities/Tooltip/Tooltip'
import {
	getDocumentClassifications,
	getArchivalCodeChoices,
	getInformationClassChoices,
	getApplicationAreaChoices,
	getKnowledgeAreaChoices,
} from '../../../../utils/fetchUtils'

const Metadata = ({ mode, document, setValues }) => {
	// console.log('document', document)
	const [documentClassifications, setDocumentClassifications] = useState(null)
	const [documentArchivalCodes, setDocumentArchivalCodes] = useState(null)
	const [documentInformationClasses, setDocumentInformationClasses] = useState(null)
	const [documentApplicationAreas, setDocumentApplicationAreas] = useState(null)
	const [documentKnowledgeAreas, setDocumentKnowledgeAreas] = useState(null)
	const fetchData = async () => {
		const Choises = await Promise.all([
			getDocumentClassifications(document.storage),
			getArchivalCodeChoices(document.storage),
			getInformationClassChoices(document.storage),
			getApplicationAreaChoices(document.storage),
			getKnowledgeAreaChoices(document.storage),
		])

		setDocumentClassifications(Choises[0])
		setDocumentArchivalCodes(Choises[1])
		setDocumentInformationClasses(Choises[2])
		setDocumentApplicationAreas(Choises[3])
		setDocumentKnowledgeAreas(Choises[4])
	}
	useEffect(() => {
		if (mode === 'edit' || mode === 'finalise') {
			fetchData()
		}
	}, [])

	return (
		<>
			<h3>Metadata</h3>
			<div className='doc-properties'>
				<div className='doc-properties-row'>
					<div className='doc-properties-row-with-info'>
						<span className={mode === 'finalise' ? 'required' : null}>CLASS</span>
						<Tooltip
							icon={faInfo}
							text={
								'Choose the appropriate class for the sales document. Classes are based on IEC61355 documentation standard.'
							}
						/>
					</div>
					{mode === 'view' ? <div>{document.class}</div> : null}
					{mode === 'edit' || mode === 'finalise' ? (
						<div>
							{documentClassifications ? (
								<Dropdown
									data={documentClassifications}
									placeholder={'Select document class'}
									selected={document.class}
									searchable={true}
									setValue={setValues.DocumentClass}
								/>
							) : (
								<span>
									<FontAwesomeIcon className='fa-spin' icon={faSpinner} /> Laoding..
								</span>
							)}
						</div>
					) : null}
				</div>
				<div className='doc-properties-row'>
					<div className='doc-properties-row-with-info'>
						<span className={mode === 'finalise' ? 'required' : null}>ARCHIVAL CODE</span>
						<Tooltip
							icon={faInfo}
							text={
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
							}
						/>
					</div>
					{mode === 'view' ? <div>{document.archivalCode}</div> : null}
					{mode === 'edit' || mode === 'finalise' ? (
						<div>
							{documentArchivalCodes ? (
								<Dropdown
									data={{
										grouped: false,
										items: documentArchivalCodes,
									}}
									placeholder={'Select archival code'}
									selected={document.archivalCode}
									searchable={false}
									setValue={setValues.ArchivalCode}
								/>
							) : (
								<span>
									<FontAwesomeIcon className='fa-spin' icon={faSpinner} /> Laoding..
								</span>
							)}
						</div>
					) : null}
				</div>
				<div className='doc-properties-row'>
					<div className='doc-properties-row-with-info'>
						<span className={mode === 'edit' || mode === 'finalise' ? 'required' : null}>INFORMATION CLASSIFICATION</span>
						<Tooltip
							icon={faInfo}
							text={
								'Choose Internal, when documents can be available for all working in the Wärtsilä community​. Choose Confidential, when documents have a restricted distribution / sending documents to customer (external documents)'
							}
						/>
					</div>
					{mode === 'view' ? <div>{document.informationClass}</div> : null}
					{mode === 'edit' || mode === 'finalise' ? (
						<div>
							{documentInformationClasses ? (
								<Dropdown
									data={{
										grouped: false,
										items: documentInformationClasses,
									}}
									placeholder={'Select information classification'}
									selected={document.informationClass}
									searchable={false}
									setValue={setValues.InformationClass}
								/>
							) : (
								<span>
									<FontAwesomeIcon className='fa-spin' icon={faSpinner} /> Laoding..
								</span>
							)}
						</div>
					) : null}
				</div>
				<div className='doc-properties-row'>
					<div className='doc-properties-row-with-info'>
						<span className={mode === 'finalise' ? 'required' : null}>APPLICATION AREA</span>
						<Tooltip
							icon={faInfo}
							text={
								'Choose A - Overall management ​document​ Choose B - Overall technology, if:​Drawings / Layouts​Site Inspection Report / additional electrical​ Perf Calculations, Perf excel file​ Emission sheet, Emission statement, Flue gas data sheet ​Choose C – Constuction engineering, if:​ Request for site survey​ Topographical survey, specification​ Typical bore hole locations​ Soil & Topography investigation report / SI specification​'
							}
						/>
					</div>
					{mode === 'view' ? <div>{document.applicationArea}</div> : null}
					{mode === 'edit' || mode === 'finalise' ? (
						<div>
							{documentApplicationAreas ? (
								<Dropdown
									data={{
										grouped: false,
										items: documentApplicationAreas,
									}}
									placeholder={'Select application area'}
									selected={document.applicationArea}
									searchable={false}
									setValue={setValues.ApplicationArea}
								/>
							) : (
								<span>
									<FontAwesomeIcon className='fa-spin' icon={faSpinner} /> Laoding..
								</span>
							)}
						</div>
					) : null}
				</div>
				<div className='doc-properties-row'>
					<div className='doc-properties-row-with-info'>
						<span className={mode === 'finalise' ? 'required' : null}>KNOWLEDGE AREA</span>
						<Tooltip
							icon={faInfo}
							text={
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
							}
						/>
					</div>
					{mode === 'view' ? <div>{document.knowledgeArea}</div> : null}
					{mode === 'edit' || mode === 'finalise' ? (
						<div>
							{documentKnowledgeAreas ? (
								<Dropdown
									data={{
										grouped: false,
										items: documentKnowledgeAreas,
									}}
									placeholder={'Select knowledge area'}
									selected={document.knowledgeArea}
									searchable={true}
									setValue={setValues.KnowledgeArea}
								/>
							) : (
								<span>
									<FontAwesomeIcon className='fa-spin' icon={faSpinner} /> Laoding..
								</span>
							)}
						</div>
					) : null}
				</div>
			</div>
		</>
	)
}
export default Metadata
