import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setRootNode } from '../../components/Tree/treeSlice'
import EmailPicker from '../Utilities/EmailPicker/EmailPicker'


const Home = () => {
	const dispatch = useDispatch()
	const nodes = useSelector((state) => state.tree.nodes)
	const loading = useSelector((state) => state.tree.loading)
	const rootFetched = useSelector((state) => state.tree.rootFetched)
	useEffect(() => {
		if (rootFetched) {
			let rootNodeIDs = nodes.filter((node) => node.type === 'region').map((node) => node.id)
			dispatch(setRootNode(rootNodeIDs))
		}
	}, [rootFetched])
	return (
		<div>
			<h1>Home</h1>
		</div>
	)
}
export default Home
