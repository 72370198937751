import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { subRegionLanding, setSelectedNode } from '../../../Tree/treeSlice'

const SubRegion = (props) => {
	let [subRegion, setSubRegion] = useState(null)
	let params = useParams()
	const dispatch = useDispatch()
	const nodes = useSelector((state) => state.tree.nodes)
	const rootSet = useSelector((state) => state.tree.rootSet)
	useEffect(() => {
		if (nodes.length) {
			setSubRegion({
				...nodes.find((node) => node.type === "subregion" && node.title === params.subregion),
			})
		}
	}, [params])
	useEffect(() => {
		if (subRegion) {
			dispatch(setSelectedNode(subRegion.id))
		}
	}, [subRegion])
	useEffect(() => {
		if (params) {
			if (!rootSet) {
				if (nodes.length) {
					setSubRegion({
						...nodes.find((node) => node.type === "subregion" && node.title === params.subregion),
					})
					dispatch(subRegionLanding(params.subregion))
				}
			} 
		}
	}, [params, rootSet, nodes])
	return (
		<div>
			<h1>SubRegion component</h1>
			<h2>id: {subRegion ? subRegion.id : null}</h2>
		</div>
	)
};
export default SubRegion;
