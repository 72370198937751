import React from 'react'
import ClipboardManager from "../ClipboardManager/ClipboardManager"
import TransmittalManager from "../TransmittalManager/TransmittalManager"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn } from '@fortawesome/free-solid-svg-icons'
import './ToolSelector.css'
const ToolSelector = ({ selectedTool, setSelectedTool }) => {

	return (
		<div className='tool-selector'>
			<div className='tool-selector-left'></div>
			<div className='tool-selector-right'>{selectedTool ? <TransmittalManager /> : <ClipboardManager />}</div>
			<div></div>
			<div className='tool-selector-toggle'>
				<span className='tool-selector-toggle-clipboard' onClick={() => setSelectedTool(false)}>
					Clipboard
				</span>
				<span>{selectedTool ? <FontAwesomeIcon icon={faToggleOn} /> : <FontAwesomeIcon className='fa-flip-horizontal' icon={faToggleOn} />}</span>
				<span className='tool-selector-toggle-transmittal' onClick={() => setSelectedTool(true)}>
					Transmittals
				</span>
			</div>
		</div>
	)
}
export default ToolSelector
