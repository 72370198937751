import React, { useEffect, useState, useRef } from 'react'
import { useOutletContext, useNavigate } from 'react-router-dom'
import File from './Components/File'
import Properties from './Components/Properties'
import Metadata from './Components/Metadata'
import Auditable from './Components/Auditable'
import { FinaliseDocument } from '../../../utils/fetchUtils'
import { ObjectHasEmptyValues } from '../../../utils/appUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useSendMessage } from '../../Utilities/MessageManager/MessageContext'
const Finalise = () => {
	const { document } = useOutletContext()
	const SendMessage = useSendMessage()
	let navigate = useNavigate()
	const documentTitleInput = useRef(null)
	const [loading, setLoading] = useState(false)
	const [documentClass, setDocumentClass] = useState(document.class)
	const [documentArchivalCode, setDocumentArchivalCode] = useState(document.archivalCode)
	const [documentInformationClass, setDocumentInformationClass] = useState(document.informationClass)
	const [documentApplicationArea, setDocumentApplicationArea] = useState(document.applicationArea)
	const [documentKnowledgeArea, setDocumentKnowledgeArea] = useState(document.knowledgeArea)
	const [pdfRender, setPdfRender] = useState(document.renderStatus === 'Manual' ? false : true)
	useEffect(() => {
		if (document.status !== 'Draft') {
			navigate(`/opportunity/${document.opportunityId}/document/view/${document.documentid}/${document.revision}`, { replace: true })
		}
	}, [])
	const SubmitData = async () => {
		setLoading(true)
		let item = {
			title: {
				required: true,
				value: documentTitleInput.current.value === '' ? null : documentTitleInput.current.value,
			},
			class: {
				required: true,
				value: documentClass ? documentClass.label : documentClass,
			},
			informationClass: {
				required: true,
				value: documentInformationClass ? documentInformationClass.label : documentInformationClass,
			},
			archivalCode: {
				required: true,
				value: documentArchivalCode ? documentArchivalCode.label : documentArchivalCode,
			},
			applicationArea: {
				required: true,
				value: documentApplicationArea ? documentApplicationArea.label : documentApplicationArea,
			},
			knowledgeArea: {
				required: true,
				value: documentKnowledgeArea ? documentKnowledgeArea.label : documentKnowledgeArea,
			},
			pdfRender: {
				required: true,
				value:  document.fileType === 'pdf' ? 'NA' : pdfRender ? 'Started':'Manual',
			},
		}
		if (ObjectHasEmptyValues(item)) {
			console.log('hepp')
			SendMessage("Required fields missing!", "alert")
			setLoading(false)
		} else {
			await FinaliseDocument(document.storage, document.opportunityId, document.SPID, item, document.ServerRelativeUrl, document.fileType)
			SendMessage("Document successfully finalised!", "success")
			navigate(`/opportunity/${document.opportunityId}/document/view/${document.documentid}/${document.revision}`, { replace: true })
		}		
	}
	return (
		<div className='page'>
			<h1>
				Finalise document
			</h1>
			<File mode={'finalise'} document={document} pdfRender={{ pdfRender: pdfRender, setPdfRender: setPdfRender }} />
			<Properties mode={'finalise'} document={document} documentTitleInput={documentTitleInput} />
			<Metadata
				mode={'finalise'}
				document={document}
				setValues={{
					ArchivalCode: setDocumentArchivalCode,
					DocumentClass: setDocumentClass,
					InformationClass: setDocumentInformationClass,
					ApplicationArea: setDocumentApplicationArea,
					KnowledgeArea: setDocumentKnowledgeArea,
				}}
			/>
			<Auditable document={document} />
			<button disabled={loading} className='btn btn-blue mt-25' onClick={() => SubmitData()}>
				{loading ? (
					<span>
						<FontAwesomeIcon className='fa-spin' icon={faSpinner} /> Finalising..
					</span>
				) : (
					<span>Finalise document</span>
				)}
			</button>
		</div>
	)
}

export default Finalise
