import React, { useState, useContext } from 'react'
const ModalContext = React.createContext()
export const useModal = () => {
	return useContext(ModalContext)
}
const ModalProvider = ({ children }) => {
	const [modal, setModal] = useState(null)
	return (
		<ModalContext.Provider value={[modal, setModal]}>{children}</ModalContext.Provider>
	)
}
export default ModalProvider