import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useState, useEffect } from 'react'
import './MessageManager.css'
import { useMessage } from './MessageContext'
import { useSendMessage } from './MessageContext'
const MessageManager = () => {
	const [togglebutton, settogglebutton] = useState(false)
	const Message = useMessage()
	const SendMessage = useSendMessage()
	useEffect(() => {
		if (Message) {
			switch (Message.type) {
				case 'error':
					settogglebutton(true)
					break
				case 'alert':
					setTimeout(() => {
						SendMessage(null)
					}, 3000)
					break
				case 'success':
					setTimeout(() => {
						SendMessage(null)
					}, 3000)
                    break
				default:
			}
		}
	}, [Message])
	const handleCloseMessage = () => {
        SendMessage(null)
        settogglebutton(false)
	}
	return Message ? (
		<div className={`message ${Message.type}`}>
			{Message.message}
			{togglebutton ? <div className='close-message' onClick={() => handleCloseMessage()}>Close <FontAwesomeIcon icon={faXmark}/></div> : null}
		</div>
	) : null
}
export default MessageManager
