import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Auth from './Auth'
import { UserProvider } from '../src/components/Utilities/UserContext/UserProvider'
ReactDOM.render(
	<React.StrictMode>
		<UserProvider>
			<Auth />
		</UserProvider>
	</React.StrictMode>,
	document.getElementById('root')
)
