import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Properties = ({ mode, document, documentTitleInput }) => {
	return (
		<>		
			<h3>Properties</h3>
			<div className='doc-properties'>
				<div className='doc-properties-row'>
					<div className={mode === 'edit' || mode === 'finalise' || mode === 'revise' ? 'required' : null}>title</div>
					{mode === 'view' ? <div>{document.title}</div> : null}
					{mode === 'edit' || mode === 'finalise' || mode === 'revise'  ? (
						<div>
							<input ref={documentTitleInput} className='input' type='text' defaultValue={document.title} />
						</div>
					) : null}
				</div>
				<div className='doc-properties-row'>
					<div>document id</div>
					<div>{document.documentid}</div>
				</div>
				<div className='doc-properties-row'>
					<div>Revision</div>
					{mode === 'revise' ? (
						<div>
							{document.revision.toString().padStart(2, '0')} <FontAwesomeIcon icon={faArrowRight} />{' '}
							{(document.revision + 1).toString().padStart(2, '0')}
						</div>
					) : (
						<div>{document.revision.toString().padStart(2, '0')}</div>
					)}
				</div>
				<div className='doc-properties-row'>
					<div>status</div>
					{mode === 'revise' ? (
						<div>
							{document.status} <FontAwesomeIcon icon={faArrowRight} />
							{' Draft'}
						</div>
					) : (
						<div>{document.status}</div>
					)}
				</div>
			</div>
		</>
	)
}
export default Properties
