import React, { useEffect, useState } from 'react'
import { getTransmittals } from '../../../utils/fetchUtils'
import { useOpportunity } from '../../Utilities/TransmittalManager/TransmittalContext'
import ListOfTransmittals from './ListOfTransmittals'
import TransmitttalsFilter from '../../Folder/Browse/Components/TransmittalsFilter'
function ListOfAllTransmittals() {
	const [transmittals, setTransmittals] = useState()
	const [filteredTransmittals, setFilteredTransmittals] = useState([]);
	const [opportunity] = useOpportunity()
	useEffect(() => {
		const fetchTransmittals = async () => {
			let Transmittals = await getTransmittals(opportunity.storage, opportunity.id, 'Open')
			setTransmittals(Transmittals)
			setFilteredTransmittals(Transmittals);
		}
		if (opportunity) {
			fetchTransmittals()
		}
	}, [opportunity])

	return (
		<div>
			{transmittals && <TransmitttalsFilter transmittals={transmittals} setFilteredTransmittals={setFilteredTransmittals} />}
			<h3>Transmittals</h3>
			<ListOfTransmittals transmittals={filteredTransmittals} />
		</div>
	)
}

export default ListOfAllTransmittals
