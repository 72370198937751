import React, { useEffect, useState } from 'react'
import { getRevisionsById } from '../../../../utils/fetchUtils'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { selectDocument, deselectDocument } from './browseSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faSquareCheck, faFilePdf } from '@fortawesome/free-regular-svg-icons'
import DocumentOptionsMenu from './DocumentOptionsMenu'
import { GetFileIcon, GetFileLink, ReadAppLink, DownloadLink, ModifyAppLink, OpenInNewTab } from '../../../../utils/appUtils'
import { faCircleExclamation, faComment, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useUsers } from '../../../Utilities/UserContext/UserProvider'
import { useTransmittal } from '../../../Utilities/TransmittalManager/TransmittalContext'
import Tooltip from '../../../Utilities/Tooltip/Tooltip'
import { useActiveTransmittal } from '../../../Utilities/TransmittalManager/TransmittalContext'
import { useClipboard } from '../../../Utilities/ClipboardManager/ClipboardContext'

function FileLink({ fileType, status, url, purpose, renderStatus }) {
	const DraftRead = () => {
		if (fileType === 'pdf') {
			return (
				<div onClick={() => OpenInNewTab(GetFileLink(fileType, status, url, purpose))}>
					<FontAwesomeIcon icon={GetFileIcon(fileType)} /> PDF
				</div>
			)
		} else {
			return (
				<div onClick={() => OpenInNewTab(GetFileLink(fileType, status, url, purpose))}>
					<FontAwesomeIcon icon={GetFileIcon(fileType)} /> {purpose}
				</div>
			)
		}
	}
	const DraftModify = () => {
		if (fileType === 'pdf') {
			return <div></div>
		} else {
			return (
				<div onClick={() => OpenInNewTab(GetFileLink(fileType, status, url, purpose))}>
					<FontAwesomeIcon icon={GetFileIcon(fileType)} /> {purpose}
				</div>
			)
		}
	}
	const FinalisedRead = () => {
		if (fileType === 'pdf') {
			return (
				<div onClick={() => OpenInNewTab(GetFileLink(fileType, status, url, purpose))}>
					<FontAwesomeIcon icon={GetFileIcon(fileType)} /> PDF
				</div>
			)
		} else {
			return (
				<div onClick={() => OpenInNewTab(GetFileLink(fileType, status, url, purpose))}>
					<FontAwesomeIcon icon={GetFileIcon(fileType)} /> {purpose}
				</div>
			)
		}
	}
	const ExpiredRead = () => {
		return (
			<div onClick={() => OpenInNewTab(GetFileLink(fileType, status, url, purpose))}>
				<FontAwesomeIcon icon={GetFileIcon(fileType)} /> {purpose}
			</div>
		)
	}
	const PDFStarted = () => {
		return (
			<div>
				<FontAwesomeIcon className='fa-spin' icon={faSpinner} /> Render
			</div>
		)
	}
	const PDFCompleted = () => {
		return (
			<div>
				<FontAwesomeIcon icon={GetFileIcon(fileType)} /> PDF
			</div>
		)
	}
	const PDFFailed = () => {
		return <div></div>
	}
	const PDF = () => {
		if (fileType === 'pdf') {
			return <div></div>
		} else {
			switch (renderStatus) {
				case 'Preview':
					return <div></div>
				case 'Manual':
					return <div></div>
				case 'Started':
					return PDFStarted()
				case 'Completed':
					return PDFCompleted()
				case 'Failed':
					return PDFFailed()
				case 'NA':
					return <div></div>
				default:
					return <div></div>
			}
		}
	}
	// eslint-disable-next-line default-case
	switch (purpose) {
		case 'Read':
			switch (status) {
				case 'Draft':
					return DraftRead()
				case 'Finalised':
					return FinalisedRead()
				case 'Expired':
					return ExpiredRead()
				default:
					return <div></div>
			}
		case 'Modify':
			switch (status) {
				case 'Draft':
					return DraftModify()
				case 'Finalised':
					return PDF()
				case 'Expired':
					return PDF()
				default:
					return <div></div>
			}
	}
}
const ReadLink = ({ document }) => {
	const Link = () => {
		return (
			<div onClick={() => OpenInNewTab(ReadAppLink(document.fileType, document.ServerRelativeUrl))}>
				<FontAwesomeIcon icon={GetFileIcon(document.fileType)} /> {document.fileType === 'pdf' ? 'PDF' : 'Read'}
			</div>
		)
	}
	// eslint-disable-next-line default-case
	switch (document.status) {
		case 'Draft':
			return Link()
		case 'Finalised':
			return Link()
		case 'Expired':
			return Link()
	}
}
const ModifyLink = ({ document }) => {
	const Modify = () => {
		if (document.fileType === 'pdf') {
			return <div></div>
		} else {
			return (
				<div onClick={() => OpenInNewTab(ModifyAppLink(document.fileType, document.ServerRelativeUrl))}>
					<FontAwesomeIcon icon={GetFileIcon(document.fileType)} /> Modify
				</div>
			)
		}
	}
	const Render = () => {
		if (document.renderStatus === "Started") return (
			<div style={{ cursor: "default" }}>
				<FontAwesomeIcon className='fa-spin' icon={faSpinner} />
			</div >)
		if (document.renderStatus === "Completed" && document.render) return (
			<div onClick={() => DownloadLink(document.storage, document.render.fileType, document.render.ServerRelativeUrl)}>
				<FontAwesomeIcon icon={GetFileIcon(document.render.fileType)} /> PDF
			</div>
		)

		if (document.renderStatus === "Failed") return (
			<div style={{ color: "#ff726f" }}>
				<Tooltip text="PDF render failed. Upload file manually." icon={faFilePdf} /> PDF
			</div>
		)
		return <div></div>

	}
	switch (document.status) {
		case 'Draft':
			return Modify()
		case 'Finalised':
		case 'Expired':
			return Render()
		default: return
	}

}
function PreviousRevisions({ revisions }) {
	return revisions.length
		? revisions.map((document, index) => (
			<div key={index} className='document-row old-revision'>
				<div className='document-row-checkbox'>{document.revision.toString().padStart(2, '0')}</div>
				<div className='document-row-metadata'>
					<div>
						<FileLink
							fileType={document.fileType}
							status={document.status}
							url={document.ServerRelativeUrl}
							purpose={'Read'}
							renderStatus={document.renderStatus}
						/>
					</div>
					<div>
						<Link to={`/opportunity/${document.opportunityId}/document/view/${document.documentid}/${document.revision}`}>
							{document.title}
						</Link>
					</div>
					<div>Expired</div>
					<div>{document.created}</div>
					<div>{document.modified}</div>
					<div>
						<FileLink
							fileType={document.fileType}
							status={document.status}
							url={document.ServerRelativeUrl}
							purpose={'Modify'}
							renderStatus={document.renderStatus}
						/>
					</div>
					<div>{document.class}</div>
					<div></div>
					<div>{document.createdBy}</div>
					<div>{document.modifiedBy}</div>
				</div>
				<div className='document-row-menu'></div>
			</div>
		))
		: null
}

function Document({ document, searchString, comments, selectedTool }) {
	const [togglePreviousRevisions, setTogglePreviousRevisions] = useState(false)
	const [revisions, setRevisions] = useState([])
	const { currentUser } = useUsers()
	const areThereComments = comments.some((comment) => comment.DocumentID === document.documentid)
	const areThereUnseenComments = comments.some((comment) => comment.DocumentID === document.documentid && !comment.ViewedBy.includes(currentUser.Id))
	const [transmittal] = useTransmittal()
	const [activeTransmittal] = useActiveTransmittal()
	const dispatch = useDispatch()
	const [clipboard] = useClipboard()

	useEffect(() => {
		if (togglePreviousRevisions) {
			const fetchData = async () => {
				setRevisions(await getRevisionsById(document.opportunityId, document.storage, document.documentid))
			}
			fetchData()
		}
	}, [togglePreviousRevisions])
	const Highlighted = ({ text = '' }) => {
		if (!searchString.trim()) {
			return <span>{text}</span>
		}
		const regex = new RegExp(`(${searchString})`, 'gi')
		const parts = text.split(regex)

		return (
			<span>
				{parts.filter(String).map((part, i) => {
					return regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
				})}
			</span>
		)
	}
	const documentsCanTransmit = (selectedDocument) => {
		const wrongTransmittalActive = () => {
			if (activeTransmittal)
				return !(selectedDocument.opportunityId === activeTransmittal?.opportunityId);
		}
		const documentInTransmittal = (id, rev) => {
			if (transmittal && activeTransmittal) {
				const CheckIfDocumentInTransmittal = (document) => `${document.documentid}_${document.revision}` === `${id}_${rev}`
				return transmittal.some(CheckIfDocumentInTransmittal)
			}
		}
		const documentStatusNotFinalised = (status) => status !== 'Finalised'
		const documentRenderMissing = (selectedDocument) => {
			if (selectedDocument.renderStatus === 'NA') {
				return false
			} else if (selectedDocument.renderStatus === 'Completed' || selectedDocument.render) {
				return false
			} else {
				return true
			}
		}

		if (wrongTransmittalActive()) {
			return {
				...selectedDocument,
				canTransmit: false,
				reason: 'Active transmittal is in another Opportunity',
			}
		}
		if (documentInTransmittal(selectedDocument.documentid, selectedDocument.revision)) {
			return {
				...selectedDocument,
				canTransmit: false,
				reason: 'Document already in transmittal',
			}
		}
		if (documentStatusNotFinalised(selectedDocument.status)) {
			return {
				...selectedDocument,
				canTransmit: false,
				reason: 'Document not finalised',
			}
		}
		if (documentRenderMissing(selectedDocument)) {
			return {
				...selectedDocument,
				canTransmit: false,
				reason: 'Document render file is missing',
			}
		} else {
			return {
				...selectedDocument,
				canTransmit: true,
				reason: null,
			}
		}
	}

	const documentsCanClipboard = (selectedDocument) => {

		for (let doc of clipboard) {
			if (doc.documentid === selectedDocument.documentid) {
				return {
					...selectedDocument,
					canClipboard: false,
					reason: 'Document is already in Clipboard',
				}
			}
		}
		return {
			...selectedDocument,
			canClipboard: true,
		}
	}

	const SelectDocument = () => {
		return document.selected ? (
			<span onClick={() => dispatch(deselectDocument(document.documentid))}>
				<FontAwesomeIcon icon={faSquareCheck} />
			</span>
		) : (
			<span onClick={() => dispatch(selectDocument(document.documentid))}>
				<FontAwesomeIcon icon={faSquare} />
			</span>
		)
	}
	return (
		<div className='document-row-wrapper'>
			<div className={`document-row latest-revision document-filtered-${document.filtered}`}>
				<div className='document-row-checkbox'>
					{areThereComments && (
						<span className='document-in-transmittal'>
							<span className='fa-layers fa-fw'>
								<Link to={`/opportunity/${document.opportunityId}/document/comments/${document.documentid}/${document.revision}`}>
									<FontAwesomeIcon
										className='fa-flip-horizontal'
										icon={faComment}
										style={areThereUnseenComments ? { color: '#ff7321' } : { color: '#004a6b' }}
									/>
								</Link>
							</span>
						</span>
					)}
					{selectedTool ? (
						documentsCanTransmit(document).canTransmit ? (
							<SelectDocument />
						) : (
							<span>
								<Tooltip icon={faCircleExclamation} text={documentsCanTransmit(document).reason} />
							</span>
						)
					) : (
						documentsCanClipboard(document).canClipboard ? (
							<SelectDocument />
						) : (
							<span>
								<Tooltip icon={faCircleExclamation} text={documentsCanClipboard(document).reason} />
							</span>
						)
					)}
				</div>
				<div className='document-row-metadata'>
					<ReadLink document={document} />
					<div>
						<Link to={`/opportunity/${document.opportunityId}/document/view/${document.documentid}/${document.revision}`}>
							<Highlighted text={document.title} />
						</Link>
					</div>
					<div>{document.status}</div>
					<div>{document.created}</div>
					<div>{document.modified}</div>
					<ModifyLink document={document} />
					<div>{document.class}</div>
					<div>{`${document.documentid},-${document.revision.toString().padStart(2, '0')}`}</div>
					<div>{document.createdBy}</div>
					<div>{document.modifiedBy}</div>
				</div>
				<DocumentOptionsMenu document={document} setTogglePreviousRevisions={setTogglePreviousRevisions} />
			</div>
			{togglePreviousRevisions ? <PreviousRevisions revisions={revisions} /> : null}
		</div>
	)
}

export default Document
