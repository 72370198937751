import React, { useEffect, useState } from 'react'
import { closeTransmittal, getTransmittal, updateTransmittal } from '../../../utils/fetchUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import './transmittal-info.css'
import InplaceEditor from '../../Utilities/InplaceEditor/InplaceEditor'
import { useModal } from '../../Utilities/Modal/ModalContext'
import Dropdown from '../../Utilities/Dropdown/Dropdown'
import { useSendMessage } from '../../Utilities/MessageManager/MessageContext'
import { useUsers } from '../../Utilities/UserContext/UserProvider'
import { useActiveTransmittal } from '../../Utilities/TransmittalManager/TransmittalContext'

const TransmittalInformation = ({ transmittal, setTransmittal, selectedTransmittal, zip }) => {

	const handleEditTransmittalName = async (data) => {
		await updateTransmittal(selectedTransmittal.storage, transmittal.SPID, data)
		setTransmittal(await getTransmittal(selectedTransmittal.storage, selectedTransmittal.opportunityId, selectedTransmittal.title))
		return
	}
	const handleEditTransmittalDescription = async (data) => {
		await updateTransmittal(selectedTransmittal.storage, transmittal.SPID, data)
		setTransmittal(await getTransmittal(selectedTransmittal.storage, selectedTransmittal.opportunityId, selectedTransmittal.title))
		return
	}
	const handleEditTransmittalMessage = async (data) => {
		await updateTransmittal(selectedTransmittal.storage, transmittal.SPID, data)
		setTransmittal(await getTransmittal(selectedTransmittal.storage, selectedTransmittal.opportunityId, selectedTransmittal.title))
		return
	}
	const handleEditTransmittalNotes = async (data) => {
		await updateTransmittal(selectedTransmittal.storage, transmittal.SPID, data)
		setTransmittal(await getTransmittal(selectedTransmittal.storage, selectedTransmittal.opportunityId, selectedTransmittal.title))
		return
	}
	const TransmittalStatusDropdown = () => {
		const [transmittalStatus, setTransmittalStatus] = useState()
		const { currentUser, siteUsers } = useUsers()
		const [modal, setModal] = useModal()
		const [activeTransmittal, setActiveTransmittal] = useActiveTransmittal()
		const SendMessage = useSendMessage()
		useEffect(() => {
			const ModalContent = () => {
				console.log("transmittal.status", transmittalStatus.label)
				const [loading, setLoading] = useState(false)
				const handleChangeTransmittalStatus = async () => {
					setLoading(true)
					let data = {
						TransmittalStatus: transmittalStatus.label,
					}
					if (transmittalStatus.label === 'Closed') {
						await closeTransmittal(
							selectedTransmittal.storage,
							selectedTransmittal.opportunityId,
							selectedTransmittal.title,
							selectedTransmittal.ServerRelativeUrl,
							currentUser.Email,
							transmittal.SPID,
							transmittal.message,
							transmittal.created,
							data
						)
					} else {
						await updateTransmittal(
							selectedTransmittal.storage,
							transmittal.SPID,
							data
						)
					}
					setTransmittal(await getTransmittal(selectedTransmittal.storage, selectedTransmittal.opportunityId, selectedTransmittal.title))
					setActiveTransmittal(null);
					setLoading(false)
					setModal(null)
					SendMessage('Transmittal status successfully updated!', 'success')
				}
				return (
					<div className='page'>
						<h1>
							Change status to <FontAwesomeIcon icon={faArrowRight} /> "{transmittalStatus.label}" on transmittal {transmittal.id}
						</h1>
						<div>
							<div>Some good text to explain what happens when closing an transmittal</div>
							<div className='mt-25'>
								<button disabled={loading} className='btn btn-blue' onClick={() => handleChangeTransmittalStatus()}>
									{loading ? (
										<span>
											<FontAwesomeIcon className='fa-spin' icon={faSpinner} /> Closing..
										</span>
									) : (
										<span>Change status</span>
									)}
								</button>
							</div>
						</div>
					</div>
				)
			}
			if (transmittalStatus) {
				if (transmittal.status !== transmittalStatus.label) {
					setModal(<ModalContent />)
				}
			}
		}, [transmittalStatus])

		switch (transmittal.status) {
			case 'Open':
				return (
					<Dropdown
						data={{
							grouped: false,
							items: [
								{ id: 0, label: 'Open' },
								{ id: 1, label: 'Closed' },
								{ id: 3, label: 'Expired' },
							],
						}}
						placeholder={'Change transmittal status'}
						selected={transmittal.status}
						searchable={false}
						setValue={setTransmittalStatus}
					/>
				)
			case 'Closed':
				return (
					<Dropdown
						data={{
							grouped: false,
							items: [
								{ id: 1, label: 'Closed' },
								{ id: 2, label: 'Sent' },
								{ id: 3, label: 'Expired' },
							],
						}}
						placeholder={'Change transmittal status'}
						selected={transmittal.status}
						searchable={false}
						setValue={setTransmittalStatus}
					/>
				)
			case 'Sent':
				return <div>{transmittal.status}</div>
			case 'Expired':
				return transmittal.status
			default:
				return null
		}
	}
	return transmittal ? (
		<div className='transmittal-info'>
			<div className='transmittal-info-table'>
				<div>Name</div>
				<div>
					{transmittal.status === 'Open' ? (
						<InplaceEditor column={'TransmittalName'} handleConfirm={handleEditTransmittalName} text={transmittal.name} type={'input'} />
					) : (
						<div>{transmittal.name}</div>
					)}
				</div>
			</div>
			<div className='transmittal-info-table'>
				<div>Description</div>
				<div>
					{transmittal.status === 'Open' ? (
						<InplaceEditor
							column={'TransmittalDescription'}
							handleConfirm={handleEditTransmittalDescription}
							text={transmittal.description}
							type={'textarea'}
						/>
					) : (
						<div style={{ paddingTop: 5, paddingBottom: 5 }}>{transmittal.description}</div>
					)}
				</div>
			</div>
			<div className='transmittal-info-table'>
				<div>Message</div>
				<div>
					{transmittal.status === 'Open' ? (
						<InplaceEditor column={'Message'} handleConfirm={handleEditTransmittalMessage} text={transmittal.message} type={'textarea'} />
					) : (
						<div style={{ paddingTop: 5, paddingBottom: 5 }}>{transmittal.message}</div>
					)}
				</div>
			</div>
			<div className='transmittal-info-table'>
				<div>Notes</div>
				<div>
					<InplaceEditor column={'Notes'} handleConfirm={handleEditTransmittalNotes} text={transmittal.notes} type={'textarea'} />
				</div>
			</div>
			<div className='transmittal-info-table'>
				<div>Status</div>
				<div>
					<TransmittalStatusDropdown />
				</div>
			</div>
			<div className='transmittal-info-table'>
				<div>Created</div>
				<div>
					{transmittal.created} by {transmittal.createdBy}
				</div>
			</div>

			{zip ? (
				<div className='transmittal-info-table'>
					<div>ZIP</div>
					<div>
						<a href={zip}>Download</a>
					</div>
				</div>
			) : null}
		</div>
	) : null
}
export default TransmittalInformation
