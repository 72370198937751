import React, { useState, useContext } from 'react'

const ClipboardContext = React.createContext()

export const useClipboard = () => {
	return useContext(ClipboardContext)
}
const ClipboardProvider = ({ children }) => {
	const [clipboard, setClipboard] = useState([])
	return (
		<ClipboardContext.Provider value={[clipboard, setClipboard]}>{children}</ClipboardContext.Provider>
	)
}
export default ClipboardProvider
