import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { regionLanding, setSelectedNode } from '../../Tree/treeSlice'

const Region = (props) => {
	let [region, setRegion] = useState(null)
	let params = useParams()
	const dispatch = useDispatch()
	const nodes = useSelector((state) => state.tree.nodes)
	const rootSet = useSelector((state) => state.tree.rootSet)
	useEffect(() => {
		if (nodes.length) {
			setRegion({
				...nodes.find((node) => node.type === "region" && node.title === params.region),
			})
		}
	}, [params])
	useEffect(() => {
		if (region) {
			dispatch(setSelectedNode(region.id))
		}
	}, [region])
	useEffect(() => {
		if (params) {
			if (rootSet) {
			} else {
				if (nodes.length) {
					setRegion({
						...nodes.find((node) => node.type === "region" && node.title === params.region),
					})
					dispatch(regionLanding(params.region))
				}
			}
		}
	}, [params, rootSet, nodes])
	return (
		<div>
			<h1>Region component</h1>
			<h2>id: {region ? region.id : null}</h2>
		</div>
	)
}

export default Region
