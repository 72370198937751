import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import {
	getInitialStateNodes,
	getOpportunitiesForCountry,
	getOpportunityRootFolders,
	getSubfoldersById,
	getOpportunityById,
	getSubfoldersByParentPath,
	getTransmittalRootFolders,
} from '../../utils/fetchUtils'

export const fetchInitialStateNodes = createAsyncThunk('getInitialStateNodes', async (data, { dispatch }) => {
	return getInitialStateNodes()
})
export const fetchOpportunitiesForCountry = createAsyncThunk('getOpportunitiesForCountry', async (countryid, { dispatch }) => {
	let nodes = await getOpportunitiesForCountry(countryid)
	return {
		nodes: nodes,
		nodeid: countryid,
	}
})
export const fetchOpportunityRootFolders = createAsyncThunk('getOpportunityRootFolders', async (parameters, { dispatch }) => {
	console.log('from fetchOpportunityRootFolders')
	let nodes = await getOpportunityRootFolders(parameters.opportunityid, parameters.storage)
	return {
		nodes: nodes,
		nodeid: parameters.opportunityid,
	}
})
export const fetchTransmittalRootFolders = createAsyncThunk('getTransmittalRootFolders', async (parameters, { dispatch }) => {
	let nodes = await getTransmittalRootFolders(parameters.opportunityid, parameters.storage, parameters.parentId)
	return {
		nodes: nodes,
		nodeid: parameters.parentId,
		transmittalId: parameters.transmittalId,
	}
})
export const fetchSubfoldersByPath = createAsyncThunk('getSubfoldersByPath', async (data, { dispatch }) => {
	let SubFolders = await getSubfoldersByParentPath(data.parentPath, data.opportunityId, data.storage, data.parentId)
	return {
		nodes: SubFolders,
		nodeid: data.parentId,
	}
})
export const fetchOpportunityById = createAsyncThunk('getOpportunityById', async (opportunityid, { dispatch }) => {
	let Opportunity = await getOpportunityById(opportunityid)
	let [Opportunities, RootFolders] = await Promise.all([
		getOpportunitiesForCountry(Opportunity.countryId),
		getOpportunityRootFolders(opportunityid, Opportunity.storage),
	])
	return {
		Opportunities: Opportunities,
		RootFolders: RootFolders,
		nodeid: opportunityid,
	}
})
export const fetchFoldersByPath = createAsyncThunk('getFoldersByPath', async (data, { dispatch }) => {
	let Opportunity = await getOpportunityById(data.opportunityid)
	console.log('from fetchFoldersByPath')
	let [Opportunities, RootFolders] = await Promise.all([
		getOpportunitiesForCountry(Opportunity.countryId),
		getOpportunityRootFolders(data.opportunityid, Opportunity.storage),
	])
	let FolderPaths = data.SelectedFolderPath.split('/')
	let ExpandedRootFolderPath = FolderPaths.slice(0, 1).toString()
	let ExpandedRootFolderNodeIndex = RootFolders.findIndex((RootFolder) => RootFolder.title === ExpandedRootFolderPath)
	RootFolders[ExpandedRootFolderNodeIndex].expanded = true
	RootFolders[ExpandedRootFolderNodeIndex].childrenLoaded = true

	let AllFolderPaths = [...FolderPaths].reduce((previousFolder, currentFolder, index) => {
		if (index === 0) {
			return [...previousFolder, previousFolder + currentFolder]
		} else {
			return [...previousFolder, previousFolder[index - 1] + '/' + currentFolder]
		}
	}, [])
	if (AllFolderPaths.length === 1) {
		RootFolders[ExpandedRootFolderNodeIndex].selected = true
	}

	let SubFolders = await Promise.all(
		AllFolderPaths.map((Path) =>
			getSubfoldersByParentPath(Path, data.opportunityid, Opportunity.storage).then((SubFolders) => {
				return SubFolders.map((SubFolder) => {
					let FolderInPath = AllFolderPaths.some((Path) => Path === SubFolder.path)
					return {
						...SubFolder,
						expanded: FolderInPath,
						selected: SubFolder.path === data.SelectedFolderPath ? true : false,
						childrenLoaded: FolderInPath,
					}
				})
			})
		)
	)
	SubFolders = SubFolders.flat()
	let AllFolders = RootFolders.concat(SubFolders)
	AllFolders.filter((Folder) => !Folder.parent).forEach((Folder) => {
		AllFolders[AllFolders.findIndex((node) => node.id === Folder.id)].parent = AllFolders.find((node) => node.path === Folder.parentPath).id
	})
	return {
		Opportunities: Opportunities,
		Folders: AllFolders,
		nodeid: data.opportunityid,
	}
})

export const fetchTransmittals = createAsyncThunk('getTransmittals', async (data, { dispatch }) => {
	let Opportunity = await getOpportunityById(data.opportunityid)
	console.log('from fetchTransmittals')
	let [Opportunities, RootFolders, TransmittalRootFolders] = await Promise.all([
		getOpportunitiesForCountry(Opportunity.countryId),
		getOpportunityRootFolders(data.opportunityid, Opportunity.storage),
		getTransmittalRootFolders(data.opportunityid, Opportunity.storage, data.parentId),
	])
	return {
		Opportunities: Opportunities,
		RootFolders: RootFolders,
		TransmittalRootFolders: TransmittalRootFolders,
		nodeid: data.opportunityid,
		parentId: data.parentId,
		transmittalId: data.transmittalId,
	}
})
const treeSlice = createSlice({
	name: 'tree',
	initialState: {
		rootSet: false,
		rootFetched: false,
		nodes: [],
		status: null,
	},
	reducers: {
		setRootFetched: (state, action) => {
			state.rootFetched = action.payload
		},
		setRootNode: (state, action) => {
			if (Array.isArray(action.payload)) {
				action.payload.forEach((nodeID) => {
					const nodeIndex = state.nodes.findIndex((node) => node.id === nodeID)
					let currentNode = { ...state.nodes[nodeIndex] }
					state.nodes[nodeIndex] = {
						...currentNode,
						root: true,
					}
					state.rootSet = true
				})
			} else {
				const nodeIndex = state.nodes.findIndex((node) => node.id === action.payload)
				let currentNode = { ...state.nodes[nodeIndex] }
				state.nodes[nodeIndex] = {
					...currentNode,
					root: true,
				}
				state.rootSet = true
			}
		},
		expandNode: (state, action) => {
			const nodeIndex = state.nodes.findIndex((node) => node.id === action.payload)
			let currentNode = { ...state.nodes[nodeIndex] }
			state.nodes[nodeIndex] = {
				...currentNode,
				expanded: true,
			}
		},
		collapseNode: (state, action) => {
			const nodeIndex = state.nodes.findIndex((node) => node.id === action.payload)
			let currentNode = { ...state.nodes[nodeIndex] }
			state.nodes[nodeIndex] = {
				...currentNode,
				expanded: false,
			}
		},
		loadChildren: (state, action) => { },
		loadParentNodes: (state, action) => {
			const currentNodeIndex = state.nodes.findIndex((node) => node.root)
			let currentNode = { ...state.nodes[currentNodeIndex] }
			const currentNodeParentNodeIndex = state.nodes.findIndex((node) => node.id === currentNode.parent)
			let currentNodeParentNode = { ...state.nodes[currentNodeParentNodeIndex] }

			state.nodes
				.filter((node) => node.root === true)
				.forEach((rootNode) => {
					state.nodes[state.nodes.findIndex((node) => node.id === rootNode.id)] = {
						...rootNode,
						root: false,
					}
				})
			state.nodes
				.filter((node) => node.parent === currentNodeParentNode.parent)
				.forEach((parentNode) => {
					if (parentNode.id == currentNode.parent) {
						state.nodes[state.nodes.findIndex((node) => node.id === parentNode.id)] = {
							...parentNode,
							root: true,
							expanded: true,
							childrenLoaded: true,
						}
					} else {
						state.nodes[state.nodes.findIndex((node) => node.id === parentNode.id)] = {
							...parentNode,
							root: true,
						}
					}
				})
		},
		setSelectedNode: (state, action) => {
			const nodeToDeselectIndex = state.nodes.findIndex((node) => node.selected === true)
			const nodeToSelectIndex = state.nodes.findIndex((node) => node.id === action.payload)
			let nodeToDeseleect = { ...state.nodes[nodeToDeselectIndex] }
			let nodeToSelect = { ...state.nodes[nodeToSelectIndex] }
			state.nodes[nodeToDeselectIndex] = {
				...nodeToDeseleect,
				selected: false,
			}
			state.nodes[nodeToSelectIndex] = {
				...nodeToSelect,
				selected: true,
			}
		},
		addNodes: (state, action) => { },
		regionLanding: (state, action) => {
			state.nodes
				.filter((node) => node.type === 'region')
				.forEach((regionNode) => {
					const nodeIndex = state.nodes.findIndex((node) => node.id === regionNode.id)
					let currentNode = { ...state.nodes[nodeIndex] }
					if (currentNode.title == action.payload) {
						state.nodes[nodeIndex] = {
							...currentNode,
							root: true,
							selected: true,
							expanded: true,
						}
					} else {
						state.nodes[nodeIndex] = {
							...currentNode,
							root: true,
						}
					}
				})
			state.rootSet = true
		},
		subRegionLanding: (state, action) => {
			const routedNode = state.nodes.find((node) => node.type === 'subregion' && node.title === action.payload)
			state.nodes
				.filter((node) => node.type === 'subregion' && node.parent === routedNode.parent)
				.forEach((subRegionNode) => {
					const nodeIndex = state.nodes.findIndex((node) => node.id === subRegionNode.id)
					let currentNode = { ...state.nodes[nodeIndex] }
					if (currentNode.id == routedNode.id) {
						state.nodes[nodeIndex] = {
							...currentNode,
							root: true,
							selected: true,
							expanded: true,
						}
					} else {
						state.nodes[nodeIndex] = {
							...currentNode,
							root: true,
						}
					}
				})
			state.rootSet = true
		},
		countryLanding: (state, action) => {
			const routedNode = state.nodes.find((node) => node.type === 'country' && node.title === action.payload)
			state.nodes
				.filter((node) => node.type === 'country' && node.parent === routedNode.parent)
				.forEach((countryNode) => {
					const nodeIndex = state.nodes.findIndex((node) => node.id === countryNode.id)
					let currentNode = { ...state.nodes[nodeIndex] }
					if (currentNode.id == routedNode.id) {
						state.nodes[nodeIndex] = {
							...currentNode,
							root: true,
							selected: true,
						}
					} else {
						state.nodes[nodeIndex] = {
							...currentNode,
							root: true,
						}
					}
				})
			state.rootSet = true
		},
		refreshNodes: (state, action) => {
			const nodes = action.payload
			nodes.forEach((node) => {
				const nodeIndex = state.nodes.findIndex((stateNode) => stateNode.id === node.id)
				const nodeHasChildren = state.nodes.some((stateNode) => stateNode.parent === node.id)
				state.nodes[nodeIndex] = {
					...node,
					childrenLoaded: nodeHasChildren,
					expanded: true,
				}
			})
		},

		addNode: (state, action) => {
			const node = action.payload;
			state.nodes.push(node);

		},
		removeNode: (state, action) => {
			const filteredState = state.nodes.filter(node => node.id !== action.payload);
			state.nodes = [...filteredState]
		}
	},
	extraReducers: {
		[fetchInitialStateNodes.pending]: (state, action) => {
			state.status = 'loading'
		},
		[fetchInitialStateNodes.fulfilled]: (state, action) => {
			state.rootFetched = true
			state.status = 'success'
			state.nodes = action.payload
		},
		[fetchInitialStateNodes.rejected]: (state, action) => {
			state.status = 'failed'
		},
		[fetchOpportunitiesForCountry.pending]: (state, action) => {
			state.status = 'loading'
		},
		[fetchOpportunitiesForCountry.fulfilled]: (state, action) => {
			state.status = 'success'
			const nodeIndex = state.nodes.findIndex((node) => node.SPID === action.payload.nodeid)
			let currentNode = { ...state.nodes[nodeIndex] }
			state.nodes[nodeIndex] = {
				...currentNode,
				expanded: true,
				childrenLoaded: true,
			}
			state.nodes = state.nodes.concat(action.payload.nodes)
		},
		[fetchOpportunitiesForCountry.rejected]: (state, action) => {
			state.status = 'failed'
		},
		[fetchTransmittalRootFolders.pending]: (state, action) => {
			state.status = 'loading'
			const nodeIndex = state.nodes.findIndex((node) => node.id === `transmittals_${action.meta.arg.opportunityid}`)
			let currentNode = { ...state.nodes[nodeIndex] }
			state.nodes[nodeIndex] = {
				...currentNode,
				loading: true,
			}
		},
		[fetchTransmittalRootFolders.fulfilled]: (state, action) => {
			state.status = 'success'

			const deselectSelectedNode = () => {
				const nodeIndex = state.nodes.findIndex((node) => node.selected)
				let currentNode = { ...state.nodes[nodeIndex] }
				state.nodes[nodeIndex] = {
					...currentNode,
					selected: false,
				}
			}
			const selectTransmittalNode = () => {
				const nodeIndex = action.payload.nodes.findIndex((node) => node.title === action.payload.transmittalId)
				let currentNode = { ...action.payload.nodes[nodeIndex] }
				action.payload.nodes[nodeIndex] = {
					...currentNode,
					selected: true,
				}
			}

			const expandParentNode = () => {
				const nodeIndex = state.nodes.findIndex((node) => node.id === action.payload.nodeid)
				let currentNode = { ...state.nodes[nodeIndex] }
				state.nodes[nodeIndex] = {
					...currentNode,
					expanded: true,
					childrenLoaded: true,
					loading: false,
				}
			}
			//deselectSelectedNode()
			//selectTransmittalNode()
			expandParentNode()
			state.nodes = state.nodes.concat(action.payload.nodes)
		},
		[fetchTransmittalRootFolders.rejected]: (state, action) => {
			state.status = 'failed'
		},
		[fetchOpportunityRootFolders.pending]: (state, action) => {
			state.status = 'loading'
			const nodeIndex = state.nodes.findIndex((node) => node.id === action.meta.arg.parentId)
			let currentNode = { ...state.nodes[nodeIndex] }
			state.nodes[nodeIndex] = {
				...currentNode,
				loading: true,
			}
		},
		[fetchOpportunityRootFolders.fulfilled]: (state, action) => {
			state.status = 'success'

			//action.payload.nodes.

			const nodeIndex = state.nodes.findIndex((node) => node.id === action.payload.nodeid)
			let currentNode = { ...state.nodes[nodeIndex] }
			state.nodes[nodeIndex] = {
				...currentNode,
				expanded: true,
				childrenLoaded: true,
				loading: false,
			}
			state.nodes = state.nodes.concat(action.payload.nodes)
		},
		[fetchOpportunityRootFolders.rejected]: (state, action) => {
			state.status = 'failed'
		},
		[fetchSubfoldersByPath.pending]: (state, action) => {
			state.status = 'loading'
			const nodeIndex = state.nodes.findIndex((node) => node.id === action.meta.arg.parentId)
			let currentNode = { ...state.nodes[nodeIndex] }
			state.nodes[nodeIndex] = {
				...currentNode,
				loading: true,
			}
		},
		[fetchSubfoldersByPath.fulfilled]: (state, action) => {
			state.status = 'success'
			const nodeIndex = state.nodes.findIndex((node) => node.id === action.payload.nodeid)
			let currentNode = { ...state.nodes[nodeIndex] }
			state.nodes[nodeIndex] = {
				...currentNode,
				expanded: true,
				childrenLoaded: true,
				loading: false,
			}
			state.nodes = state.nodes.concat(action.payload.nodes)
		},
		[fetchSubfoldersByPath.rejected]: (state, action) => {
			state.status = 'failed'
		},
		[fetchOpportunityById.pending]: (state, action) => {
			state.status = 'loading'
		},
		[fetchOpportunityById.fulfilled]: (state, action) => {
			let OpportunityNodes = action.payload.Opportunities.map((opportunityNode) => {
				if (opportunityNode.id == action.payload.nodeid) {
					return {
						...opportunityNode,
						root: true,
						selected: true,
						childrenLoaded: true,
						expanded: true,
					}
				} else {
					return {
						...opportunityNode,
						root: true,
					}
				}
			})
			let RootFolderNodes = action.payload.RootFolders

			state.nodes = state.nodes.concat(OpportunityNodes).concat(RootFolderNodes)
			state.rootSet = true
			state.status = 'success'
		},
		[fetchOpportunityById.rejected]: (state, action) => {
			state.status = 'failed'
		},
		[fetchFoldersByPath.pending]: (state, action) => {
			state.status = 'loading'
		},
		[fetchFoldersByPath.fulfilled]: (state, action) => {
			let OpportunityNodes = action.payload.Opportunities.map((opportunityNode) => {
				if (opportunityNode.id == action.payload.nodeid) {
					return {
						...opportunityNode,
						root: true,
						childrenLoaded: true,
						expanded: true,
					}
				} else {
					return {
						...opportunityNode,
						root: true,
					}
				}
			})
			let Folders = action.payload.Folders
			state.nodes = state.nodes.concat(OpportunityNodes).concat(Folders)
			state.rootSet = true
			state.status = 'success'
		},
		[fetchFoldersByPath.rejected]: (state, action) => {
			state.status = 'failed'
		},
		[fetchTransmittals.pending]: (state, action) => {
			state.status = 'loading'
		},
		[fetchTransmittals.fulfilled]: (state, action) => {
			let OpportunityNodes = action.payload.Opportunities.map((opportunityNode) => {
				if (opportunityNode.id == action.payload.nodeid) {
					return {
						...opportunityNode,
						root: true,
						childrenLoaded: true,
						expanded: true,
					}
				} else {
					return {
						...opportunityNode,
						root: true,
					}
				}
			})
			let RootFolders = action.payload.RootFolders
			let TransmittalRootFolders = action.payload.TransmittalRootFolders
			if (action.payload.transmittalId) {
				const nodeIndex = TransmittalRootFolders.findIndex((node) => node.title === action.payload.transmittalId)
				let currentNode = { ...TransmittalRootFolders[nodeIndex] }
				TransmittalRootFolders[nodeIndex] = {
					...currentNode,
					selected: true,
				}
			}
			const nodeIndex = RootFolders.findIndex((node) => node.id === action.payload.parentId)
			let currentNode = { ...RootFolders[nodeIndex] }
			RootFolders[nodeIndex] = {
				...currentNode,
				selected: action.payload.transmittalId ? false : true,
				childrenLoaded: true,
				expanded: true,
			}
			state.nodes = state.nodes.concat(OpportunityNodes).concat(RootFolders).concat(TransmittalRootFolders)
			state.rootSet = true
			state.status = 'success'
		},
		[fetchTransmittals.rejected]: (state, action) => {
			state.status = 'failed'
		},
	},
})

export const {
	setRootNode,
	setRootFetched,
	setSelectedNode,
	expandNode,
	refreshNodes,
	loadParentNodes,
	collapseNode,
	regionLanding,
	subRegionLanding,
	countryLanding,
	addNode,
	removeNode
} = treeSlice.actions
export default treeSlice.reducer
