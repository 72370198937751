import React, { useEffect, useState, useRef } from 'react'
import { useOutletContext, useNavigate } from 'react-router-dom'
import File from './Components/File'
import Properties from './Components/Properties'
import Metadata from './Components/Metadata'
import Auditable from './Components/Auditable'
import { ReviseDocument } from '../../../utils/fetchUtils'
import { ObjectHasEmptyValues } from '../../../utils/appUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useSendMessage } from '../../Utilities/MessageManager/MessageContext'
const Revise = () => {
	const { document } = useOutletContext()
	const SendMessage = useSendMessage()
	let navigate = useNavigate()
	const documentTitleInput = useRef(null)
	const [loading, setLoading] = useState(false)
	const [documentClass, setDocumentClass] = useState(document.class)
	const [documentArchivalCode, setDocumentArchivalCode] = useState(document.archivalCode)
	const [documentInformationClass, setDocumentInformationClass] = useState(document.informationClass)
	const [documentApplicationArea, setDocumentApplicationArea] = useState(document.applicationArea)
	const [documentKnowledgeArea, setDocumentKnowledgeArea] = useState(document.knowledgeArea)
	useEffect(() => {
		if (document.status !== 'Finalised') {
			navigate(`/opportunity/${document.opportunityId}/document/view/${document.documentid}/${document.revision}`, { replace: true })
		}
	}, [])
	const SubmitData = async () => {
        setLoading(true)
		let item = {
			title: {
				required: true,
				value: documentTitleInput.current.value === '' ? null : documentTitleInput.current.value,
			},
			class: {
				required: false,
				value: documentClass ? documentClass.label : documentClass,
			},
			informationClass: {
				required: true,
				value: documentInformationClass ? documentInformationClass.label : documentInformationClass,
			},
			archivalCode: {
				required: false,
				value: documentArchivalCode ? documentArchivalCode.label : documentArchivalCode,
			},
			applicationArea: {
				required: false,
				value: documentApplicationArea ? documentApplicationArea.label : documentApplicationArea,
			},
			knowledgeArea: {
				required: false,
				value: documentKnowledgeArea ? documentKnowledgeArea.label : documentKnowledgeArea,
			},
		}
		if (ObjectHasEmptyValues(item)) {					
			SendMessage("Required fields missing!", "alert")
            setLoading(false)
		} else {
			let filesToArchive = []
			let nativeArchive = {
				"destinationPath":`/sites/${document.storage}/${document.opportunityId}/${document.ServerRelativeUrl.split("/")[4]} - Archive/${document.ServerRelativeUrl.split("/").at(-1)}`,
				"ServerRelativeUrl":document.ServerRelativeUrl
			}
			filesToArchive.push(nativeArchive)
			if(document.render){
				let renderArchive = {
					"destinationPath":`/sites/${document.render.storage}/${document.opportunityId}/${document.render.ServerRelativeUrl.split("/")[4]} - Archive/${document.render.ServerRelativeUrl.split("/").at(-1)}`,
					"ServerRelativeUrl":document.render.ServerRelativeUrl
				}
				filesToArchive.push(renderArchive)
			}
			await ReviseDocument(document.storage, document.opportunityId, document.SPID, item, document.etag, document.revision, document.documentid, document.ServerRelativeUrl, document.render ? document.render.SPID: null, filesToArchive)
			SendMessage("Document successfully revised!", "success")
			navigate(`/opportunity/${document.opportunityId}/folder/browse?path=${document.path}`, { replace: true })
		}
	}
	return (
		<div className='page'>
			<h1>Revise document</h1>
			<File mode={'revise'} document={document} />
			<Properties mode={'revise'} document={document} documentTitleInput={documentTitleInput} />
			<Metadata
				mode={'edit'}
				document={document}
				setValues={{
					ArchivalCode: setDocumentArchivalCode,
					DocumentClass: setDocumentClass,
					InformationClass: setDocumentInformationClass,
					ApplicationArea: setDocumentApplicationArea,
					KnowledgeArea: setDocumentKnowledgeArea,
				}}
			/>
			<Auditable document={document} />
			<button disabled={loading} className='btn btn-blue mt-25' onClick={() => SubmitData()}>
				{loading ? (
					<span>
						<FontAwesomeIcon className='fa-spin' icon={faSpinner} /> Processing..
					</span>
				) : (
					<span>Revise document</span>
				)}
			</button>
		</div>
	)
}

export default Revise
