import React, { useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'
export default function DragAndDrop({ data, dispatch, fileTypes, multiple }) {
	const hiddenFileInput = useRef(null)
	const handleDragEnter = (e) => {
		e.preventDefault()
		e.stopPropagation()

		dispatch({ type: 'SET_DROP_DEPTH', dropDepth: data.dropDepth + 1 })
	}
	const handleDragLeave = (e) => {
		e.preventDefault()
		e.stopPropagation()

		dispatch({ type: 'SET_DROP_DEPTH', dropDepth: data.dropDepth - 1 })
		if (data.dropDepth > 0) return
		dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false })
	}
	const handleDragOver = (e) => {
		e.preventDefault()
		e.stopPropagation()
		e.dataTransfer.dropEffect = 'copy'
		dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: true })
	}
	const handleDrop = (e) => {
		e.preventDefault()
		e.stopPropagation()
		let files = e.type === 'drop' ? [...e.dataTransfer.files] : [...e.target.files]
		if (files && files.length > 0) {
			dispatch({ type: 'ADD_FILE_TO_LIST', files })
			dispatch({ type: 'SET_DROP_DEPTH', dropDepth: 0 })
			dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false })
		}
	}
	return (
		<div>
			<div
				className={data.inDropZone ? 'document-upload-wrapper inside-document-upload-wrapper' : 'document-upload-wrapper'}
				onDrop={(e) => handleDrop(e)}
				onDragOver={(e) => handleDragOver(e)}
				onDragEnter={(e) => handleDragEnter(e)}
				onDragLeave={(e) => handleDragLeave(e)}
			>
				<button onClick={() => hiddenFileInput.current.click()} className='btn btn-blue'>
					<span>Choose file</span>
					<span>
						<FontAwesomeIcon icon={faCloudArrowUp} />
					</span>
				</button>
				<span>or drag files in here</span>
				<input ref={hiddenFileInput} onChange={(e) => handleDrop(e)} accept={fileTypes.map(fileType=>`.${fileType}`).join(",")} multiple={multiple} type='file' style={{ display: 'none' }} />
			</div>
		</div>
	)
}
