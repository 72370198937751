import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchDocuments } from './Components/browseSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useModal } from '../../Utilities/Modal/ModalContext'
import { getDocumentTemplates } from '../../../utils/fetchUtils'
import { faFileCirclePlus, faToggleOff, faToggleOn, faFileArrowUp } from '@fortawesome/free-solid-svg-icons'
import { GetFileIcon } from '../../../utils/appUtils'
import Filter from './Components/Filter'
import Sorter from './Components/Sorter'
import Documents from './Components/Documents'
import Upload from './Components/Upload'

const ToggleComponent = ({ title, toggle, setToggle }) => {
	return (
		<span onClick={() => setToggle((toggle) => !toggle)} className='documents-toggle-component'>
			{toggle ? (
				<span>
					<FontAwesomeIcon icon={faToggleOn} /> <span>{title}</span>
				</span>
			) : (
				<span>
					<FontAwesomeIcon icon={faToggleOff} /> <span>{title}</span>
				</span>
			)}
		</span>
	)
}
const Browse = ({ selectedTool }) => {
	const { opportunity, selectedFolder } = useOutletContext()
	const [modal, setModal] = useModal()
	const documents = useSelector((state) => state.browse.documents)
	const [toggleUpload, setToggleUpload] = useState(false)
	const [toggleFilter, setToggleFilter] = useState(true)
	const [toggleSorter, setToggleSorter] = useState(true)
	const [searchString, setSearchString] = useState('')
	const [checkedTemplates, setCheckedTemplates] = useState([]);
	const dispatch = useDispatch()
	useEffect(() => {
		if (selectedFolder && opportunity) {
			dispatch(
				fetchDocuments({
					opportunityid: opportunity.id,
					SelectedFolderPath: selectedFolder.path,
					ServerRelativeUrl: selectedFolder.ServerRelativeUrl,
					storage: selectedFolder.storage,
				})
			)
		}
	}, [selectedFolder])
	const handleNewDocument = () => {
		const ModalContent = () => {
			const [templates, setTemplates] = useState([])
			const [loading, setLoading] = useState(false)
			const [checkedState, setCheckedState] = useState([]);
			useEffect(() => {
				const fetchTemplates = async () => {
					setLoading(true)
					const Templates = await getDocumentTemplates()
					setTemplates(Templates)
					setCheckedState(new Array(Templates.length).fill(false))
					setLoading(false)
				}
				fetchTemplates()
			}, [])
			const handleOnChange = (position) => {
				const updatedCheckedState = checkedState.map((item, index) =>
					index === position ? !item : item
				);
				setCheckedState(updatedCheckedState);
			};
			const handleSumbit = async () => {
				const selectedTemplates = checkedState.reduce((accumulator, current, index) => {
					if (current === true) {
						return accumulator.concat(templates[index].File)
					}
					return accumulator;
				}, [])
				setCheckedTemplates(selectedTemplates);
				setToggleUpload(true);
				setModal(null);
			}
			return (
				<div className='page'>
					<h1>New document from template</h1>
					{loading ? <span>Loading..</span> : (
						<>
							<span>List of templates</span>
							<div className='table'>
								{templates.map((template, index) => {
									return (
										<div className='table-row template-documents' key={index}>
											<input
												type="checkbox"
												id={`custom-checkbox-${index}`}
												name={template.File.Name}
												value={template.File.Name}
												onChange={() => handleOnChange(index)}
											/>
											<div ><FontAwesomeIcon icon={GetFileIcon(template.File_x0020_Type)} /></div>
											<div >{template.File.Name}</div>


										</div>

									);
								})}
							</div>

							<button disabled={!checkedState.some((el) => el === true)} onClick={handleSumbit} className='btn btn-blue'>
								<span>Create document(s)</span>
								<span>
									<FontAwesomeIcon icon={faFileArrowUp} />
								</span>
							</button>
						</>)
					}
				</div>
			)
		}
		setModal(<ModalContent />)
	}
	return (
		<div className='page'>
			<div className='documents-table'>
				<div className='documents-toggle'>
					<span onClick={() => handleNewDocument()} className='documents-toggle-component'>
						<span>
							<FontAwesomeIcon icon={faFileCirclePlus} /> <span>New document</span>
						</span>
					</span>
					<ToggleComponent title={'Upload'} toggle={toggleUpload} setToggle={setToggleUpload} />
					<ToggleComponent title={'Filters'} toggle={toggleFilter} setToggle={setToggleFilter} />
					<ToggleComponent title={'Sorter'} toggle={toggleSorter} setToggle={setToggleSorter} />
				</div>

				{toggleUpload ? (
					<Upload
						ServerRelativeUrl={selectedFolder.ServerRelativeUrl}
						storage={selectedFolder.storage}
						opportunityId={opportunity.id}
						path={selectedFolder.path}
						setToggleUpload={setToggleUpload}
						checkedTemplates={checkedTemplates}
						setCheckedTemplates={setCheckedTemplates}
					/>
				) : null}
				{toggleFilter ? <Filter setSearchString={setSearchString} /> : null}
				{toggleSorter ? <Sorter /> : null}

				<Documents documents={documents} opportunity={opportunity} searchString={searchString} selectedTool={selectedTool} />
			</div>
		</div>
	)
}
export default Browse
