import React, { useReducer, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowUp, faSpinner } from '@fortawesome/free-solid-svg-icons'
import DragAndDrop from './DragAndDrop'
import UploadFileList from './UploadFileList'
import { UploadDocuments } from '../../../../utils/fetchUtils'
import { ObjectHasEmptyValues } from '../../../../utils/appUtils'
import { useSendMessage } from '../../../Utilities/MessageManager/MessageContext'
const Upload = ({ storage, ServerRelativeUrl, opportunityId, path, setToggleUpload, checkedTemplates, setCheckedTemplates }) => {
	useEffect(() => {
		if (checkedTemplates.length > 0) {
			const files = [...checkedTemplates];
			dispatch({ type: 'ADD_FILE_TO_LIST', files })
			setCheckedTemplates([]);
		}
	}, [checkedTemplates])

	const SendMessage = useSendMessage()
	const [uploading, setUploading] = useState(false)
	let navigate = useNavigate()
	const reducer = (state, action) => {
		switch (action.type) {
			case 'SET_DROP_DEPTH':
				return { ...state, dropDepth: action.dropDepth }
			case 'SET_IN_DROP_ZONE':
				return { ...state, inDropZone: action.inDropZone }
			case 'ADD_FILE_TO_LIST':
				let files = action.files
				const existingFiles = state.fileList.map((f) => f.name)
				files = files.filter((f) => !existingFiles.includes(f.name))
				for (const [key, value] of Object.entries(files)) {
					files[key].metadata = {
						fileExtension: value.name.split('.').pop(),
						title: value.name.replace(/\.[^/.]+$/, ''),
						class: null,
						informationClass: 'Confidential',
						archivalCode: null,
						applicationArea: null,
						knowledgeArea: null,
						storage:storage
					}
				}
				return { ...state, fileList: state.fileList.concat(files) }
			case 'REMOVE_FILE_FROM_LIST':
				return { ...state, fileList: state.fileList.filter((_, index) => index !== action.fileIndex) }
			case 'CHANGE_METADATA_ON_FILE':
				state.fileList[action.fileIndex].metadata[action.field] = action.value
				return { ...state, fileList: state.fileList }
			default:
				return state
		}
	}
	const [data, dispatch] = useReducer(reducer, { dropDepth: 0, inDropZone: false, fileList: [] })
	const checkForEmptyValues = () => {
		return data.fileList
			.map((file) =>
				ObjectHasEmptyValues({
					title: {
						required: true,
						value: file.metadata.title,
					},
					class: {
						required: false,
						value: file.metadata.class,
					},
					informationClass: {
						required: true,
						value: file.metadata.informationClass,
					},
					archivalCode: {
						required: false,
						value: file.metadata.archivalCode,
					},
					applicationArea: {
						required: false,
						value: file.metadata.applicationArea,
					},
					knowledgeArea: {
						required: false,
						value: file.metadata.knowledgeArea,
					},
				})
			)
			.some((empty) => empty === true)
	}
	const handleUploadDocuments = async () => {
		setUploading(true)
		if (checkForEmptyValues()) {
			SendMessage("Required fields missing!", "alert")
			setUploading(false)
		} else {
			await UploadDocuments(storage, ServerRelativeUrl, data.fileList)
			setToggleUpload(false)
			navigate(`/opportunity/${opportunityId}/folder/browse?path=${path}`, { replace: true })
		}
	}
	return (
		<div>
			<DragAndDrop dispatch={dispatch} data={data} fileTypes={[]} multiple={true}  />
			<UploadFileList dispatch={dispatch} fileList={data.fileList} uploading={uploading} />
			{data.fileList.length ? (
				<button disabled={uploading} className='btn btn-blue mt-25' onClick={() => handleUploadDocuments()}>
					{uploading ? (
						<span>
							<FontAwesomeIcon className='fa-spin ' icon={faSpinner} /> Uploading..
						</span>
					) : (
						<>
							<span>Upload files</span>
							<span>
								<FontAwesomeIcon icon={faFileArrowUp} />
							</span>
						</>
					)}
				</button>
			) : null}
		</div>
	)
}
export default Upload
