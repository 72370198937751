import { useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

const OpportunityProperties = (props) => {
	const params = useParams();
	const node = useSelector(state => state.tree.nodes.find(n => n.type === "opportunity" && n.id === params.opportunityid), shallowEqual);
	return (
		<div>
			<h1>Opportunity Properties</h1>
			<div className='doc-properties'>

				<div className='doc-properties-row'>
					<div>Opportunity Name</div>
					<div>{node?.title}</div>
				</div>

				<div className='doc-properties-row'>
					<div>Account name</div>
					<div>{node?.AccountName}</div>
				</div>


				<div className='doc-properties-row'>
					<div>Site location name</div>
					<div>{node?.SiteLocationName}</div>
				</div>

				<div className='doc-properties-row'>
					<div>Project type</div>
					<div>{node?.ProjectType}</div>
				</div>

				<div className='doc-properties-row'>
					<div>EB Project classification</div>
					<div>{node?.EBProjectClassification}</div>
				</div>

				<div className='doc-properties-row'>
					<div>Number of Engines</div>
					<div>{node?.NumberofEng}</div>
				</div>


				<div className='doc-properties-row'>
					<div>Primary engine type</div>
					<div>{node?.PrimaryEngineType}</div>
				</div>

				<div className='doc-properties-row'>
					<div>Number of Cylinders</div>
					<div>{node?.NumberofCyl}</div>
				</div>

				<div className='doc-properties-row'>
					<div>Business line ES</div>
					<div>{node?.BusinessLineES}</div>
				</div>

				<div className='doc-properties-row'>
					<div>Opportunity ID</div>
					<div>{node?.id}</div>
				</div>

			</div>
		</div>
	)
}
export default OpportunityProperties
