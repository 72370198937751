import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { CopyDocumentsTo, MoveDocumentsTo } from '../../../utils/fetchUtils'
import { useClipboard } from './ClipboardContext'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faFile, faCopy, faFolder, faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons'
import { useModal } from '../Modal/ModalContext'
const ListOfDocumentsInClipboard = () => {
	const nodes = useSelector((state) => state.tree.nodes)
	const [clipboard, setClipboard] = useClipboard()
	const [copyingFiles, setCopyingFiles] = useState(false)
	const [movingFiles, setMovingFiles] = useState(false)
	const [modal, setModal] = useModal()
	const selectedFolder = nodes.find((node) => node.selected === true)
	const selectedDocumentsInClipboard = clipboard.filter((document) => document.selected)


	let navigate = useNavigate()
	const handleSelectDocument = (index, value) => {
		let documents = [...clipboard]
		documents[index] = { ...documents[index], selected: value }
		setClipboard(documents)
	}
	const handleRemoveDocument = (index) => {
		let documents = [...clipboard]
		documents.splice(index, 1)
		setClipboard(documents)
	}

	const cantCopyDocument = () => {
		return copyingFiles ? true : selectedDocumentsInClipboard.length > 0 ? false : true
	}

	const cantMoveDocument = () => {
		if (movingFiles)
			return true

		for (let doc of selectedDocumentsInClipboard) {
			if (doc.path === selectedFolder.path || doc.opportunityId !== selectedFolder.opportunityId) return true
		}



		if (selectedDocumentsInClipboard.length > 0) return false

		return true
	}

	const RowOfDocument = ({ index, document }) => {
		return (
			<div className='table-row documents-in-cliboard'>
				<div>
					{document.selected ? (
						<FontAwesomeIcon onClick={() => handleSelectDocument(index, false)} icon={faSquareCheck} />
					) : (
						<FontAwesomeIcon onClick={() => handleSelectDocument(index, true)} icon={faSquare} />
					)}
				</div>

				<div
					onClick={() =>
						navigate(`/opportunity/${document.opportunityId}/document/view/${document.documentid}/${document.revision}`, {
							replace: true,
						})
					}
				>
					<FontAwesomeIcon className='fa-fw' icon={faFile} />
					{document.title}
				</div>
				<div>{document.documentid}</div>
				<div>{document.revision.toString().padStart(2, '0')}</div>
				<div>{document.status}</div>
				<div>
					<FontAwesomeIcon onClick={() => handleRemoveDocument(index)} icon={faTrash} />
				</div>
				<div
					onClick={() =>
						navigate(`/opportunity/${document.opportunityId}/folder/browse?path=${document.path}`, {
							replace: true,
						})
					}
					className='documents-in-cliboard-source-path'
				>
					<FontAwesomeIcon className='fa-fw' icon={faFolder} />
					{`${document.opportunityId} / ${document.path.replaceAll('/', ' / ')}`}
				</div>
			</div>
		)
	}
	const MoveToFolderTable = () => {
		const handleMoveTo = async () => {
			setMovingFiles(true)
			let fileList = []
			selectedDocumentsInClipboard.forEach((document) => {
				fileList.push({
					"destinationPath": `${selectedFolder.ServerRelativeUrl}/${document.ServerRelativeUrl.split("/").at(-1)}`,
					"ServerRelativeUrl": document.ServerRelativeUrl
				})
				if (document.render) {
					fileList.push({
						"destinationPath": `${selectedFolder.ServerRelativeUrl}/${document.render.ServerRelativeUrl.split("/").at(-1)}`,
						"ServerRelativeUrl": document.render.ServerRelativeUrl
					})
				}
			})
			let documents = [...clipboard]
			await MoveDocumentsTo(selectedFolder.storage, fileList)
			setClipboard(documents.filter((document) => !document.selected))
			setModal(null)
			setMovingFiles(false)
			navigate(`/opportunity/${selectedFolder.opportunityId}/folder/browse?path=${selectedFolder.path}`, { replace: true })
		}
		return (
			<div className='table-row copy-move-clipboard-table'>
				<div>
					<button onClick={() => handleMoveTo()} disabled={cantMoveDocument()} className='btn btn-blue btn-full-width'>
						{movingFiles ? (
							<span>
								<FontAwesomeIcon className='fa-spin' icon={faSpinner} /> Moving..
							</span>
						) : (
							<span>Move to</span>
						)}
					</button>
				</div>
				<div>
					<FontAwesomeIcon icon={faArrowRight} />
				</div>
				<div>{`${selectedFolder?.opportunityId} / ${selectedFolder?.path?.replaceAll('/', ' / ')}`}</div>
			</div>
		)
	}
	const CopyToFolderTable = () => {
		const handleCopyTo = async () => {
			setCopyingFiles(true)
			let fileList = selectedDocumentsInClipboard.map((document) => {
				return {
					fileType: document.fileType,
					ServerRelativeUrl: document.ServerRelativeUrl,
					storage: document.storage,
				}
			})
			let documents = [...clipboard]
			await CopyDocumentsTo(selectedFolder.ServerRelativeUrl, fileList, selectedFolder.storage)
			setClipboard(documents.filter((document) => !document.selected))
			setModal(null)
			setCopyingFiles(false)
			navigate(`/opportunity/${selectedFolder.opportunityId}/folder/browse?path=${selectedFolder.path}`, { replace: true })
		}
		return (
			<div className='table-row copy-move-clipboard-table'>
				<div>
					<button onClick={() => handleCopyTo()} disabled={cantCopyDocument()} className='btn btn-blue btn-full-width'>
						{copyingFiles ? (
							<span>
								<FontAwesomeIcon className='fa-spin' icon={faSpinner} /> Copying..
							</span>
						) : (
							<span>Copy to</span>
						)}
					</button>
				</div>
				<div>
					<FontAwesomeIcon icon={faCopy} />
				</div>
				<div>{`${selectedFolder?.opportunityId} / ${selectedFolder?.path?.replaceAll('/', ' / ')}`}</div>
			</div>
		)
	}
	return (
		<div>
			<div className='table'>
				<div className='table-heading table-row documents-in-cliboard'>
					<div></div>
					<div>Title</div>
					<div>Document ID</div>
					<div>Rev.</div>
					<div>Status</div>
					<div>Remove</div>
				</div>
				{clipboard.map((document, index) => {
					return <RowOfDocument key={index} index={index} document={document} />
				})}
			</div>
			<div className='table mt-25'>
				{selectedFolder ? <CopyToFolderTable /> : null}
				{selectedFolder ? <MoveToFolderTable /> : null}
			</div>
		</div>
	)
}
export default ListOfDocumentsInClipboard
