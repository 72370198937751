import { faFileWord, faFilePowerpoint, faFileExcel, faFilePdf, faFile, faFileImage, faFileVideo } from '@fortawesome/free-solid-svg-icons'
import { getFileAsBlob } from './fetchUtils'
import { MIMEMap } from './MIMEmap'
export function ObjectIsEmpty(obj) {
	if (obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype) {
		return true
	} else {
		return false
	}
}

export const ObjectHasEmptyValues = (obj) => {
	const RequiredFields = Object.values(obj).filter((field) => field.required)
	const CheckForNull = RequiredFields.some((field) => field.value === null)
	const CheckForEmptyString = RequiredFields.some((field) => field.value == '')
	const CheckForUndefined = RequiredFields.some((field) => field.value == undefined)
	if (CheckForNull || CheckForEmptyString || CheckForUndefined) {
		return true
	} else {
		return false
	}
}
export const GetFormattedBytes = (bytes, decimals = 1) => {
	if (bytes === 0) return '0 Bytes'
	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
export const GetFormattedDate = (date) => {
	let SharePointDate = new Date(date)
	const FormattedDate = String(SharePointDate.getDate()).toString().padStart(2, '0')
	const FormattedMonth = String(SharePointDate.getMonth() + 1)
		.toString()
		.padStart(2, '0')
	const FormattedYear = String(SharePointDate.getFullYear())
	const FormattedHours = String(SharePointDate.getHours()).toString().padStart(2, '0')
	const FormattedMinutes = String(SharePointDate.getMinutes()).toString().padStart(2, '0')
	return `${FormattedDate}.${FormattedMonth}.${FormattedYear} ${FormattedHours}:${FormattedMinutes}`
	/*
	//return Date + "." + Month + "." + Year + " " + Hours + ":" + Minutes,
	return {
		UTC: {
			UTCYear: date.getUTCFullYear(),
			UTCMonth: date.getUTCMonth() + 1,
			UTCDate: date.getUTCDate(),
			UTCHours: date.getUTCHours(),
			UTCMinutes: date.getUTCMinutes(),
		},
		Locale: {
			Year: date.getFullYear(),
			Month: date.getMonth() + 1,
			Date: date.getDate(),
			Hour: date.getHours(),
			Minutes: date.getMinutes(),
			ConcatenatedDateOnly:
				String(date.getDate()).toString().padStart(2, "0") +
				"." +
				String(date.getMonth() + 1)
					.toString()
					.padStart(2, "0") +
				"." +
				String(date.getFullYear()),
			Concatenated:
				String(date.getDate()).toString().padStart(2, "0") +
				"." +
				String(date.getMonth() + 1)
					.toString()
					.padStart(2, "0") +
				"." +
				String(date.getFullYear()) +
				" " +
				String(date.getHours()).toString().padStart(2, "0") +
				":" +
				String(date.getMinutes()).toString().padStart(2, "0"),
		},
	};
	*/
}
export const GetFileIcon = (type) => {
	switch (type) {
		case 'pptx':
			return faFilePowerpoint
		case 'docx':
			return faFileWord
		case 'xlsx':
			return faFileExcel
		case 'pdf':
			return faFilePdf
		case 'jpg':
		case 'png':
			return faFileImage
		case 'mkv':
		case 'avi':
		case 'mp4':
		case 'mov':
			return faFileVideo
		default:
			return faFile
	}
}
export const ReadAppLink = (FileType, url) => {
	const link = () => {
		switch (FileType) {
			case 'docx':
				return 'ms-word:ofv|u|' + process.env.REACT_APP_ROOTSITE
			case 'xlsx':
				return 'ms-excel:ofv|u|' + process.env.REACT_APP_ROOTSITE
			case 'pptx':
				return 'ms-powerpoint:ofv|u|' + process.env.REACT_APP_ROOTSITE
			default:
				return process.env.REACT_APP_ROOTSITE
		}
	}
	return link() + url
}
export const DownloadLink = async (storage, FileType, ServerRelativeUrl) => {
	let MIME = MIMEMap(FileType)
	if(MIME){
		const blob = await getFileAsBlob(storage, ServerRelativeUrl)
		const file = new Blob([blob], { type: MIME })
		const fileURL = URL.createObjectURL(file)
		OpenInNewTab(fileURL)
		URL.revokeObjectURL(fileURL);	
	}
}
export const ModifyAppLink = (FileType, url) => {
	const link = () => {
		switch (FileType) {
			case 'docx':
				return 'ms-word:ofe|u|' + process.env.REACT_APP_ROOTSITE
			case 'xlsx':
				return 'ms-excel:ofe|u|' + process.env.REACT_APP_ROOTSITE
			case 'pptx':
				return 'ms-powerpoint:ofe|u|' + process.env.REACT_APP_ROOTSITE
			default:
				return process.env.REACT_APP_ROOTSITE
		}
	}
	return link() + url
}
export const GetFileLink = (FileType, DocumentStatus, ServerRelativeUrl, Purpose) => {
	const ApplicationLink = () => {
		switch (DocumentStatus) {
			case 'Draft':
				switch (Purpose) {
					case 'Read':
						switch (FileType) {
							case 'docx':
								return 'ms-word:ofv|u|' + process.env.REACT_APP_ROOTSITE
							case 'xlsx':
								return 'ms-excel:ofv|u|' + process.env.REACT_APP_ROOTSITE
							case 'pptx':
								return 'ms-powerpoint:ofv|u|' + process.env.REACT_APP_ROOTSITE
							default:
								return process.env.REACT_APP_ROOTSITE
						}
					case 'Modify':
						switch (FileType) {
							case 'docx':
								return 'ms-word:ofe|u|' + process.env.REACT_APP_ROOTSITE
							case 'xlsx':
								return 'ms-excel:ofe|u|' + process.env.REACT_APP_ROOTSITE
							case 'pptx':
								return 'ms-powerpoint:ofe|u|' + process.env.REACT_APP_ROOTSITE
							default:
								return process.env.REACT_APP_ROOTSITE
						}
				}
			case 'Finalised':
			case 'Expired':
				switch (FileType) {
					case 'docx':
						return 'ms-word:ofv|u|' + process.env.REACT_APP_ROOTSITE
					case 'xlsx':
						return 'ms-excel:ofv|u|' + process.env.REACT_APP_ROOTSITE
					case 'pptx':
						return 'ms-powerpoint:ofv|u|' + process.env.REACT_APP_ROOTSITE
					default:
						return process.env.REACT_APP_ROOTSITE
				}
			default:
				return process.env.REACT_APP_ROOTSITE
		}
	}
	return ApplicationLink() + ServerRelativeUrl
}
export const OpenInNewTab = (url) => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
	if (newWindow) newWindow.opener = null
}
